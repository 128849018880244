import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import loginBanner from "../../assets/images/login/LoginBanner.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Spinner from "../Common/Spinner";
import UATbelt from "../Common/UATbelt";
import { CommonService } from "../../Service/CommonService";
import { Service } from "../../Service/ApiService";
import PasswordStrength from "../Dynamic/PasswordStrength";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ResetPassword: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [resetPassword, setResetPassword] = useState({
    password: "",
    confirmPassword: "",
    id: id,
  });

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const validatePasswords = () => {
    let isValid = true;
    if (resetPassword.password.length === 0) {
      setPasswordError("Please enter password");
      isValid = false;
    } else if (resetPassword.password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      isValid = false;
    } else {
      setPasswordError(null);
    }

    if (resetPassword.confirmPassword.length === 0) {
      setConfirmPasswordError("Please retype your password");
      isValid = false;
    } else if (resetPassword.confirmPassword !== resetPassword.password) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    } else {
      setConfirmPasswordError(null);
    }

    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validatePasswords()) return;

    setIsLoading(true);
    try {
      const res = await Service.resetUserPassword(resetPassword);
      if (res.data) {
        CommonService.Toast.fire({
          title: "Password reset successfully!",
          icon: "success",
        });
        history.push("/login");
      }
    } catch (error) {
      CommonService.Toast.fire({
        title: "Sorry, User not found!",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setResetPassword((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  };

  const handlePasswordChange = (e: any) => {
    setPasswordError("");
    if (e.target.value === resetPassword.confirmPassword) {
      setConfirmPasswordError("");
    }
    handleChange(e);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPasswordError("");
    handleChange(e);
  };

  const handleBackToLogin=() =>{
    history.push("/login");
  }

  return (
    <>
      <UATbelt />
      <div className="login text-start">
        <div className="login__form mx-auto">
          <div className="row d-flex align-items-lg-center">
            <div className="col-lg-6">
              <div className="login__form--outer">
                <div className="row mb-3">
                  
                  <div className="col-12">
                  <button className="back-btn btn d-flex align-items-center gap-2" onClick={handleBackToLogin}><span className="arrow-wrapper"><ArrowBackIcon/></span><span className="back-btn-text"> Back to login</span></button>
                    <h1 className="login__heading">Reset Password</h1>
                    <p className="login__text">
                      Create a new password below. Make sure it's strong and
                      secure
                    </p>
                  </div>
                </div>
                <form
                  className="form__inner row d-flex"
                  onSubmit={handleSubmit}
                >
                  <div className="col-12 mb-4">
                    <label htmlFor="password" className="form-label">
                      Password{" "}
                      <span className="star-required text-danger">*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${
                          passwordError ? "is_invalid" : ""
                        }`}
                        placeholder="Enter your password"
                        value={resetPassword.password}
                        onChange={handlePasswordChange}
                        name="password"
                        id="password"
                      />
                      <button
                        className="btn text-secondary position-absolute top-50 end-0 translate-middle-y"
                        type="button"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    {resetPassword.password && (
                      <PasswordStrength password={resetPassword.password} />
                    )}
                    {passwordError && (
                      <div className="error-message">{passwordError}</div>
                    )}
                  </div>

                  <div className="col-12 mb-4">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm Password{" "}
                      <span className="star-required text-danger">*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className={`form-control ${
                          confirmPasswordError ? "is_invalid" : ""
                        }`}
                        placeholder="Confirm your password"
                        value={resetPassword.confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        name="confirmPassword"
                        id="confirmPassword"
                      />
                      <button
                        className="btn text-secondary position-absolute top-50 end-0 translate-middle-y"
                        type="button"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    {confirmPasswordError && (
                      <div className="error-message">
                        {confirmPasswordError}
                      </div>
                    )}
                  </div>

                  <div className="col-12 mb-3">
                    <div className="form__btn--group row d-flex flex-wrap justify-content-center">
                      <div className="col-6">
                        <button
                          type="submit"
                          className="btn secondary-btn w-100 mb-2"
                          disabled={isLoading}
                        >
                          {isLoading && <Spinner />}
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className="login__form--banner d-flex justify-content-center">
                <img src={loginBanner} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
