 const Constant ={

     Role :{
        ADMIN :"ADMIN",
        SUPER_ADMIN : "SUPER_ADMIN",
        USER : "USER"
      },

      OrderStatus :{
        CART : "CART",
        PENDING_REVIEW : "PENDING_REVIEW",
        ACCEPTED : "ACCEPTED",
        REJECTED : "REJECTED",
        SHIPPED : "SHIPPED",
        CANCELLED : "CANCELLED",
        DELIVERED : "DELIVERED",
        SELLER_REJECTED: "SELLER_REJECTED",
        ADMIN_REJECTED: "ADMIN_REJECTED"
      },

      SampleRequestStatus :{
        PENDING_REVIEW : "PENDING_REVIEW",
        ACCEPTED : "ACCEPTED",
        REJECTED : "REJECTED",
        APPROVED : "APPROVED",
      },


      facility : {
        WHITEDOG_TRADING_STORAGE : "WhiteDog Trading Storage",
        WDTS : "WDTS",
      } ,

}


export default Constant;