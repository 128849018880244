import "../../App.css";
import { CookieService } from "../../Service/CookieService";
import Cookies from "universal-cookie";
import { useHistory, useLocation, useParams } from "react-router";
import { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import ProfileBar from "../Static/Profile/ProfileBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CommonService } from "../../Service/CommonService";
import { Service } from "../../Service/ApiService";
import { format } from "date-fns";
import { Dropdown } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Info from "../../assets/images/icons/Info.svg";
import CompanyStoreIcon from "../../assets/images/icons/CompanyStoreIcon.svg";
import User from "../../assets/images/icons/User.svg";
import DeviceMobileCamera from "../../assets/images/icons/DeviceMobileCamera.svg";
import EnvelopeSimpleOpen from "../../assets/images/icons/EnvelopeSimpleOpen.svg";
import MapPin from "../../assets/images/icons/MapPin.svg";
import CalendarCheck from "../../assets/images/icons/CalendarCheck.svg";
import { useSelector } from "react-redux";
import { RootState } from "../store";


const UserHeader = () => {
  const cookies = new Cookies();
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const currentPath = location.pathname;
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;
  const [companyDetails, setCompanyDetails] = useState<any>({});
  const [formattedDate, setFormattedDate] = useState<string>("");
  const productName = useSelector((state: RootState) => state.cart.productName);

  const logout = () => {
    CookieService.removeAuthToken();
    history.push("/login");
  };

  useEffect(() => {
    const fetchUserRole = () => {
      const cookies = new Cookies();
      const role = cookies.get("userRole");
      setUserRole(role);
    };

    const fetchCurrentPath = () => {
      // Update the activeTab state based on the current pathname
      if (currentPath === "/master/dashboard") {
        setActiveTab("dashboard");
      } else if (currentPath === "/user/my-warehouse") {
        setActiveTab("dashboard");
      } else if (currentPath === "/inventories") {
        setActiveTab("inventories");
      } else if (currentPath === "/my-inventory") {
        setActiveTab("my-inventory");
      } else if (currentPath === "/inventory-sell-requests") {
        setActiveTab("inventory-sell-requests");
      } else if (currentPath === "/login-history") {
        setActiveTab("login-history");
      } else if (currentPath.includes("/view-csv")) {
        setActiveTab("view-csv");
      } else if (currentPath.includes("/view-sell_request")) {
        setActiveTab("view-sell-request");
      } else if (currentPath.includes("/my-cart")) {
        setActiveTab("my-cart");
      } else {
        setActiveTab("");
      }
    };

    fetchUserRole();

    fetchCurrentPath();
    fetchCompanyById();
  }, [location.pathname]);

  function route(route: string) {
    history.push(route);
  }

  function historyBack() {
    console.log("historyBack")
    if (
      currentPath == "/user/empty-cart" ||
      currentPath.startsWith("/user/empty-cart")
    ) {
      history.push(`/user/inventories`);
    } else history.goBack();
  }

  const fetchCompanyById = async () => {
    const response: any = await Service.fetchCompanyById(params);
    setCompanyDetails(response.data);
    setFormattedDate(
      format(new Date(response.data.createdDate), "MMMM dd, yyyy")
    );
  };

  return (
    <header className="wd__header wd__header--user">
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <Logo size={"200px"} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="navbar-nav ms-auto mb-2 mb-lg-0"
              style={{ cursor: "pointer" }}
            >
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/inventories")}
                >
                  Marketplace
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/my-warehouse")}
                  role="button"
                  aria-expanded="false"
                >
                  Dashboard
                </a>
              </li>
              
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/wish-list")}
                >
                  My Wishlist
                </a>
              </li> */}

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/messages")}
                  role="button"
                  aria-expanded="false"
                >
                  Inbox
                </a>
              </li>

              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/my-requests")}
                  role="button"
                  aria-expanded="false"
                >
                  My Requests
                </a>
              </li> */}

              {/* {userRole === "ADMIN" && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    My Requests
                    <ExpandMoreRoundedIcon />
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => route("/user/active-order")}
                      >
                        Order Requests
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => route("/user/sample-request")}
                      >
                        Sample Requests
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => route("/user/offer-request")}
                      >
                        Offer Requests
                      </a>
                    </li>
                  </ul>
                </li>
              )} */}

              

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/support-requests")}
                >
                  Support
                </a>
              </li> 

              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/my-inventory")}
                >
                  My Inventories
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <div className="header__banner">
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center">
          <div>
            {currentPath !== "/user/inventories" && (
            <>
              <div
                className="back__button me-3 d-flex align-items-center"
              >
                {(currentPath !== "/user/my-warehouse" && currentPath !== "/user/view-as-owner" && currentPath !== "/user/view-as-buyer") && !currentPath.startsWith("/user/support-requests") && (currentPath !== "/user/messages") && (
                <IconButton
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    mb: 1,
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} onClick={() => historyBack()}/>
                </IconButton>)}

                {(currentPath.startsWith("/user/user-detail")) && (
                  <h3 className="ms-2 fw-bold text-white">
                    User Profile
                  </h3>
                )}

                {(currentPath.startsWith("/user/my-profile")) && (
                  <h3 className="ms-2 fw-bold text-white">
                    My Profile
                  </h3>
                )}

                  
                {(currentPath.startsWith("/user/support-requests")) && (
                  <h3 className="ms-2 fw-bold text-white">
                    Support Requests
                  </h3>
                )}


                {(currentPath.startsWith("/user/messages")) && (
                  <h3 className="ms-2 fw-bold text-white">
                    Messages
                  </h3>
                )}

                {(currentPath.startsWith("/user/product-details")) && (
                  <h3 className="ms-2 fw-bold text-white">
                    {productName}
                  </h3>
                )}



{(currentPath.startsWith("/user/my-cart")) && (
                  <h3 className="ms-2 fw-bold text-white">
                    My Cart
                  </h3>
                )}





                {(currentPath.startsWith("/user/my-warehouse/user-list")) && (
                  <h3 className="ms-2 fw-bold text-white">
                    Users
                  </h3>
                )}

                {(currentPath.startsWith("/user/view-csv") ||
                  currentPath.startsWith("/user/view-sell-request/")) && (
                  <h3 className="ms-2 fw-bold text-white">
                    My Inventory Sell Request
                  </h3>
                )}

                {(currentPath == "/user/inventory-sell-requests") && (
                  <h3 className="ms-2 fw-bold text-white">
                    Sell Requests
                  </h3>
                )}

                {(currentPath == "/user/active-order" ||
                  currentPath.startsWith("/user/order-detail")) && (
                  <h3 className="ms-2 fw-bold text-white">My Order Request</h3>
                )}

                {(currentPath == "/user/my-warehouse/active-order") && (
                  <h3 className="ms-2 fw-bold text-white">Order Requests</h3>
                )}

                {(currentPath == "/user/sample-request" ||
                  currentPath.startsWith("/user/view-sample-request")) && (
                  <h3 className="ms-2 fw-bold text-white">
                    My Sample Request
                  </h3>
                )}

                {(currentPath == "/user/my-warehouse/sample-request") && (
                  <h3 className="ms-2 fw-bold text-white">
                    Sample Requests
                  </h3>
                )}
                
                {(currentPath == "/user/offer-request" ||
                  currentPath.startsWith("/user/view-offer-request")) && (
                  <h3 className="ms-2 fw-bold text-white">My Offer Request</h3>
                )}
                {(currentPath == "/user/my-warehouse/offer-request") && (
                  <h3 className="ms-2 fw-bold text-white">
                    Offer Requests
                  </h3>
                )}
                {(currentPath == "/user/my-requests" ||
                  currentPath.startsWith("/my-requests")) && (
                  <h3 className="ms-2 fw-bold text-white">My Requests</h3>
                )}
                {(currentPath == "/user/my-warehouse" ||
                  currentPath == "/user/view-as-owner" || currentPath == "/user/view-as-buyer") && (
                    <>
                  <h4 className="dashboard__user--title my-3 px-2 lh-base text-white">
                    {companyDetails?.companyName}
                  </h4>
                  <div className="dashboard__user--companyInfo my-3 px-2">
                  <Dropdown>
                    <Dropdown.Toggle variant="info">
                      <img src={Info} alt="Info" />
                    </Dropdown.Toggle>
      
                    <Dropdown.Menu>
                      <ListGroup>
                        <ListGroup.Item>
                          <div className="company-details d-flex align-items-center">
                            <img src={CompanyStoreIcon} alt="CompanyStoreIcon" />
                            <span className="company-name ms-2">
                             {companyDetails?.companyName && companyDetails.companyName.trim() !== "" ? companyDetails.companyName : "-"}
                            </span>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <div className="icon">
                            <img src={User} alt="User" />
                          </div>
                          <div className="info d-flex flex-column mx-3">
                            <span className="sub-title">Contact Person</span>
                            <span className="detail">{companyDetails?.contactPerson && companyDetails.contactPerson.trim() !== "" ? companyDetails.contactPerson : "-"}</span>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <div className="icon">
                            {/* <SmartphoneOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                            <img src={DeviceMobileCamera} alt="DeviceMobileCamera" />
                          </div>
                          <div className="info d-flex flex-column mx-3">
                            <span className="sub-title">Contact Number</span>
                            <span className="detail">{companyDetails?.contactNumber && companyDetails.contactNumber.trim() !== "" ? companyDetails.contactNumber : "-"}</span>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <div className="icon">
                            {/* <EmailOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                            <img src={EnvelopeSimpleOpen} alt="EnvelopeSimpleOpen" />
                          </div>
                          <div className="info d-flex flex-column mx-3">
                            <span className="sub-title">Email</span>
                            <span className="detail">{companyDetails?.email && companyDetails.email.trim() !== "" ? companyDetails.email : "-"}</span>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <div className="icon">
                            {/* <LocationOnOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                            <img src={MapPin} alt="MapPin" />
                          </div>
                          <div className="info d-flex flex-column mx-3">
                            <span className="sub-title">Location</span>
                            <span className="detail">
                            {companyDetails?.city}{companyDetails?.city ?? "-"} {companyDetails?.state}{" "}
                            {companyDetails?.zipcode}
                            </span>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <div className="icon">
                            {/* <EventAvailableOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                            <img src={CalendarCheck} alt="CalendarCheck" />
                          </div>
                          <div className="info d-flex flex-column mx-3">
                            <span className="sub-title">Joining Date</span>
                            <span className="detail">{formattedDate}</span>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                </>
                )}
              </div>
              {(currentPath == "/user/my-warehouse" || currentPath == "/user/view-as-buyer" || currentPath == "/user/view-as-owner") && (<p className="user-header__note">
                Manage all your service requests and buyer interactions efficiently. 
                Raise inventory service requests and oversee your stock seamlessly.</p>)}
              </>
            )}
            {/* {userRole == "ADMIN" && (
              <div className="header--title">
                <h1 className="title text-white fs-2 fw-semibold mb-0">
                  My Inventory
                  <i className="bi bi-heart-fill"></i>
                </h1>
              </div>
            )} */}
          </div>
          <ProfileBar />
        </div>
      </div>

      {/* <div>
    
                <div className="logout"> 
                    <Button variant="contained" size="small" onClick={logout}>Logout</Button>
                </div>
            </div> */}
    </header>
  );
};

export default UserHeader;
