import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import EmptyListIcon from './../../assets/images/icons/EmptyListIcon.svg'

const InventoryEmpty: React.FC = () => {
    return (
        <Container maxWidth="lg">
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', margin: '32px 0px' }}>
                <img src={EmptyListIcon} alt="EmptyListIcon" />
                <Typography variant="h3" sx={{ color: '#333333', fontSize: '24px', fontWeight: '700', margin: '24px 0px 12px' }}>
                    It’s Empty!
                </Typography>
                <Typography variant="body1" sx={{ color: '#333333', fontSize: '15px', maxWidth: '625px' }} gutterBottom>
                    We're sorry, but the items you're looking for are currently out of stock. Please check back later or <Link href="#" underline="none" sx={{ color: '#FF8B03' }}>contact us</Link> for more information.
                </Typography>
            </Box>
        </Container>
    )
}

export default InventoryEmpty;