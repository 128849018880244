import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CartState {
  myCartItems: number;
  productName: string;
}

const initialState: CartState = {
  myCartItems: 0,
  productName: '',
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setMyCartItems(state, action: PayloadAction<number>) {
      state.myCartItems = action.payload;
    },
    resetMyCartItems(state) {
      state.myCartItems = 0;
    },
    setProductName(state, action: PayloadAction<string>) {
      state.productName = action.payload;
    },
    resetProductName(state) {
      state.productName = '';
    },
  },
});

export const { setMyCartItems, resetMyCartItems , setProductName, resetProductName} = cartSlice.actions;
export default cartSlice.reducer;
