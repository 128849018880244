import { DataGrid } from "@mui/x-data-grid";
import "../Dynamic/DisplayGrid.scss";
import { Box } from "@mui/material";

interface ChildProps {
    tableData: any;
    columns: any;
    localeText: any;
    title: any;
}

export const DisplayGrid: React.FC<ChildProps> = ({ tableData, columns, localeText, title }) => {

    console.log(tableData);
    console.log(columns);

    const toTitleCase = (str: string) => {
        return str?.replace(/_/g, " ") // Replace underscores with spaces
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
    };
    return (
        <div className="row mt-5">
            <div className="d-flex justify-content-between mb-3">
                <h5 className='mb-0'><b>{toTitleCase(title)}</b></h5>
                {/* <Button className='p-0' variant="link" onClick={() => route("/master/messages")}>View all</Button> */}
            </div>
            <div className="company-dashboard-data-grid table-responsive">
                <Box sx={{ height: 350, width: '100%' }}>
                    <DataGrid
                        localeText={localeText}
                        rows={tableData}
                        columns={columns}
                        className="data-grid"
                        pageSizeOptions={[5, 10]}
                        initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                        pagination
                        disableRowSelectionOnClick={true}
                    />
                </Box>
            </div>
        </div>
    )
}