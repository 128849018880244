import React, { useEffect, useState } from 'react'
import DownloadSimpleIcon from "../../assets/images/icons/DownloadSimple.svg";
import TrashIcon from "../../assets/images/icons/Trash.svg";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
import { Form, ListGroup, Card, Container } from 'react-bootstrap';
import { CommonService } from '../../Service/CommonService';
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from 'react-router';
import { MessageApiService } from '../../Service/MessageApiService';
import { WebSocketProvider } from '../../Websocket/WebSocketProvider';
import MessageBox from './MessageBox';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const AdminMessageBox: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [groups, setGroups] = React.useState<any>([]);
  const [selectedSellerId, setSelectedSellerId] = useState<any>(null);
  const [selectedSellerCompanyId, setSelectedSellerCompanyId] = useState<any>(null);

  useEffect(() => {
    getAdminMessageGroups();
  }, []);

  const getAdminMessageGroups = async () => {
    try {
      const response = await MessageApiService.getAdminMessageGroups(id);
      setGroups(response.data);
      setSelectedSellerId("User1");
      setSelectedSellerCompanyId("User2");
    } catch (error) {
      console.log("error fetching Admin Message Groups" + error);
    }
  }
  return (
    <>
      <div className="admin-message-box">
        <div className="admin-message-box-inner positio-relative">
        {/* <ArrowDropUpIcon className="arrow-icon"/> */}
          <div className="row m-0 h-100">
            <div className="col-lg-5 px-0 px-lg-2">
              <div className="sidebar h-100">
                <div className="buyer-info-wrapper" onClick={() => {
                  setSelectedSellerId("User1");
                  setSelectedSellerCompanyId("User2");
                }}  style={{
                  backgroundColor:
                  selectedSellerCompanyId === "User2"
                      ? "#E3EBEC"
                      : "#F0F0F0",
                }}>
                  <p className="buyer-text">Buyer</p>
                  <p className="buyer-name">{groups.buyerCompanyName}</p>
                  {groups.unreadMessageCount !== 0 && (
                  <div className="msg-count-wrapper">
                    {groups.unreadMessageCount}
                  </div>
                  )}
                </div>
                <div className="seller-list">
                  <div className="seller-list-header">
                    <p className="heading"> Seller(s)</p>
                  </div>
                  <div className="seller-list-header">
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        className="chat-search"
                        style={{ paddingLeft: "30px" }}
                      />
                      <SearchIcon
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "5px",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>
                  <ListGroup variant="flush">
                    {groups.sellers?.map((seller, index) => (
                      <ListGroup.Item
                        key={seller.sellerId} // Add a key for optimal rendering
                        className="seller"
                        onClick={() => {
                          setSelectedSellerId(seller.sellerId);
                          setSelectedSellerCompanyId(seller.sellerCompanyId);
                        }}
                      >
                        <div className="seller-inner" style={{
                        backgroundColor:
                        selectedSellerCompanyId === seller.sellerCompanyId
                            ? "white"
                            : "initial",
                      }}>
                          <p className="mb-1 seller-name">{seller.sellerCompanyName}</p>
                          <ul className="list-unstyled seller-item-list">
                            {seller.inventoryName.map((item, index) => (
                              <li key={index} className="seller-list-item">{item}</li>
                            ))}
                          </ul>
                          {seller.sellerUnreadMessageCount !== 0 && (
                          <div className="msg-count-wrapper">
                            {seller.sellerUnreadMessageCount}
                          </div>
                          )}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                </div>
              </div>
            </div>

            <div className="col-lg-7 px-0 px-lg-2">
              <div className="chat h-100">
                <div className="chat-header">
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      className="chat-search"
                      style={{ paddingLeft: "30px" }}
                    />
                    <SearchIcon
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "5px",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                </div>
                <WebSocketProvider requestId={id} sellerCompanyId={selectedSellerCompanyId === "User2" ? null : selectedSellerCompanyId}>
                  {/* <Container className='p-0'> */}

                    <div className="message-list-card">
                      {selectedSellerCompanyId && (
                        <MessageBox
                          groupRequestId={id}
                          user1={selectedSellerId}
                          user2={selectedSellerCompanyId}
                          searchQuery={""}
                          requestType={"ORDER_REQUEST"}
                        />
                      )}
                    </div>
                  {/* </Container> */}
                </WebSocketProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminMessageBox;