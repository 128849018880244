import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  Iso,
} from "@mui/icons-material";
import { Route, Switch, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/UserList.scss";
import React from "react";
import SingleReInvite from "../popups/SingleReInvite";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../Static/SearchBar";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { OrderApiService } from "../../Service/OrderApiService";
import Constant from "../../Constant";
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Button, ButtonGroup } from "react-bootstrap";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import StatusDropdown from "../Common/StatusDropdown";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import Spinner from "../Common/Spinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ActiveOrder = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [tableData, setTableData] = useState<any[]>([]);
  const [reviewOrderCount, setReviewOrderCount] = useState(0);
  const [search, setSearch] = useState("");
  let accepted: any[] = [];
  const disableAction = useRef(false);
  const [isNeedReviewClicked, setNeedReviewClicked] = useState(false);
  const selectedRows = useRef(accepted);
  const [userRole, setUserRole] = useState<string | null>(null);
  const rejectionNote = useRef("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;
  /* interface StatusDetails {
     id: string;
     status: string;
   }*/
  const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "", quantity: 0});
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [statusFlags, setStatusFlags] = useState<Record<string, boolean>>({});
  const [isSeller, setIsSeller] = useState<boolean>(false);
  let dashboardPrefrence="";
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const handleClose = () => {
    setShow(false);
    setNote("");
    setError("");
  };
  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const handleStatusChange = async (newStatus: string, rowId: string, quantity: number) => {
    console.log(newStatus);
    // const statusMap: { [key: string]: string } = {
    //   "1": "Reserved",
    //   "2": "Pending Review",
    //   "3": "Rejected",
    //   "4": "Shipped",
    //   "5": "Cancelled",
    //   "6": "Delivered",
    // };

    // const newStatus = statusMap[eventKey];

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: rowId,
      companyId: CommonService.getCompanyId(),
      status: orderStatus.toUpperCase(),
      rejectionNote: rejectionNote.current,
      quantity: quantity
    };

    statusDetailsRef.current = sd;
    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      handleShow();
    } else {
      changeOrderStatus();
    }
  };

  const changeOrderStatus = async () => {

    console.log("rejection note value"+ rejectionNoteValue);

    if (rejectionNoteValue.trim().length == 0 && statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      console.log("Come inside it");
      setError("Please enter rejection note")
      return;
    }
    setIsLoading(true);
    try {
      statusDetailsRef.current.rejectionNote = rejectionNote.current;
      console.log(statusDetailsRef.current);
      await OrderApiService.changeOrderStatus(statusDetailsRef.current);
      const rowIndex = tableData.findIndex(
        (row) => row.id === statusDetailsRef.current.id
      );

      setIsLoading(false);
      if (rowIndex !== -1) {
        // Create a new array with the updated status for the found object
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex] = {
          ...updatedTableData[rowIndex],
          status: statusDetailsRef.current.status,
          rejectionNote: statusDetailsRef.current.rejectionNote,
        };

        // Update the tableData state with the new array
        setTableData(updatedTableData);
      }
      console.log("status updated successfully");
      handleClose();
      CommonService.Toast.fire({
        title: "Order status updated successfully",
        icon: "success",
      });
    } catch (error) {
      setIsLoading(false)
      console.log("error occurred which api calling");
    }
  };

  const fetchPendingOrdersCount = async () => {
    try {
      const response = await OrderApiService.getPendingOrdersCount();
      setReviewOrderCount(response.data);
    } catch (error) {
      console.log("error occurred while api calling");
    }
  };

  const fetchAllAcceptedOrders = async (page : number, pageSize: number) => {
    setIsLoading(true); // Set the loading flag to true before the API call

    // First fetch the count of pending orders
    await fetchPendingOrdersCount();

    try {
      const response = await OrderApiService.getOrders(page, pageSize,false);
      response.data.content.forEach(item => {
        fetchOrderRequestDetail(item.id);
      });
      setTableData(response.data.content);
      setTotalCount(response.data.totalElements); 
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set the loading flag to false after the API call completes
    }
  };

  //get all the pending orders
  const showPendingOrders = async (page : number, pageSize: number) => {
    if (!isNeedReviewClicked) {
      //update status
      setNeedReviewClicked(true);
      setIsLoading(true);
      try {
        const response = await OrderApiService.getOrders(page, pageSize,true);
        response.data.content.forEach(item => {
          fetchOrderRequestDetail(item.id);
        });
        setTableData(response.data.content);
        setTotalCount(response.data.totalElements); 
      } catch (error) {
        console.log("error is occurred");
      } finally {
        setIsLoading(false); // Set the loading flag to false after the API call completes
      }
    } else {
      //update the status
      setNeedReviewClicked(false);
      fetchAllAcceptedOrders(page, pageSize);
    }
  };

  const justShowPendingOrders = async (page : number, pageSize: number) => {
    
      //update status
      // setNeedReviewClicked(true);
      setIsLoading(true);
      try {
        const response = await OrderApiService.getOrders(page, pageSize,true);
        response.data.content.forEach(item => {
          fetchOrderRequestDetail(item.id);
        });
        setTableData(response.data.content);
        setTotalCount(response.data.totalElements); 
      } catch (error) {
        console.log("error is occurred");
      } finally {
        setIsLoading(false); // Set the loading flag to false after the API call completes
      }
    
  };

  const history = useHistory();

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    event.stopPropagation(); // Prevent event from bubbling up to the row
    const linkPrefix = userRole === "SUPER_ADMIN" ? "/master" : "/user";
    history.push(`${linkPrefix}/order-detail/${params.row.id}`);
  };

  const isUserRole = userRole === "ADMIN";

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //formate the status
  function formatStatus(status: string): string {
    const formattedStatus = status
      .split(/[_\\]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return formattedStatus;
  }

  const clicked = (event: any) => {
    if (userRole === "ADMIN") {
      event.preventDefault();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log(event.target.value);
    setNote(event.target.value);
    setError("");
    rejectionNote.current = event.target.value;

    console.log(rejectionNoteValue);
  };

  const fetchUserRole = () => {
    const cookies = new Cookies();
    const role = cookies.get("userRole");
    setUserRole(role);
    //  if (role == 'SUPER_ADMIN')
    fetchAllAcceptedOrders(page,pageSize);
    // else if (role == 'USER')
    //   showPendingOrders();
  };

  const handleRowClick = (params) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
    history.push(`${linkPrefix}/order-detail/${params.row.id}`);
  }

  const handleBack = () => {
    history.goBack();
  }

  const openOrderRequests = async (page:number , pageSize : number) => {
    try {
        setIsLoading(true);

        if (currentPath.startsWith('/user/view-as-buyer/active-order'))
          dashboardPrefrence = 'Buyer';
        else if (currentPath.startsWith('/user/view-as-owner/active-order'))
          dashboardPrefrence = 'Owner';
        else
          dashboardPrefrence= CommonService.getDashboardPrefrence();
  
        const response: any = await OrderApiService.getCompanyWiseOrderRequestsPagination(params, false, dashboardPrefrence,page,pageSize); // Applies the status filter only if isFetchOpenRequests is true;          

        response.data.content.forEach(item => {
          fetchOrderRequestDetail(item.id);
        });
        setTableData(Object.values(response.data.content)); // Convert object to array
        setTotalCount(response.data.totalElements);
    } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set the loading flag to false after the API call completes
    }
  }

  const fetchOrderRequestDetail = async (orderId: any) => {
    const response: any = await OrderApiService.getItemsByOrderId(orderId, CommonService.getDashboardPrefrence());

    const isSeller = response.data.some((item) => {
      return item.typeSenseResponse.company_id === CommonService.getCompanyId() && CommonService.isUser()
    });

    const hasActionRequiredOrPendingReview = response.data.some((item) => {
      return item.status === "ACTION_REQUIRED" || item.status === "PENDING_REVIEW";
    });

    setIsSeller(isSeller);
    setStatusFlags((prevFlags) => ({ ...prevFlags, [orderId]: hasActionRequiredOrPendingReview }));
  };

  useEffect(() => {
    if (currentPath === '/master/active-order')
      fetchUserRole();
    else
      openOrderRequests(page,pageSize);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "requestNumber",
      headerName: "Request Number",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.row.requestNumber || '-'}/>

    },
    {
      field: "createdDate",
      headerName: "Created At",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
    },
    {
      field: "quantity",
      headerName: "Total Quantity",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={`${params.value} barrels`} />
    },
    CommonService.isAdmin() && {
      field: "finalAmount",
      headerName: "Total Amount",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
    },
    {
      field: "companyName",
      headerName: "Company Name",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />
    },
    CommonService.isAdmin() && {
      field: "user",
      headerName: "Created By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },

    CommonService.isAdmin() && {
      field: "email",
      headerName: "Email",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "status",
      headerName: "Order Status",
      headerClassName: "table-header",
      cellClassName: "status-badge",
      width: 150, // Set an initial width
      minWidth: 150, // Ensure the column does not shrink below this width
      flex: 1, // Allow it to grow if there is extra space
    
      renderCell: (params: any) => {
        const isStatusValid = statusFlags[params.row.id] || false;
        return (
        <div onClick={(e) => e.stopPropagation()}>

           {/* Pending Review - User */}
          {(params.row.status === "PENDING_REVIEW" || params.row.status === "ACCEPTED_REJECTED") && CommonService.isUser() && (
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row.id,
                  params.row.quantity
                )
              }
              disableCondition={CommonService.isAdmin() || params.row.userId !== CommonService.getUserId()}
              allowedLabels={["Cancelled"]}
            />
          )}
    
      {((params.row?.status === "PENDING_REVIEW" || params.row?.status === "UNDER_REVIEW") && CommonService.isAdmin()) && (
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row.id,
                  params.row.quantity
                )
              }
              disableCondition={CommonService.isUser() || params.row.status === "CANCELLED" || params.row.status === "REJECTED" || (params.row.status === "UNDER_REVIEW" && isStatusValid)}
              allowedLabels={isStatusValid ? ["Under Review"] : ["Under Review","Accepted", "Rejected"]}
            />
          )}

           {/* Under Review - User (disabled) */}
      {( params.row?.status === "UNDER_REVIEW" && CommonService.isUser()) && (
        <StatusDropdown
          currentStatus={formatStatus( params.row?.status)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus,  params.row.id,  params.row.quantity)}
          disableCondition={true}
          allowedLabels={[]}
        />
      )}

      {/* Accepted to Payment Received - Both User and Admin */}
      {["ACCEPTED", "REJECTED", "CONTRACT_SENT", "INVOICE_SENT", "CONTRACT_EXECUTED"].includes( params.row?.status) && (
        <StatusDropdown
          currentStatus={formatStatus( params.row?.status)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id, params.row.quantity)}
          disableCondition={CommonService.isUser() ||  params.row?.status === "CANCELLED" ||  params.row?.status === "REJECTED"}
          allowedLabels={["Contract Sent", "Contract Executed", "Invoice Sent", "Payment Received", "Rejected"]}
        />
      )}

      {/* Accepted - Admin specific options */}
      { params.row?.status === "PAYMENT_RECEIVED" && (
        <StatusDropdown
          currentStatus={formatStatus( params.row?.status)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id, params.row.quantity)}
          disableCondition={CommonService.isUser() ||  params.row?.status === "CANCELLED" ||  params.row?.status === "REJECTED"}
          allowedLabels={["Ownership Transferred", "Rejected"]}
        />
      )}

      {/* Ownership Transferred or Cancelled - Both User and Admin (disabled) */}
      {( params.row?.status === "OWNERSHIP_TRANSFERRED" ||  params.row?.status === "CANCELLED") && (
        <StatusDropdown
          currentStatus={formatStatus( params.row?.status)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id, params.row.quantity)}
          disableCondition={true}
          allowedLabels={[]}
        />
      )}
    
          {params.row.status === "REJECTED" && (
            <Tooltip
              title={`Rejection Note: ${params.row.rejectionNote}`}
              arrow
            >
              <StickyNote2Icon
                className="note-icon cursor-pointer"
                style={{ color: "gray" }}
              />
            </Tooltip>
          )}
        </div>
        );
      },
    },
  ].filter(Boolean) as GridColDef[];

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return tableData.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    selectedRows.current = newSelectedRows;
    let flag: any = false;
    flag = selectedRows.current.some((user) => user.userStatus === "INVITED");
    disableAction.current = flag;
  };

  const handleOrderListChange = (page : number, pageSize: number) => {
    if (currentPath === '/master/active-order'){

      if(isNeedReviewClicked)
        justShowPendingOrders(page,pageSize);
      else
        fetchAllAcceptedOrders(page ,pageSize);
    }
    else
      openOrderRequests(page,pageSize);
  };

  // const getVariant = (status: any) => {
  //   switch (status) {
  //     case "RESERVED":
  //       return "success";
  //     case "PENDING_REVIEW":
  //       return "primary";
  //     case "REJECTED":
  //       return "danger";
  //     case "CANCELLED":
  //       return "dark";
  //     case "SHIPPED":
  //       return "info";
  //     case "DELIVERED":
  //       return "secondary";
  //     default:
  //       return "secondary";
  //   }
  // };

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  // Filter rows based on search query
  const filteredRows = tableData.filter(
    (row) =>
      CommonService.isAdmin() ?
        row.requestNumber?.toLowerCase().includes(search?.toLowerCase()) ||
        row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.email?.toLowerCase().includes(search?.toLowerCase()) ||
        (row.firstName + " " + row.lastName).toLowerCase().includes(search?.toLowerCase())
      :
        row.requestNumber?.toLowerCase().includes(search?.toLowerCase()) ||
        row.companyName?.toLowerCase().includes(search?.toLowerCase())
  );

  // Map filtered rows with unique IDs
  const tableDataWithIds = filteredRows.map((rowData, index) => ({
    ...rowData
  }));

  return (
    <>
      <div className="usersList">
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        {CommonService.isAdmin() && (<IconButton
                  onClick={handleBack}
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    mb: 1,
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>)}
          <div className="header--title mb-2 me-2">
            <h1 className="title mb-0 ms-2">
              {/* {userRole == "ADMIN" && "My Orders"} */}
              {CommonService.isAdmin() && "Order Requests"}{" "}
              <i className="bi bi-heart-fill"></i>
            </h1>
          </div>
          <div className="header--menu ms-auto mb-2">
            <div className="header--menu-items row d-flex justify-content-end">
              <div className="col-auto mb-2">
                <SearchBar search={search} setSearch={setSearch} />
              </div>
              {userRole === "SUPER_ADMIN" && ( <div className="col-auto menu-items__btnGroup btnGroup-border d-inline-flex mb-2">
                
                  <button
                    className={`white-outline-btn d-inline-flex justify-content-between align-items-center ms-1 pe-1 ${
                      isNeedReviewClicked ? "bg-white" : ""
                    }`}
                    onClick={() => {
                      showPendingOrders(page,pageSize);
                    }}
                  >
                    Need Review
                    <span className={`review--number d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2 ${
                      isNeedReviewClicked ? "bg-secondary-subtle" : "bg-white"
                    }`}>
                      {reviewOrderCount}
                    </span>
                  </button>
                
              </div>
)}              {/* <div className="col-auto menu-items__btnGroup d-inline-flex mb-2"> */}
                {/* {isNeedReviewClicked && !disableAction.current && (
                  <button className="white-outline-btn me-1">
                   
                  </button>
                )}
                {isNeedReviewClicked && !disableAction.current && (
                  <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                    
                  </button>
                )} */}
                {/* <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                  Delete
                </button> */}
                {/* <span className="counting d-inline-flex justify-content-between align-items-center ms-2">
                  {selectionModel.length}
                </span> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="user-table-container">
          <div className="userTable">
            <DataGrid
              rows={tableDataWithIds}
              columns={columns}
              className="data-grid"
              initialState={{
                pagination: {
                  paginationModel: { page: page, pageSize: pageSize },
                },
              }}
              pagination
              paginationModel={{
                page,
                pageSize,
              }}
              onPaginationModelChange={(newPaginationModel) => {
                setPage(newPaginationModel.page);
                setPageSize(newPaginationModel.pageSize);
  
                handleOrderListChange(newPaginationModel.page, newPaginationModel.pageSize);
              }}
              rowCount={totalCount}
              paginationMode="server"
              pageSizeOptions={[5, 10]}
              // checkboxSelection
              disableRowSelectionOnClick={true}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={handleSelectionModelChange}
              localeText={localeText}
              onRowClick={(params) => handleRowClick(params)}
            />
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="modal__wd modal__wd--submitSellReq">
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Order Status</Modal.Title>
          <p className="modal-text">
            Are you sure, you want to change status to Rejected ?
          </p>
        </Modal.Header>
        <Modal.Body>
        <form className="form__inner">
                    <textarea
                        required
                        className={`form-control ${error ? "is_invalid" : ""}`}
                        aria-label="With textarea"
                        value={rejectionNoteValue}
                        onChange={handleInputChange}
                        rows={8}
                        style={{resize:"none"}}
                        placeholder="Enter text here..."
                    />
                    {error && <div className="error-message">{error}</div>}
                </form>

        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button className="primary-btn w-100" onClick={changeOrderStatus} disabled={isLoading}>
               {isLoading && <Spinner/>} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActiveOrder;