import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import MessageBox from './MessageBox';
import { WebSocketProvider } from '../../Websocket/WebSocketProvider';
import { useParams } from 'react-router';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import SearchBar from '../Static/SearchBar';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';

interface ContainerMessageBoxProps {
  requestType: string;
  sellerCompanyId: string;
}

const ContainerMessageBox: React.FC<ContainerMessageBoxProps> = ({requestType, sellerCompanyId}) => {
  //requestId
  const { id } = useParams<{ id: string }>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const handleMsgBoxCollapse = ()=> {
    setIsCollapsed(!isCollapsed);
  }
  
  return (
    <WebSocketProvider requestId={id} sellerCompanyId={sellerCompanyId}>
      <div className={`msgbox-container ${isCollapsed ? '' : 'show'}`}>
        <div className="title mb-2">
          <div className="msg-icn">
            <MarkunreadOutlinedIcon sx={{ fontSize: '36px', color: '#FFF' }} />
          </div>
          <h3 className="text mb-0 mx-2">Messages</h3>
          <div className="search-box ms-auto">
            <SearchBar search={searchQuery} setSearch={setSearchQuery} />
          </div>
          {/* <form className="d-flex" role="search">
          <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
          <button className="btn btn-outline-success" type="submit">Search</button>
        </form> */}
        </div>
      <MessageBox groupRequestId={id} user1="User1" user2="User2" searchQuery={searchQuery} requestType = {requestType} />
      </div>
    </WebSocketProvider>
  );
};

export default ContainerMessageBox;

