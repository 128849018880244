import React, { useEffect, useRef, useState } from "react";
import './CompareInventory.scss';
import { InventoryApiService } from "../../Service/InventoryApiService";
import { useParams } from "react-router";
import { CommonService } from "../../Service/CommonService";
import StatusDropdown from "../Common/StatusDropdown";
import Constant from "../../Constant";
import Modal from "react-bootstrap/Modal";
import Spinner from "../Common/Spinner";
import ContainerMessageBox from "./ContainerMessageBox";
import { useRoute } from "../Common/route";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CompareInventory: React.FC = () => {
    const route = useRoute();
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState(false);
    // Define the localeText object based on the isLoading flag
    const localeText = {
        noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
    };
    const [data, setData] = useState<any>({});
    const [difference, setDifferenceData] = useState<any>({});
    const items = Object.keys(difference);
    const rejectionNote = useRef("");
    const [rejectionNoteValue, setNote] = useState<string>("");
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "" });
    const handleClose = () => {
        setShow(false);
        setError("");
        setNote("");
    };
    const [error, setError] = useState<string>("");

    const fetchInventoryById = async () => {
        try {
            setIsLoading(true);
            const response = await InventoryApiService.getInventoryChangeRequestById(id);
            setData(response.data);
            setDifferenceData(response.data['difference']);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    };

    useEffect(() => {
        fetchInventoryById();
    }, []);

    const handleStatusChange = async (newStatus: string, inventoryId: any) => {

        const status = newStatus?.replace(/ /g, "_");
        //create the object to send
        const sd = {
            id: id,
            inventoryId: inventoryId,
            status: status.toUpperCase(),
            rejectionNote: rejectionNote.current,
        };
        statusDetailsRef.current = sd;
        if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
            handleShow();
        } else {
            updateInventoryChangeRequestStatus();
        }
    };

    const updateInventoryChangeRequestStatus = async () => {

        if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED && rejectionNoteValue.trim().length === 0) {
            setError("Please enter rejection note");
            return;
        }
        setIsLoading(true);
        try {
            statusDetailsRef.current.rejectionNote = rejectionNote.current;
            await InventoryApiService.updateInventoryChangeRequestStatus(statusDetailsRef.current);
            setIsLoading(false);
            CommonService.Toast.fire({
                title: "Inventory Change Request Updated Successfully",
                icon: "success",
            });
            handleClose();
            fetchInventoryById();
        } catch (error) {
            setIsLoading(false);
            console.log("error occurred which api calling");
        }
    };

    //formate the status
    function formatStatus(status?: string): string {

        let formattedStatus = "";
        if (status !== undefined) {
            formattedStatus = status?.split(/[_\\]/)
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
        }
        return formattedStatus;
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {

        setNote(event.target.value);
        setError("");
        rejectionNote.current = event.target.value;
    };

    return (
        <>
        <div className="usersList rounded-0 compare-inventory-page">
            <div className="usersList__header--wrapper d-flex flex-wrap align-items-center mb-2">
                {CommonService.isAdmin() && (
                    <div className="back__button me-3"  onClick={() =>
                        route("/master/inventory-change-requests")
                      }>
                        <IconButton
                            aria-label="ArrowBackIcon"
                            sx={{ backgroundColor: "#FFF", mb: 1 }}
                        >
                            <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                        </IconButton>
                    </div>
                )}

                <div className="header--title mb-2 me-3">
                    <h1 className="title mb-0">
                        Updated Inventories
                        <i className="bi bi-heart-fill"></i>
                    </h1>
                </div>
                <div className="header--menu mb-2">
                    <div className="header--menu-items row d-flex">
                        <div className="col-auto mb-2">
                            <span>Company Name</span><br />
                            <span className="fw-bold">{data['companyName']}</span>
                        </div>
                        <div className="col-auto mb-2">
                            <span>Created By</span><br />
                            <span className="fw-bold">{data['createdBy']}</span>
                        </div>
                        <div className="col-auto mb-2">
                            <span>Status</span><br />
                            <span className="d-inline-block fw-bold">
                            {data['status'] === "PENDING_REVIEW" && CommonService.isUser() && (
                                <StatusDropdown
                                currentStatus={data['status']}
                                onStatusChange={(newStatus) =>
                                    handleStatusChange(newStatus,data['inventoryId'])
                                }
                                disableCondition={false}
                                allowedLabels={["Cancelled"]}
                                />
                            )}
                            {((data['status'] !== "PENDING_REVIEW" && CommonService.isUser()) || CommonService.isAdmin()) &&
                                <StatusDropdown
                                currentStatus={formatStatus(data['status'])}
                                onStatusChange={(newStatus) => {
                                    handleStatusChange(newStatus,data['inventoryId']);
                                }}
                                disableCondition={CommonService.isUser() || data['status'] === "CANCELLED"}
                                allowedLabels={[
                                    "Accepted",
                                    "Rejected"
                                ]}
                                />}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="user-table-container d-flex flex-column flex-lg-row">
                <div className="userTable mb-3 mb-lg-0 p-3">
                    <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Items</th>
                                    <th>Before</th>
                                    <th>Now</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.length === 0 ? (
                                    <tr>
                                        <td colSpan={3}>{localeText.noRowsLabel}</td>
                                    </tr>
                                ) : (
                                    items.map((item) => (
                                        (difference[item][0] === true || difference[item][0] === false) ? (
                                            <tr key={item}>
                                                <td>{item}</td>
                                                <td>
                                                    <div className="custom-radio-container">
                                                        <div className="d-inline-flex me-2">
                                                            <input
                                                                type="radio"
                                                                className="custom-radio"
                                                                name="valueBefore"
                                                                id="valueBefore"
                                                                value="true"
                                                                checked={difference[item][0] === true}
                                                               /* onChange={() =>
                                                                    //setFormData({
                                                                    //    ...formData,
                                                                    //    isRelocationRequired: true,
                                                                    //})
                                                                }*/
                                                            />
                                                            <label
                                                                className="custom-radio-label"
                                                                htmlFor="valueBefore"
                                                            >
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="d-inline-flex">
                                                            <input
                                                                type="radio"
                                                                className="custom-radio"
                                                                name="valueBefore"
                                                                id="valueBefore"
                                                                value="false"
                                                                checked={difference[item][0] === false}
                                                                /*onChange={() =>
                                                                    setFormData({
                                                                        ...formData,
                                                                        isRelocationRequired: false,
                                                                    })
                                                                } */
                                                            />
                                                            <label
                                                                className="custom-radio-label"
                                                                htmlFor="valueBefore"
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                <div className="custom-radio-container">
                                                        <div className="d-inline-flex me-2">
                                                            <input
                                                                type="radio"
                                                                className="custom-radio"
                                                                name="valueAfter"
                                                                id="valueAfter"
                                                                value="true"
                                                                checked={difference[item][1] === true}
                                                               /* onChange={() =>
                                                                    //setFormData({
                                                                    //    ...formData,
                                                                    //    isRelocationRequired: true,
                                                                    //})
                                                                }*/
                                                            />
                                                            <label
                                                                className="custom-radio-label"
                                                                htmlFor="valueAfter"
                                                            >
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="d-inline-flex">
                                                            <input
                                                                type="radio"
                                                                className="custom-radio"
                                                                name="valueAfter"
                                                                id="valueAfter"
                                                                value="false"
                                                                checked={difference[item][1] === false}
                                                                /*onChange={() =>
                                                                    setFormData({
                                                                        ...formData,
                                                                        isRelocationRequired: false,
                                                                    })
                                                                } */
                                                            />
                                                            <label
                                                                className="custom-radio-label"
                                                                htmlFor="valueAfter"
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : <tr key={item}>
                                                <td>{item}</td>
                                                <td>{difference[item][0]}</td>
                                                <td>{difference[item][1]}</td>
                                            </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="msgContainerBox">
                    <ContainerMessageBox requestType={"INVENTORY_CHANGE_REQUEST"} sellerCompanyId={null} />
                </div>
            </div>
        </div>

        {/* Status Change Model Pop-up */}
        <Modal
            show={show}
            onHide={handleClose}
            className="modal__wd modal__wd--submitSellReq"
        >
            <Modal.Header className="flex-column align-items-start">
                <Modal.Title>Update Inventory Change Request Status</Modal.Title>
                <p className="modal-text">
                    Are you sure, you want to change status to Rejected ?
                </p>
            </Modal.Header>

            <Modal.Body>
                <div className="form__inner">
                    <textarea
                        className={`form-control ${error ? "is_invalid" : ""}`}
                        aria-label="With textarea"
                        value={rejectionNoteValue}
                        onChange={handleInputChange}
                        rows={8}
                        style={{ resize: "none" }}
                        placeholder="Enter Rejection Note..."
                    />
                    {error && <div className="error-message">{error}</div>}
                </div>
            </Modal.Body>

            <Modal.Footer className="justify-content-center">
                <div className="btn-group row d-flex justify-content-between w-100">
                    <div className="col-6 ps-0">
                        <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                            No
                        </button>
                    </div>

                    <div className="col-6 pe-0">
                        <button className="primary-btn w-100" onClick={updateInventoryChangeRequestStatus} disabled={isLoading}>
                            {isLoading && <Spinner />} Yes
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default CompareInventory;