import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import MessageBox from "./MessageBox";
import { WebSocketProvider } from "../../Websocket/WebSocketProvider";
import { useParams } from "react-router";
import { MessageApiService } from "../../Service/MessageApiService";
import "./GroupMessageBox.scss";
import { Typography } from "@mui/material";
import ListGroup from "react-bootstrap/ListGroup";
import OfferPercentage from "../../assets/images/icons/OfferPercentage.svg";
import SampleDrop from "../../assets/images/icons/Sample.svg";
import ShoppingCartSimple from "../../assets/images/icons/ShoppingCartSimple.svg";
import SellRequestSimple from "../../assets/images/icons/SellRequestSimple.svg";
import SearchIcon from "@mui/icons-material/Search";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { CommonService } from "../../Service/CommonService";
import NoGroupMessage from "../Common/NoGroupMessage";
import NoChatMessage from "../Common/NoChatMessage";

const GroupMessageBox: React.FC<{}> = ({ }) => {
  //requestId
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState<any[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadMessages("All");
  }, []);

  const loadMessages = async (tab: string) => {
    try {
      setIsLoading(true);

      const response = await MessageApiService.getGroupsMessage(tab);
      console.log(response.data);
      if (response.data.length > 0) {
        response.data[0].unreadMessageCount = 0;
      }
      setGroups([...response.data]);
      setSelectedGroup(response.data[0]);
      setIsLoading(false);
    } catch (error) {
      console.log("error fetching data" + error);
      setIsLoading(false);
    }
  };

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  // Filter rows based on search query
  const filteredRows = groups.filter(
    (row) =>
      row.sendBy?.toLowerCase().includes(search?.toLowerCase()) ||
      row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
      row.requestType
        ?.toLowerCase()
        .replace(/_/g, " ")
        .includes(search?.toLowerCase()) ||
      row.modifiedDate?.toLowerCase().includes(search?.toLowerCase())
  );

  // Map filtered rows with unique IDs
  const groupsData = filteredRows.map((rowData, index) => ({
    ...rowData,
  }));

  const setGroupMessagesAsRead = (group: any) => {
    const updatedGroups = groupsData.map((g) =>
      g.requestId === group.requestId ? { ...g, unreadMessageCount: 0 } : g
    );

    // Update state with the new groups array
    setGroups(updatedGroups);

    // Set the selected group
    setSelectedGroup(group);
  };

  return (
    <div className="group-chat-wrapper">
      {CommonService.isAdmin() && (<Typography
        variant="h1"
        gutterBottom
        sx={{
          px: 1,
          mb: 2,
          fontSize: "25px",
          fontWeight: 600,
          color: "#333333",
        }}
      >
        Messages
      </Typography>)}
      <div className="d-flex">
        <div className="group-chat-container row d-flex flex-grow-1 m-0">
          <div className="group-list-wrapper col-md-6 col-lg-5 col-xl-4 mb-3 mb-md-0">
            {/* {groups.map((group) => (
              <div
                key={group.requestId}
                className={`group-item ${selectedGroup?.requestId === group.requestId ? 'selected' : ''}`}
                onClick={() => setSelectedGroup(group)}
              >
                {group.sendBy}
              </div>
            ))} */}
            {CommonService.isUser() && (
              <nav className="group-chat-nav-wrapper">
                <div
                  className="nav nav-tabs justify-content-around mt-3"
                  id="nav-tab"
                  role="tablist"
                >
                  <button
                    onClick={() => loadMessages("All")}
                    className="nav-link active"
                    id="nav-all-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-all"
                    type="button"
                    role="tab"
                    aria-controls="nav-all"
                    aria-selected="true"
                  >
                    All
                  </button>
                  <button
                    onClick={() => loadMessages("My Wearhouse")}
                    className="nav-link"
                    id="nav-warehouse-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-warehouse"
                    type="button"
                    role="tab"
                    aria-controls="nav-warehouse"
                    aria-selected="false"
                  >
                    Owner Message Group
                  </button>
                  <button
                    onClick={() => loadMessages("My Requests")}
                    className="nav-link"
                    id="nav-requests-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-requests"
                    type="button"
                    role="tab"
                    aria-controls="nav-requests"
                    aria-selected="false"
                  >
                    Buyer Message Group
                  </button>
                </div>
              </nav>
            )}

            <div className="position-relative mt-3">
              <Form.Control
                type="text"
                placeholder="Search"
                className="chat-search mr-sm-2 pl"
                onChange={(e) => setSearch(e.target.value)}
              />
              <SearchIcon
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "15px",
                  transform: "translateY(-50%)",
                }}
              />
            </div>
            <div className="text-muted">
              <hr />
            </div>

            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-all"
                role="tabpanel"
                aria-labelledby="nav-all-tab"
                tabIndex={0}
              >
                {/* {selectedGroup ? (  */}
                <ListGroup>
                  {groupsData?.map((group, index) => (
                    <ListGroup.Item
                      className="cursor-pointer"
                      style={{
                        backgroundColor:
                          selectedGroup?.requestId === group.requestId
                            ? "white"
                            : "initial",
                      }}
                      onClick={() => {
                        setGroupMessagesAsRead(group);
                      }}
                    >
                      <div className="chat-info-wrapper py-2">
                        <div className="row">
                          <div className="col-auto pe-0">
                            <div className="avtar d-inline-flex justify-content-center align-items-center position-relative">
                              {group?.sendBy?.charAt(0)}
                              <span className="active-status online position-absolute top-0 start-0"></span>
                            </div>
                          </div>
                          <div className="col-auto pe-0">
                            <div className="chat__info d-flex d-sm-block flex-column">
                              <span className="chat__info--name">
                                {group.sendBy}
                              </span>
                              <br className="d-none d-sm-block" />
                              <span className="chat__info--company me-2">
                                {group.companyName}
                              </span>
                              <span className="d-none d-sm-inline">|</span>
                              <span className="chat__info--reqType ms-sm-2">
                                {group.requestType == "OFFER_REQUEST" && (
                                  <img
                                    src={OfferPercentage}
                                    alt="OfferPercentage"
                                  />
                                )}
                                {group.requestType == "SAMPLE_REQUEST" && (
                                  <img src={SampleDrop} alt="SampleDrop" />
                                )}
                                {group.requestType == "ORDER_REQUEST" && (
                                  <img
                                    src={ShoppingCartSimple}
                                    alt="ShoppingCartSimple"
                                  />
                                )}
                                {group.requestType == "SELL_REQUEST" && (
                                  <img
                                    src={SellRequestSimple}
                                    alt="SellRequestSimple"
                                  />
                                )}
                                {toTitleCase(group.requestType)}
                              </span>
                            </div>
                          </div>
                          <div className="col-auto ms-auto">
                            <div className="time__info d-flex flex-column text-end lh-base">
                              <span className="date">
                                {group.modifiedDate.split(" | ")[0]}
                              </span>
                              <span className="time">
                                {group.modifiedDate.split(" | ")[1]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="msg-preview mb-0">
                            {" "}
                            {
                              JSON.parse(group.messages[0])[
                              JSON.parse(group.messages[0]).length - 1
                              ]
                            }
                            ...
                          </p>
                          {group.unreadMessageCount !== 0 && (
                            <span className="msg-count d-inline-flex justify-content-center align-items-center rounded-circle">
                              {group.unreadMessageCount}
                            </span>
                          )}
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>

                {groupsData.length <= 0 && <NoGroupMessage />}
              </div>
              <div
                className="tab-pane fade"
                id="nav-warehouse"
                role="tabpanel"
                aria-labelledby="nav-warehouse-tab"
                tabIndex={0}
              >
                {/* {selectedGroup ? ( */}
                <ListGroup>
                  {groupsData.map((group, index) => (
                    <ListGroup.Item
                      className="cursor-pointer"
                      style={{
                        backgroundColor:
                          selectedGroup?.requestId === group.requestId
                            ? "white"
                            : "initial",
                      }}
                      onClick={() => {
                        setGroupMessagesAsRead(group);
                      }}
                    >
                      <div className="chat-info-wrapper py-2">
                        <div className="row">
                          <div className="col-auto pe-0">
                            <div className="avtar d-inline-flex justify-content-center align-items-center position-relative">
                              {group?.sendBy?.charAt(0)}
                              <span className="active-status online position-absolute top-0 start-0"></span>
                            </div>
                          </div>
                          <div className="col-auto pe-0">
                            <div className="chat__info d-flex d-sm-block flex-column">
                              <span className="chat__info--name">
                                {group.sendBy}
                              </span>
                              <br className="d-none d-sm-block" />
                              <span className="chat__info--company me-2">
                                {group.companyName}
                              </span>
                              <span className="d-none d-sm-inline">|</span>
                              <span className="chat__info--reqType ms-sm-2">
                                {group.requestType == "OFFER_REQUEST" && (
                                  <img
                                    src={OfferPercentage}
                                    alt="OfferPercentage"
                                  />
                                )}
                                {group.requestType == "SAMPLE_REQUEST" && (
                                  <img src={SampleDrop} alt="SampleDrop" />
                                )}
                                {group.requestType == "ORDER_REQUEST" && (
                                  <img
                                    src={ShoppingCartSimple}
                                    alt="ShoppingCartSimple"
                                  />
                                )}
                                {group.requestType == "SELL_REQUEST" && (
                                  <img
                                    src={SellRequestSimple}
                                    alt="SellRequestSimple"
                                  />
                                )}
                                {toTitleCase(group.requestType)}
                              </span>
                            </div>
                          </div>
                          <div className="col-auto ms-auto">
                            <div className="time__info d-flex flex-column text-end lh-base">
                              <span className="date">
                                {group.modifiedDate.split(" | ")[0]}
                              </span>
                              <span className="time">
                                {group.modifiedDate.split(" | ")[1]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="msg-preview mb-0">
                            {" "}
                            {
                              JSON.parse(group.messages[0])[
                              JSON.parse(group.messages[0]).length - 1
                              ]
                            }
                            ...
                          </p>
                          {group.unreadMessageCount !== 0 && (
                            <span className="msg-count d-inline-flex justify-content-center align-items-center rounded-circle">
                              {group.unreadMessageCount}
                            </span>
                          )}
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                {groupsData.length <= 0 && <NoGroupMessage />}
              </div>
              <div
                className="tab-pane fade"
                id="nav-requests"
                role="tabpanel"
                aria-labelledby="nav-requests-tab"
                tabIndex={0}
              >
                {/* {selectedGroup ? (  */}
                <ListGroup>
                  {groupsData.map((group, index) => (
                    <ListGroup.Item
                      className="cursor-pointer"
                      style={{
                        backgroundColor:
                          selectedGroup?.requestId === group.requestId
                            ? "white"
                            : "initial",
                      }}
                      onClick={() => {
                        setGroupMessagesAsRead(group);
                      }}
                    >
                      <div className="chat-info-wrapper py-2">
                        <div className="row">
                          <div className="col-auto pe-0">
                            <div className="avtar d-inline-flex justify-content-center align-items-center position-relative">
                              {group?.sendBy?.charAt(0)}
                              <span className="active-status online position-absolute top-0 start-0"></span>
                            </div>
                          </div>
                          <div className="col-auto pe-0">
                            <div className="chat__info d-flex d-sm-block flex-column">
                              <span className="chat__info--name">
                                {group.sendBy}
                              </span>
                              <br className="d-none d-sm-block" />
                              <span className="chat__info--company me-2">
                                {group.companyName}
                              </span>
                              <span className="d-none d-sm-inline">|</span>
                              <span className="chat__info--reqType ms-sm-2">
                                {group.requestType == "OFFER_REQUEST" && (
                                  <img
                                    src={OfferPercentage}
                                    alt="OfferPercentage"
                                  />
                                )}
                                {group.requestType == "SAMPLE_REQUEST" && (
                                  <img src={SampleDrop} alt="SampleDrop" />
                                )}
                                {group.requestType == "ORDER_REQUEST" && (
                                  <img
                                    src={ShoppingCartSimple}
                                    alt="ShoppingCartSimple"
                                  />
                                )}
                                {group.requestType == "SELL_REQUEST" && (
                                  <img
                                    src={SellRequestSimple}
                                    alt="SellRequestSimple"
                                  />
                                )}
                                {toTitleCase(group.requestType)}
                              </span>
                            </div>
                          </div>
                          <div className="col-auto ms-auto">
                            <div className="time__info d-flex flex-column text-end lh-base">
                              <span className="date">
                                {group.modifiedDate.split(" | ")[0]}
                              </span>
                              <span className="time">
                                {group.modifiedDate.split(" | ")[1]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="msg-preview mb-0">
                            {" "}
                            {
                              JSON.parse(group.messages[0])[
                              JSON.parse(group.messages[0]).length - 1
                              ]
                            }
                            ...
                          </p>
                          {group.unreadMessageCount !== 0 && (
                            <span className="msg-count d-inline-flex justify-content-center align-items-center rounded-circle">
                              {group.unreadMessageCount}
                            </span>
                          )}
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                {groupsData.length <= 0 && <NoGroupMessage />}
              </div>
            </div>
          </div>

          <div className="message-list-wrapper col-md-6 col-lg-7 col-xl-8 position-relative">
            {selectedGroup ? (
              <WebSocketProvider requestId={selectedGroup.requestId}  sellerCompanyId={null}>
                <Container>
                  <div className="row d-flex justify-content-between border-bottom m-0 pb-2">
                    <div className="col-auto ps-0">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "inline",
                          fontWeight: 700,
                          fontSize: "18px",
                        }}
                      >
                        {selectedGroup.sendBy} |
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "inline",
                          ml: 1,
                          fontWeight: 700,
                          fontSize: "18px",
                          color: "#8F9195",
                        }}
                      >
                        {selectedGroup.companyName}
                      </Typography>
                      {/* Anderson Smith |  Max Inc */}
                    </div>
                    <div
                      className="col-auto ms-auto pe-0"
                      onClick={() => setShowDetail(!showDetail)}
                    >
                      <button
                        type="button"
                        className="btn btn-outline-offer d-inline-flex align-items-center"
                      >
                        {selectedGroup.requestType == "OFFER_REQUEST" && (
                          <img
                            src={OfferPercentage}
                            alt="OfferPercentage"
                            className="me-1"
                          />
                        )}
                        {selectedGroup.requestType == "SAMPLE_REQUEST" && (
                          <img
                            src={SampleDrop}
                            alt="SampleDrop"
                            className="me-1"
                          />
                        )}
                        {selectedGroup.requestType == "ORDER_REQUEST" && (
                          <img
                            src={ShoppingCartSimple}
                            alt="ShoppingCartSimple"
                            className="me-1"
                          />
                        )}
                        {selectedGroup.requestType == "SELL_REQUEST" && (
                          <img
                            src={SellRequestSimple}
                            alt="SellRequestSimple"
                            className="me-1"
                          />
                        )}

                        {toTitleCase(selectedGroup.requestType)}
                      </button>
                    </div>
                  </div>
                  <div className="message-list-card">
                    <MessageBox
                      groupRequestId={selectedGroup.requestId}
                      user1="User1"
                      user2="User2"
                      searchQuery={searchQuery}
                      requestType={selectedGroup.requestType}
                    />
                  </div>
                </Container>
              </WebSocketProvider>
            ) : (
              <NoChatMessage />
            )}
          </div>
        </div>
        {showDetail && (
          <div className="offer-req-container ms-3">
            {selectedGroup.requestType == "OFFER_REQUEST" && (
              <ListGroup>
                <ListGroup.Item>
                  <h2 className="title mb-0">
                    {" "}
                    {selectedGroup.inventoryName}{" "}
                  </h2>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Total Quantity</span>
                  <span className="info">{selectedGroup.quantity}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Total Price</span>
                  <span className="info">
                    <CurrencyFormatter
                      amount={selectedGroup.totalAmount}
                      locale="en-US"
                      currency="USD"
                    />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Offer Price/Barrels</span>
                  <span className="info">
                    <CurrencyFormatter
                      amount={selectedGroup.pricePerBarrel}
                      locale="en-US"
                      currency="USD"
                    />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Request Status</span>
                  <span className="info">{selectedGroup.status}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Created Date</span>
                  <span className="info"> {selectedGroup.createdDate}</span>
                </ListGroup.Item>
              </ListGroup>
            )}
            {selectedGroup.requestType == "SAMPLE_REQUEST" && (
              <ListGroup>
                <ListGroup.Item>
                  <h2 className="title mb-0">{selectedGroup.inventoryName}</h2>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Sample Size</span>
                  <span className="info">{selectedGroup.size}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Total Quantity</span>
                  <span className="info">{selectedGroup.quantity}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Purpose</span>
                  <span className="info">{selectedGroup.purpose}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Request Status</span>
                  <span className="info">{selectedGroup.status}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Created Date</span>
                  <span className="info">{selectedGroup.createdDate}</span>
                </ListGroup.Item>
              </ListGroup>
            )}
            {selectedGroup.requestType == "ORDER_REQUEST" && (
              <ListGroup>
                <ListGroup.Item>
                  <h2 className="title mb-0">{selectedGroup.requestNumber}</h2>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Total Quantity</span>
                  <span className="info">{selectedGroup.quantity} barrels</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Total Amount</span>
                  <span className="info">
                    <CurrencyFormatter
                      amount={selectedGroup.totalAmount}
                      locale="en-US"
                      currency="USD"
                    />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Request Status</span>
                  <span className="info">{selectedGroup.status}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Created Date</span>
                  <span className="info">{selectedGroup.createdDate}</span>
                </ListGroup.Item>
              </ListGroup>
            )}
            {selectedGroup.requestType == "SELL_REQUEST" && (
              <ListGroup>
                <ListGroup.Item>
                  <h2 className="title mb-0">{selectedGroup.inventoryName}</h2>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Fee Type</span>
                  <span className="info">{selectedGroup.feeType}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Platform Fee</span>
                  <span className="info">
                    {selectedGroup.feeType === "FLAT" && (
                      <>
                        <CurrencyFormatter
                          amount={selectedGroup.platformFee}
                          locale="en-US"
                          currency="USD"
                        />
                        / Barrel
                      </>
                    )}
                    {selectedGroup.feeType !== "FLAT" &&
                      `${selectedGroup.platformFee} %`}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Request Status</span>
                  <span className="info">{selectedGroup.status}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-title">Created Date</span>
                  <span className="info">{selectedGroup.createdDate}</span>
                </ListGroup.Item>
              </ListGroup>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupMessageBox;
