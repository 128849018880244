import React, { useEffect, useState } from "react";
import { Service } from "../../Service/ApiService";
import { Button } from "react-bootstrap";
import { useLocation, useParams } from "react-router";
import StatusDropdown from "../Common/StatusDropdown";
import { CommonService } from "../../Service/CommonService";
import ProfileIcon from "../../assets/images/profile/1.png";
import AlterImage from "../../assets/images/alter_image.png";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Tooltip } from "@mui/material";
import { OrderApiService } from "../../Service/OrderApiService";

const RecentRequest: React.FC = () => {
  const [recentRequests, setRecentRequests] = useState<any[]>([]);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isDashboard = location.pathname === "/master/dashboard";
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isSeller, setIsSeller] = useState<boolean>(false);

  const fetchRecentRequestCount = async (page: number, size: number) => {
   try{
    setIsLoading(true);
    const params = CommonService.isUser() ? { id: CommonService.getCompanyId() } : { id };
    const response: any = await Service.fetchRecentRequestCount( params, page, size, CommonService.getDashboardPrefrence());
    setRecentRequests(response.data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.error("Error fetching data:", error);
  }
  };

  useEffect(() => {
    fetchRecentRequestCount(0, pageSize);
  }, [location.pathname]);

  function formatStatus(status: string): string {
    const formattedStatus = status
      .split(/[_\\]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return formattedStatus;
  }

  //add tootip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const columns: GridColDef[] = [
    {
      field: "requestType",
      headerName: "Request Type",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.row.requestType}/>
    },
    {
      field: "requestNumber",
      headerName: "Request Number",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => (
        <CellWithTooltip value={params.row.requestNumber || "-"} />
      )
    },
    isDashboard && {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "createdBy",
      headerName: "User",
      flex: 1,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div className="d-flex gap-2 align-items-center">
        <div className="user-img-wrapper">
          <img src={AlterImage} alt="CompanyIcn" />
        </div>
        <span><CellWithTooltip value={params.value} /></span>
      </div>
   )
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />   
    },
    isDashboard && {
      field: "phoneNumber",
      headerName: "Phone",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "modifiedDate",
      headerName: "Created At",
      headerClassName: "table-header",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.modifiedDate ? row.modifiedDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => (
        <div className="cell-with-dropdown mb-2" onClick={(e) => e.stopPropagation()}>
            <StatusDropdown
                        currentStatus={formatStatus(params.value)}
                        onStatusChange={(newStatus) => {}}
                        disableCondition={true}
                        allowedLabels={[]}
            />
        </div>)
    }
  ].filter(Boolean) as GridColDef[];

  return (
    <div className="custom-table-outter">
      <div className="mt-3 dashboard__inner mb-3 mx-0  gap-0">
        <div className="d-flex justify-content-between mb-3 ps-0">
          <h5 className="mb-0">
            <b>Recent Requests</b>
          </h5>
          {/* <Button className='p-0' variant="link" onClick={() => route("/master/messages")}>View all</Button> */}
        </div>
        <div className="table-responsive ps-0">
          <Box sx={{ height: 350, width: '100%' }}>
              <DataGrid
                  localeText={localeText}
                  rows={recentRequests}
                  columns={columns}
                  pageSizeOptions={[5, 10]}
                  className="data-grid"
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pagination
                  disableRowSelectionOnClick={true}
                  getRowId={(row) => row.requestId}
              />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default RecentRequest;
