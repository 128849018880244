import React, { useEffect, useState } from "react";
import "./ProfileBar.scss";
import { CookieService } from "../../../Service/CookieService";
import { useHistory, useLocation } from "react-router";
import userImg from "../../../assets/images/profile/Persona.png";
import AlterImage from '../../../assets/images/alter_image.png';
import menuIcon from "../../../assets/images/icons/menu_dots_icn.svg";
import Cookies from "universal-cookie";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { IconButton, Tooltip } from "@mui/material";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useRoute } from "../../Common/route";
import notificationWebSocketService from "../../../Websocket/NotificationWebSocketService";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import { Typography } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import MessageNotification from "../../Common/MessageNotification";
import { CommonService } from "../../../Service/CommonService";
import { Service } from "../../../Service/ApiService";
import { OrderApiService } from "../../../Service/OrderApiService";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDispatch } from 'react-redux';
import { resetMyCartItems, setMyCartItems } from "../../store/cartSlice";


const ProfileBar = () => {
  const history = useHistory();
  const route = useRoute();
  // Define state variables for name and login type
  const [name, setName] = useState<string | null>(null);
  const [loginType, setLoginType] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [userId, setUserId] = useState("");
  const [imageSrc, setImageSrc] = useState<string | null>(localStorage.getItem('profile'));
  const myCartItems = useSelector((state: RootState) => state.cart.myCartItems);
  const dispatch = useDispatch();

  const fetchData = async () => {
    if(role != "SUPER_ADMIN"){
    try {
      const response: any = await OrderApiService.getOrderItems();
      console.log("user cart length - " + response?.data?.length);
      //set  count from item for profile
      dispatch(setMyCartItems(response?.data?.length));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  };

  useEffect(() => {
    const handleProfileImageChange = () => {
      setImageSrc(localStorage.getItem('profile'));
    };
    window.addEventListener('profileImageChanged', handleProfileImageChange);
  
    return () => {
      window.removeEventListener('profileImageChanged', handleProfileImageChange);
    };
  }, []);

  useEffect(() => {
    const fetchUserDetails = () => {
      const cookies = new Cookies();
      const role = cookies.get("userRole");
      setUserId(cookies.get("userId"));

      setRole(role);
      setLoginType(capitalizeString(role));

      const name = cookies.get("userName");
      setName(capitalizeString(name));
    };

    function capitalizeString(str: string): string {
      // Check if the string is empty
      if (!str.trim()) {
        return "";
      } else {
        // Split the string into words
        const words = str.split(" ");

        // Capitalize the first letter of each word
        const capitalizedWords = words.map((word) => {
          // Capitalize the first letter of the word
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });

        // Join the capitalized words back into a string
        return capitalizedWords.join(" ");
      }
    }

    fetchUserDetails();
    getProfileImage();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const logout = async () => {

    //call the api for logout user
    try{
      await Service.logout();

    } catch(error){
      console.log(error);
    }
    
    //remove the token from the cookies
    CookieService.removeAuthToken();
    notificationWebSocketService.disconnect();
    history.push("/login");
  };

  //redirect to the profile page
  const handleProfile = () => {
    CommonService.isAdmin() ? history.push("/master/my-profile/" + userId) : history.push("/user/my-profile/" + userId);
  };

  const getProfileImage = async () => {
    if (CommonService.getProfile() == null) {
      const requestDto = {
        id: CommonService.getUserId()
      };
      try {

        const response: any = await Service.getProfileImage(requestDto);
        const blob = response.data as Blob;
        if (blob.size != 0) {
          // Create a URL for the Blob and set it as the image source
          const imageUrl = URL.createObjectURL(blob);
          setImageSrc(imageUrl);
          localStorage.setItem("profile", imageUrl);
        } else {
          setImageSrc(null);
        }
      } catch (error) {
        console.error("Error occured on fetching profile picture:", error);
      }
    } else {
      setImageSrc(CommonService.getProfile());
    }
  };

  return (
    <>
      <div className="profile__bar d-inline-flex align-items-center justify-content-between px-2 py-1">
        <div className="profile__bar--info d-inline-flex align-items-center">
        {role != "SUPER_ADMIN" && (<div className="profile--notification me-2">
            {/* //add the message Notification such that only show the count and icon when i click then show the list of messages */}
            <MessageNotification userId={userId} />
          </div>)}
          {role != "SUPER_ADMIN" && (
            <div className="profile--notification profile--cart me-2">
              <Tooltip title="My Cart" arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => route("/user/my-cart")}
                  className="btn-basic"
                >
                  <ShoppingCartOutlinedIcon />
                  {(myCartItems > 0) && (
          <span
            className="msg-count"
            aria-label={`${myCartItems} notifications`}
          >
            {myCartItems}
          </span>
        )}
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div className="profile--pic me-2 cursor-pointer" onClick={handleProfile}>
            <img src={imageSrc || AlterImage} alt="userImg" 
                 onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.onerror = null; // Prevent infinite loop if the fallback image fails
                              target.src = AlterImage;
                              localStorage.removeItem("profile");
                          }}
            />
          </div>
          <div className="profile--name cursor-pointer" onClick={handleProfile}>
            {name && <h2 className="name mb-0">{name}</h2>}
            {/* <p className="login--type mb-0">{loginType}</p> */}
          </div>
        </div>
        <div className="profile__bar--menu ms-2">
          <div className="dropdown">
            <button
              className="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={menuIcon} alt="menuIcon" />
            </button>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                  onClick={logout}
                >
                  <LogoutRoundedIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileBar;
