import React, { useEffect, useMemo, useRef, useState } from "react";
import { Service } from "../../Service/ApiService";
import SearchIcon from "@mui/icons-material/Search";
import "../Dynamic/GroupMessageBox.scss";
import "../../sass/responsive.scss";
import "../Dynamic/SupportRequest.scss";
import { Typography, Box, Button, TextField, Tabs, Tab, IconButton } from "@mui/material";
import { Container, Form, ListGroup } from "react-bootstrap";
import { CommonService } from "../../Service/CommonService";
import NoGroupMessage from "../Common/NoGroupMessage";
import SupportCommentBox from "./SupportCommentBox"; // Import the SupportCommentBox
import NoChatMessage from "../Common/NoChatMessage";
import Spinner from "../Common/Spinner";
import TrashIcon from "../../assets/images/icons/Trash.svg";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NoTicketCreated from "../Common/NoTicketCreated";
import NoSupportComment from "../Common/NoSupportComment";
import FAQList from "../popups/FAQList";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { useWebSocket, WebSocketProvider } from "../../Websocket/WebSocketProvider";

const SupportRequest: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [activeTab, setActiveTab] = useState("open");
  const [search, setSearch] = useState("");
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [openRequestCount, setOpenRequestCount] = useState<number>(0);
  const [closedRequestCount, setClosedRequestCount] = useState<number>(0);
  const [supportRequests, setSupportRequests] = useState<any[]>([]);
  const [isNewTicket, setNewTicket] = useState(false);
  const [comment, setComment] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  useEffect(() => {
    loadSupportRequests("OPEN");
    getOpenRequestCount();
    getClosedRequestCount();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      // Replace old files with new ones
      const newFiles = Array.from(event.target.files);
      setFiles(newFiles);

      // Clear the input value to allow selecting the same file again
      event.target.value = "";
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleDeleteFile = (indexToDelete: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if(!comment || comment?.length == 0 ) {
      return ;
    }
    setIsLoading(true);

    const formData = new FormData();
    if (files) {
      Array.from(files).forEach((file) => {
        formData.append("file[]", file);
      });
    }

    const supportRequestDTO = {
      creatorId: CommonService.getUserId(),
      companyId: CommonService.getCompanyId(),
      siteId: 1,
      status: "OPEN",
      content: comment,
    };

    formData.append(
      "param",
      new Blob([JSON.stringify(supportRequestDTO)], {
        type: "application/json",
      })
    );

    try {
      const response = await Service.saveSupportRequest(formData);
      console.log("Response:", response.data);
      setIsLoading(false);
      CommonService.Toast.fire({
        title: "Ticket created successfully",
        icon: "success",
      });
      setFiles(null);
      setComment("");
      loadSupportRequests("OPEN"); // Reload requests after submission
      setActiveTab("open");
      setNewTicket(false);  
      getClosedRequestCount();
      getOpenRequestCount();
    } catch (error) {
      CommonService.Toast.fire({
        title: "Error submitting support request:",
        icon: "error",
      });
      console.error("Error submitting support request:", error);
      setIsLoading(false);
    }
  };

  const updateSupportStatus = async (id: any, status: any) => {
    try {
      const response = await Service.updateSupportRequestStatus(id, status);
      setSelectedRequest(null);
      loadSupportRequests(status);
      getOpenRequestCount();
      getClosedRequestCount();
      if(status == "OPEN") {
        CommonService.Toast.fire({
          title: `Ticket reopened successfully`,
          icon: "success",
        }); 
      } else {
        CommonService.Toast.fire({
          title: `Ticket closed successfully`,
          icon: "success",
        }); 
      }

    } catch (error) {
      console.error("Error fetching support requests:", error);
    }
  };

  const loadSupportRequests = async (status: string) => {
    try {
      setIsLoading(true);
      const response = await Service.getAllSupportRequests(
        (CommonService.isUser() ? CommonService.getCompanyId() : null),
        status
      );
      setSupportRequests(response.data);
      
      if (response.data.length > 0) 
        setSelectedRequest(response?.data[0])

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching support requests:", error);
    }
  };

  const getClosedRequestCount = async () => {
    try {
      const response = await Service.getCloseSupportRequestCount(
        CommonService.isUser() ? CommonService.getCompanyId() : null
      );
      setClosedRequestCount(response.data);
    } catch (error) {
      console.error("Error fetching closed request count:", error);
    }
  };

  const getOpenRequestCount = async () => {
    try {
      const response = await Service.getOpenSupportRequestCount(
        CommonService.isUser() ? CommonService.getCompanyId() : null
      );
      setOpenRequestCount(response.data);
    } catch (error) {
      console.error("Error fetching open request count:", error);
    }
  };

  const handleTabChange = (newValue: string) => {
    setActiveTab(newValue);
    setSelectedRequest(null);
    if (newValue !== "create") {
      setNewTicket(false);
      loadSupportRequests(newValue.toUpperCase());
    }
  };

const handleSupportRequestClick = (request:any) => {

      console.log(request?.supportRequestId);
      
      setNewTicket(false);
      setSelectedRequest(request);
  }

// Filter the list whenever `search` changes
const filteredItems = useMemo(
  () =>
    supportRequests.filter(item =>
      item.companyName.toLowerCase().includes(search.toLowerCase()) ||
      item.latestComment.toLowerCase().includes(search.toLowerCase()) ||
      item.name.toLowerCase().includes(search.toLowerCase())
    ),
  [supportRequests, search]
);

  return (
    <div className="group-chat-wrapper support-main-wrapper">
      {CommonService.isAdmin() && (<Typography
        variant="h1"
        gutterBottom
        sx={{
          px: 1,
          mb: 2,
          fontSize: "25px",
          fontWeight: 600,
          color: "#333333",
        }}
      >
        Support Requests
      </Typography>)}

      <div className="d-flex">
        <div className="group-chat-container row d-flex flex-grow-1 m-0">
          <div className="col-md-6 col-lg-5 col-xl-4 mb-3 mb-md-0 px-0 px-sm-2">
            <div className="group-list-wrapper" style={{
                    height:
                    isNewTicket ===
                      false
                        ? "805px"
                        : "initial",
                        minHeight:
                    CommonService.isAdmin()
                        ? "895px"
                        : "550px",
                  }}>
            <nav className="group-chat-nav-wrapper d-flex align-items-end justify-content-between w-100 position-sticky top-0">
              <div
                className="nav nav-tabs justify-content-between mt-3 w-100 px-2 px-sm-3"
                id="nav-tab"
                role="tablist"
              >
                <div className="d-flex">
                <button
                  onClick={() => handleTabChange("open")}
                  className={`nav-link ${activeTab === "open" ? "active" : ""}`}
                  id="nav-all-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-all"
                  type="button"
                  role="tab"
                  aria-controls="nav-all"
                  aria-selected={activeTab === "open"}
                >
                  {`Open (${openRequestCount})`}
                </button>
                <button
                  onClick={() => handleTabChange("close")}
                  className={`nav-link ${activeTab === "close" ? "active" : ""}`}
                  id="nav-warehouse-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-warehouse"
                  type="button"
                  role="tab"
                  aria-controls="nav-warehouse"
                  aria-selected={activeTab === "close"}
                >
                  {`Close (${closedRequestCount})`}
                </button>
                </div>
                {/* {CommonService.isUser() && (
                  <button
                    onClick={() => {
                      setSelectedRequest(null);
                      setNewTicket(true);
                    }}
                    className="nav-link"
                    id="nav-warehouse-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-warehouse"
                    type="button"
                    role="tab"
                    aria-controls="nav-warehouse"
                    aria-selected="false"
                  >
                    Create Ticket
                  </button>
                )} */}
                {CommonService.isUser() && (
                  <button
                    onClick={() => {
                      setSelectedRequest(null);
                      setNewTicket(true);
                      setComment("");
                      setFiles(null);
                    }}
                    className="create-ticket-btn mb-2"
                  title="Create Ticket"
                  >
                    <AddIcon/>
                    Create Ticket
                  </button>
                )}
              </div>
            
            {/* {CommonService.isUser() && (
                  <button
                    onClick={() => {
                      setSelectedRequest(null);
                      setNewTicket(true);
                    }}
                    // className="nav-link"
                    // id="nav-warehouse-tab"
                    // data-bs-toggle="tab"
                    // data-bs-target="#nav-warehouse"
                    // type="button"
                    // role="tab"
                    // aria-controls="nav-warehouse"
                    // aria-selected="false"
                    className="create-ticket-btn"
                  >
                    Create Ticket
                  </button>
                )} */}
                </nav>
              <div className="position-sticky ">
            <div className="position-relative mt-3 px-2 px-sm-3">
              <Form.Control
                type="text"
                placeholder="Search"
                className="chat-search mr-sm-2 pl"
                onChange={(e) => setSearch(e.target.value)}
              />
              <SearchIcon
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "25px",
                  transform: "translateY(-50%)",
                }}
              />
            </div>
            </div>
            <div className="text-muted">
              <hr />
            </div>

            <ListGroup className="px-2 px-sm-3">
              {filteredItems?.map((request, index) => (
                <ListGroup.Item
                  className="cursor-pointer"
                  // style={{
                  //   backgroundColor:
                  //     selectedRequest?.supportRequestId ===
                  //     request.supportRequestId
                  //       ? "white"
                  //       : "initial",
                  //       paddingInline:
                  //     selectedRequest?.supportRequestId ===
                  //     request.supportRequestId
                  //       ? "10px"
                  //       : "0",
                  // }}
                  onClick={() => {
                    setNewTicket(false);
                    setSelectedRequest(request);
                  }}
                >
                  <div className="chat-info-wrapper py-2 px-2" style={{
                    backgroundColor:
                      selectedRequest?.supportRequestId ===
                      request.supportRequestId
                        ? "white"
                        : "initial",
                  }}>
                    <div className="row m-0">
                      <div className="col-12 px-0">
                        <div className="d-flex justify-content-between">
                        <p className="request-name mb-0">{request?.name}</p>
                        <p className="request-ticketnumber mb-0">{`#${request?.requestNumber}`}</p>
                        </div>
                      </div>
                      {/* <div className="col-12 px-0">
                        <div className="chat__info d-flex d-sm-block flex-column">
                          <br className="d-none d-sm-block" />
                          <span className="chat__info--company me-2">
                            {request.companyName}
                          </span>
                          <span className="d-none d-sm-inline">|</span>
                          <span className="chat__info--reqType ms-sm-2">
                            SUPPORT REQUEST
                          </span>
                        </div>
                      </div> */}
                      <div className="col-12 px-0 mt-2">
                      <div className="row">
                        <div className="col-8">
                      <p className="msg-preview mb-0">
                        {request.latestComment}
                      </p>
                      </div>
                   <div className="col-4">
                        <div className="time__info d-flex flex-column text-end lh-base ms-auto">
                          {/* <span className="date">
                            {CommonService.formatDateTime(request.createdDate)}
                          </span> */}
                          <div className="date ">
                            <p className="mb-1">
                            {CommonService.formatDateForSupportRequest(request.createdDate)}
                            </p>
                            <p className="mb-0">{CommonService.formatTimeForSupportRequest(request.createdDate)}

                            </p>
                          </div>
                          {/* <span className="time">
                            {CommonService.formatTime(request.createdDate)}
                          </span> */}
                        </div>
                        </div>
                         </div>
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-between">
                      <p className="msg-preview mb-0">
                        {request.latestComment}
                        ...
                      </p>
                    </div> */}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>

            {filteredItems.length <= 0 && <NoTicketCreated openRequestCount={openRequestCount} closeRequestCount={closedRequestCount}/>}
          </div>
          </div>
          <div className="col-md-6 col-lg-7 col-xl-8 position-relative px-0 px-sm-2">
            <div className="message-list-wrapper">
            {selectedRequest && !isNewTicket && (
              <>
              <WebSocketProvider requestId={selectedRequest?.supportRequestId}  sellerCompanyId={null}>
                <div className="message-list-header d-flex align-items-center justify-content-between flex-wrap gap-2">
                    <div className="d-flex align-items-center">
                <Typography
                    variant="subtitle1"
                    sx={{
                      display: "inline",
                      fontWeight: 600,
                      fontSize: "18px",
                      color:"#333333",
                      lineHeight:"23.94px"
                    }}
                  >
                    Ticket 
                    #{selectedRequest?.requestNumber} 
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "inline",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight:"23.94px",
                      marginLeft:"10px"
                    }}
                  >
                    {selectedRequest.name} 
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "inline",
                      ml: 1,
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "#333333",
                      lineHeight:"21.28px",
                    }}
                  >
                   | {selectedRequest.companyName}
                  </Typography>
                  </div>
                  <div className="ms-auto">
                    {selectedRequest.status == "OPEN" ? (
                      <button className="close-btn d-flex align-items-center"
                      onClick={() => {
                        updateSupportStatus(
                          selectedRequest.supportRequestId,
                          "CLOSE"
                        );
                         setActiveTab('close');
                      }}
                      title="Close Ticket"
                      >
                       <CloseIcon/> Close
                      </button>
                    ) : (
                      <button className="reopen-btn d-flex align-items-center"
                      onClick={() => {
                        updateSupportStatus(
                          selectedRequest.supportRequestId,
                          "OPEN"
                        );
                         setActiveTab('open');
                      }}
                        title="Reopen Ticket">
                        <ReplayIcon/>
                        Re-open
                      </button>
                    )}
</div>
                  {/* Anderson Smith |  Max Inc */}
                </div>
                <div className="message-list-card">
                  <SupportCommentBox
                    supportRequestId={selectedRequest.supportRequestId}
                    status={selectedRequest.status}
                  />
                </div>
                </WebSocketProvider>
              </>
            )}
            { !selectedRequest && !isNewTicket && !isLoading &&
            (
              <div className="support-faq-wrapper">
                <p className="heading">FAQ</p>
              <FAQList/>
              </div>
            )}

            {isNewTicket && (
              <div className="create-ticket-wrapper">
                <p className="heading">
                Create New Ticket
                </p>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="mb-3 d-flex flex-column">
                  <label htmlFor="comment" className="block mb-2 font-medium create-ticket-label">
                    Enter your query
                  </label>
                  <textarea
                    id="comment"
                    placeholder="Type here"
                    value={comment}
                    rows={4}
                    onChange={handleCommentChange}
                    className="block w-full p-2 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div className="mb-3">
                <div className="create-ticket-attachement-wrapper d-flex flex-wrap align-items-center gap-2">
                  <IconButton
                      type="button"
                      aria-label="submit"
                      sx={{
                       transform: 'rotate(45deg)',
                       backgroundColor:'#F7F7F7',
                       padding:'12px'
                      }}
                      onClick={handleButtonClick}
                    >
                      <AttachFileIcon />
                    </IconButton>
                    {files?.map((file, index) => (
                      <div
                        className="file__upload--info d-inline-flex mb-1 me-2"
                        key={index}
                      >
                          <div className="file-name me-3">{file.name}</div>
                          <div className="file-delete">
                        <img
                          src={TrashIcon}
                          alt="TrashIcon"
                          onClick={() => handleDeleteFile(index)}
                        />
                      </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ position: "relative" }}>
                    {/* <IconButton
                      type="button"
                      aria-label="submit"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        right: "50px",
                        transform: "translateY(-50%)",
                        backgroundColor: "#FFFFFF",
                      }}
                      onClick={handleButtonClick}
                    >
                      <AttachFileIcon />
                    </IconButton> */}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      multiple
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <button disabled={isLoading} onClick={() => {
     loadSupportRequests('OPEN');
     setActiveTab('open');
     setNewTicket(false);
     setComment("");
  }} className="btn secondary-btn px-5">
                  Cancel
                </button>

                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn primary-btn px-5 ms-3"
                >
                  {isLoading && <Spinner />}
                  Send
                </button>
              </form>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportRequest;
