import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Service } from "../../Service/ApiService";
import { CommonService } from "../../Service/CommonService";
import PersonIcon from "@mui/icons-material/Person";
import Spinner from "../Common/Spinner";
import "../../sass/components/_validation.scss";

const ManagePermissions = () => {
  const { id } = useParams<{ id: string }>();
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [originalPermissions, setOriginalPermissions] = useState([]);
  const history = useHistory();
  const [roleName, setRoleName] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  // Add state for description
  const [description, setDescription] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const displayText = description || "No description available";

  const handleDescriptionClick = () => {
    if (!isEditing) {
      // setIsEditing(true);
    }
  };

  const handleDescriptionSave = () => {
    //remove the white spaces from the description
    const trimmedDescription = description.trim();
    setDescription(trimmedDescription);

    //check description is empty or not
    if (trimmedDescription.length == 0) {
      setDescriptionError("Please enter description");
    }
  };


  const fetchPermissions = async () => {
    try {
      const response = await Service.fetchAllPermissions(id);
      setPermissions(response.data.modules);
      setRoleName(response.data?.role);
      setDescription(response.data?.description);
      setOriginalPermissions(response.data);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  const handleCancel = () => {
    setPermissions(originalPermissions);
  };

  const hasPermissionsChanged = () => {
    return JSON.stringify(permissions) !== JSON.stringify(originalPermissions);
  };

  const handleToggle = (moduleIndex, operation) => {
    setPermissions((prevPermissions) => {
      const newPermissions = [...prevPermissions];
      const operationIndex = newPermissions[moduleIndex].operations.findIndex(
        (op) => op.operation === operation
      );

      if (operationIndex !== -1) {
        newPermissions[moduleIndex] = {
          ...newPermissions[moduleIndex],
          operations: [...newPermissions[moduleIndex].operations],
        };

        // If trying to uncheck CAN_VIEW but CAN_MANAGE is checked, don't allow it
        if (operation === "CAN_VIEW") {
          const canManageOp = newPermissions[moduleIndex].operations.find(
            (op) => op.operation === "CAN_MANAGE"
          );
          if (canManageOp?.granted) {
            return newPermissions;
          }
        }

        // If checking CAN_MANAGE, also check CAN_VIEW
        if (operation === "CAN_MANAGE") {
          const canViewIndex = newPermissions[moduleIndex].operations.findIndex(
            (op) => op.operation === "CAN_VIEW"
          );
          if (canViewIndex !== -1) {
            newPermissions[moduleIndex].operations[canViewIndex].granted = true;
          }
        }

        newPermissions[moduleIndex].operations[operationIndex].granted =
          !newPermissions[moduleIndex].operations[operationIndex].granted;
      }

      return newPermissions;
    });
  };

  const handleCheckAll = (operation) => {
    setPermissions((prevPermissions) => {
      const allChecked = areAllChecked(operation);

      return prevPermissions.map((module) => ({
        ...module,
        operations: module.operations.map((op) => {
          if (op.operation === operation) {
            // For CAN_VIEW, respect CAN_MANAGE status
            if (operation === "CAN_VIEW") {
              const canManageOp = module.operations.find(
                (mOp) => mOp.operation === "CAN_MANAGE"
              );
              // If unchecking all, don't uncheck if CAN_MANAGE is true
              if (allChecked && canManageOp?.granted) {
                return { ...op, granted: true };
              }
            }
            return { ...op, granted: !allChecked };
          }
          // If checking all CAN_MANAGE, also check all CAN_VIEW
          if (
            operation === "CAN_MANAGE" &&
            op.operation === "CAN_VIEW" &&
            !allChecked
          ) {
            return { ...op, granted: true };
          }
          return op;
        }),
      }));
    });
  };

  const handleDescriptionOnChange = (e:any) => {
    setDescriptionError("");
    setDescription(e.target.value);
  }

  const areAllChecked = (operation) => {
    return permissions.every(
      (module) =>
        module.operations.find((op) => op.operation === operation)?.granted
    );
  };

  // New function to determine if the "Can View" checkbox should be in an indeterminate state
  const isCanViewIndeterminate = () => {
    const someChecked = permissions.some(
      (module) =>
        module.operations.find((op) => op.operation === "CAN_VIEW")?.granted
    );
    return someChecked && !areAllChecked("CAN_VIEW");
  };

  const handleSave = async () => {
    // if (!hasPermissionsChanged()) return;

    // If description is not properly then don't call the api and show the error
    if(descriptionError || description.length == 0 ) {
      setDescriptionError("Please enter description");
      return;
    } else {
    setIsLoading(true);

    //create object for send role description and permission together
    const rolePermission  = {
      modules: permissions,
      description: description
    }

    try {
      await Service.saveAllPermissions(rolePermission, id);
      CommonService.Toast.fire({
        title: "Permissions updated successfully!",
        icon: "success",
      });
    } catch (error) {
      console.error("Error updating permissions:", error);
      CommonService.Toast.fire({
        title: "Failed to update permissions.",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
      fetchPermissions();
    }
  }
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div>
      <div className="usersList">
        <div className="usersList__header--wrapper d-flex flex-wrap align-items-center">
          <IconButton
            aria-label="ArrowBackIcon"
            onClick={handleBack}
            sx={{
              backgroundColor: "#FFF",
              mb: 1,
              "&:hover": { backgroundColor: "#FFF" },
            }}
          >
            <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
          </IconButton>

          <div className="header--title mb-2 me-2">
            <h1 className="title mb-0 ms-2">Manage Permissions</h1>
          </div>
        </div>
      </div>

      <div className="card-body p-4">
        {/* Add description section */}
        <div>
          <div className="d-flex justify-content-between align-items-center ps-4 pe-4">
            <button
              className={`white-outline-btn d-inline-flex justify-content-between align-items-center ms-1 pe-1 bg-white p-2`}
            >
              <span className="ms-2">
                {CommonService.formatRoleName(roleName)}
              </span>
              <span className="review--number d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2">
                <PersonIcon sx={{ color: "gray" }} />
              </span>
            </button>

            <div className="flex-grow-1 ms-5 validation">
              <h6 className="fw-bold">Role Description</h6>
              <textarea
                className={`form-control ${descriptionError? "is_invalid" : ""}`}
                value={description}
                onChange={handleDescriptionOnChange}
                onBlur={handleDescriptionSave}
                rows={3}
                style={{ resize: "none" }}
                autoFocus
                placeholder="Enter role description"
              />
              {descriptionError && (
                <p className="error-message">{descriptionError}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2">
        <div className="card-body p-4">
          <div className="table-responsive">
            <table className="table table-hover align-middle mb-0">
              <thead>
                <tr className="bg-light">
                  <th className="border-0">Module</th>
                  <th className="border-0 text-center">
                    <div className="form-check form-switch d-flex justify-content-center">
                      <input
                        className="form-check-input cursor-pointer"
                        type="checkbox"
                        role="switch"
                        checked={areAllChecked("CAN_MANAGE")}
                        onChange={() => handleCheckAll("CAN_MANAGE")}
                      />
                      <label className="ms-2">Can Manage</label>
                    </div>
                  </th>
                  <th className="border-0 text-center">
                    <div className="form-check form-switch d-flex justify-content-center">
                      <input
                        className="form-check-input cursor-pointer"
                        type="checkbox"
                        role="switch"
                        ref={(input) => {
                          if (input) {
                            input.indeterminate = isCanViewIndeterminate();
                          }
                        }}
                        checked={areAllChecked("CAN_VIEW")}
                        onChange={() => handleCheckAll("CAN_VIEW")}
                      />
                      <label className="ms-2">Can View</label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {permissions.map((module, moduleIndex) => (
                  <tr key={moduleIndex}>
                    <td>{CommonService.formatRoleName(module.module)}</td>
                    {["CAN_MANAGE", "CAN_VIEW"].map((operation) => (
                      <td key={operation} className="text-center">
                        <div className="form-check form-switch d-flex justify-content-center">
                          <input
                            className="form-check-input cursor-pointer"
                            type="checkbox"
                            role="switch"
                            id={`${module.module}-${operation}`}
                            checked={
                              module.operations.find(
                                (op) => op.operation === operation
                              )?.granted || false
                            }
                            onChange={() =>
                              handleToggle(moduleIndex, operation)
                            }
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="form__btn--group row d-flex mt-4">
            <div className="col-auto pe-1">
              <button
                type="reset"
                className="btn secondary-btn register-btn mb-2"
                disabled={isLoading}
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
            <div className="col-auto ps-1">
              <button
                type="submit"
                className="btn primary-btn register-btn mb-2"
                disabled={isLoading}
                onClick={handleSave}
              >
                {isLoading && <Spinner />}
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePermissions;
