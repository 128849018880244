import React, { useEffect, useState } from "react";
import './OrderSuccess.scss'
import SuccessBubbleIcon from '../../assets/images/icons/SuccessBubblesIcon.svg'
import { Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { useLocale } from "@adobe/react-spectrum";

interface LocationState {
    orderId?: string;
    orderNumber?: string;
}

const OrderSuccess: React.FC = () => {
    
    const location = useLocation<LocationState>();
    const orderId = location.state?.orderId || null
    const orderNumber = location.state?.orderNumber ||null

    const history = useHistory();



    //redirect to the order details page
    const redirectOrderDetailsPage = () => {
        history.push(`/user/view-as-buyer/order-detail/${orderId}`);
    }

    //redirect to the inventories page
    const redirectInventoryPage = () => {
        history.push(`/user/inventories`)
    }

    return (
        <>
            <div className="success-order-wrapper">
                <img src={SuccessBubbleIcon} alt="SuccessBubbleIcon" className="mx-auto mb-3" />
                <p className="text-center"><strong>Thank You for Ordering!</strong></p>
                <div className="order-deatil-card mt-3">
                    <Typography variant="h2" sx={{ mb: 3, color: '#25B634', fontWeight: 600, fontSize: '24px', lineHeight: 'normal', textAlign: 'center' }} gutterBottom>
                        Your order has been placed successfully
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#333333', fontWeight: 400, fontSize: '16px', lineHeight: 'normal', textAlign: 'center' }} gutterBottom>
                        Your order number is
                    </Typography>
                    <Typography variant="h3" sx={{ mb: 3, color: '#333333', fontWeight: 700, fontSize: '20px', lineHeight: 'normal', textAlign: 'center' }} gutterBottom>
                        {orderNumber!=null && (
                            `${orderNumber}`
                        )}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 3, color: '#333333', fontWeight: 400, fontSize: '15px', lineHeight: 'normal', textAlign: 'center' }} gutterBottom>
                        You will receive an email shortly with the details of your order. Please keep this order number for your records and reference it in any future correspondence regarding this order.
                    </Typography>
                    <Typography variant="body2" sx={{ mx: 'auto', color: '#333333', fontWeight: 400, fontSize: '15px', lineHeight: 'normal', textAlign: 'center' }} gutterBottom>
                        If you have any questions or need further assistance,
                        please contact our 
                        <Typography variant="overline" sx={{ ml: 1, cursor: 'pointer', textDecoration: 'underline', color: '#FF8B03', fontWeight: 400, fontSize: '15px', lineHeight: 'normal', letterSpacing: '0', textTransform: 'lowercase' }}>
                            customer support team.
                        </Typography>
                    </Typography>
                </div>
                <div className="d-flex justify-content-center mt-5">
                    <button className="btn primary-outline-btn mx-1" onClick={redirectOrderDetailsPage}>View Order</button>
                    <button className="btn primary-btn mx-1" onClick={redirectInventoryPage}>Continue Shopping</button>
                </div>
            </div>
        </>
    )

};

export default OrderSuccess;