import React, { createContext, useContext, useEffect, useState } from 'react';
import webSocketService from './websocketService';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children, requestId, sellerCompanyId }) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (requestId || sellerCompanyId) {
      webSocketService.connect(requestId, sellerCompanyId);
      setConnected(true);

      return () => {
        webSocketService.disconnect();
        setConnected(false);
      };
    }
  }, [requestId, sellerCompanyId]);

  return (
    <WebSocketContext.Provider value={{ connected, webSocketService }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);

