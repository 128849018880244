import React, { useEffect, useRef, useState } from "react";
import { Service } from "../../Service/ApiService";
import { CommonService } from "../../Service/CommonService";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CookieService } from "../../Service/CookieService";
import CloudArrowUp from "../../assets/images/icons/CloudArrowUp.svg";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import Tooltip from "@mui/material/Tooltip";
import { OrderApiService } from "../../Service/OrderApiService";
import { useParams } from "react-router";
import Spinner from "../Common/Spinner";
import { Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

type FormValue = {
  file: any;
};

export default function UploadDocs({
  fetchData,
  csvId,
  uploadCSV,
  makeDisable,
  requestType,
}: any) {
  useEffect(() => {
    console.log("inside upload csv");
  }, []);

  const userRole = CookieService.getCookie("userRole");
  const userId = CookieService.getCookie("userId");
  const companyId = CookieService.getCookie("companyId");
  const [show, setShow] = useState(false);
  const { id } = useParams<{ id: string }>();


  const handleClose = () => {
    setIsInvited(false);
    setValidated(false);
    setSwitchValue("");
    setThirdPartyStorage("");
    setFile(null);
    setTTBFile([]);
    fetchData();
    setShow(false);
    setSummaryError("");
    setErrorMessage("");
  };
  const handleShow = () => setShow(true);
  const [isInvited, setIsInvited] = useState(false);
  const [descriptionValue, setNote] = useState<string>("");
  const [switchValue, setSwitchValue] = useState("");
  const [thirdPartyStorage, setThirdPartyStorage] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const descriptionNote = useRef("");
  const [file, setFile] = useState<any | null>(null);
  // const [ttbFile, setTTBFile] = useState<File | null>(null);
  const [ttbFile, setTTBFile] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage,setErrorMessage] = useState<string>("");

  const onDropHandler = (ev: any) => {
    ev.preventDefault();

    if (uploadCSV) {
      let file: any = "";

      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        const fileItem = [...ev.dataTransfer.items].find(
          (item: any) => item.kind === "file"
        );
        if (fileItem) {
          file = fileItem.getAsFile();
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        file = ev.dataTransfer.files[0];
      }
      setFile(file);
    } else {
      let files: any = [];

      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        files = [...ev.dataTransfer.items].map((item: any) => item.getAsFile());
      } else {
        // Use DataTransfer interface to access the file(s)
        files = [...ev.dataTransfer.files];
      }
      setTTBFile(files);
    }
  };

  const onDragOver = (ev: any) => ev.preventDefault();

  /**
   * @param {any} event
   */
  function handleTTBFileUpload(event: any) {
    setErrorMessage("");
    const newFiles = Array.from(event.target.files); // Convert FileList to an array
    console.log(newFiles);
    setTTBFile((prevFiles: any) => [...prevFiles, ...newFiles]); // Append new files to existing array
  }

  const handleDeleteFile = (index: number) => {
    setTTBFile((currentFiles) => currentFiles.filter((_, i) => i !== index));
  };

  const sendInvite = async () => {
    try {
      console.log(file);
      if (file) {
        const formData = new FormData();

        if (userRole === "ADMIN " && (!switchValue || !thirdPartyStorage)) {
          console.log("validation fail");
          return;
        }
        let CSVfileUploadRequest = {
          id: csvId,
          description:
            "uploaded " + file.name + " file with " + descriptionNote.current,
          isRelocationRequired: switchValue === "yes" ? true : false,
          isThirdPartyStorage: thirdPartyStorage === "yes" ? true : false,
        };

        console.log(CSVfileUploadRequest);

        formData.append(
          "param",
          new Blob([JSON.stringify(CSVfileUploadRequest)], {
            type: "application/json",
          })
        );

        if (file) {
          formData.append("csvfile", file);
        }
        // Check if ttbFile is defined and is an array
        if (ttbFile && Array.isArray(ttbFile)) {
          ttbFile.forEach(function (file: any) {
            formData.append("ttbfile[]", file);
          });
        }

        //add the validation user select radio button or not

        const response = await Service.uploadAndSave(formData);
        return response;
      }
    } catch (error: any) {
      CommonService.Toast.fire({
        title: "Failed to upload CSV",
        icon: "error",
      });
      setIsInvited(false);
    }
  };
  const uploadFilesAndDocs = async () => {

    
    console.log("inside submit");
    setValidated(true);
    if(uploadCSV && !descriptionValue.trim()){
      setSummaryError("Please enter summary")
      return;
    }


   
    setIsLoading(true);
    let response;
    if (requestType === "SAMPLE_REQUEST") {
      
      if(ttbFile.length === 0) {
        setErrorMessage("Please upload file")
        setIsLoading(false);
        return ;
      }
      //Upload document of sample request
      response = uploadSampleRequestDocs();
    } else if (requestType === "ORDER_REQUEST") {
      if(ttbFile.length === 0) {
        setErrorMessage("Please upload file")
        setIsLoading(false);
        return ;
      }
      //Upload document of order request
      response = uploadOrderRequestDocs();
    } else if (requestType === "OFFER_REQUEST") {
      //Upload document of order request
      if(ttbFile.length === 0) {
        setErrorMessage("Please upload file")
        setIsLoading(false);
        return ;
      }
      response = uploadOfferRequestDocs();
    } else {
      const formData = new FormData();

      let CSVfileUploadRequest = {};
      if (uploadCSV) {
        response = await sendInvite();
      } else {
        console.log("else" , ttbFile)
        // Check if ttbFile is defined and is an array
        if (ttbFile.length > 0 && Array.isArray(ttbFile)) {
          ttbFile.forEach(function (file: any) {
            formData.append("ttbfile[]", file);
          });

          console.log(csvId);
          const fileNames = ttbFile.map((file) => file.name).join(", ");

          CSVfileUploadRequest = {
            id: csvId,
            description: "uploaded " + fileNames + " document",
          };
          formData.append(
            "param",
            new Blob([JSON.stringify(CSVfileUploadRequest)], {
              type: "application/json",
            })
          );
          response = await Service.uploadDocs(formData);
        }
      }
    }

    if (response && !errorMessage) {
      setIsLoading(false);
      setIsInvited(true);
      fetchData();
    }
    setIsLoading(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
    if(event.target.value.trim().length !== 0) {
      setSummaryError("");
    }
    descriptionNote.current = event.target.value;
    console.log(descriptionNote.current);
  };

  const uploadSampleRequestDocs = async () => {
    
    if(ttbFile.length == 0) {
        setErrorMessage("Please select file")
        return 0;
    }

    const formData = new FormData();
    let response;
    let SampleRequestDoc = {};

    if (ttbFile && Array.isArray(ttbFile)) {
      ttbFile.forEach(function (file: any) {
        formData.append("file", file);
      });
    }

    const fileNames = ttbFile.map((file) => file.name).join(", ");

    SampleRequestDoc = {
      sampleRequestId: id,
      description: "uploaded " + fileNames + " document",
      userId: userId,
      title: fileNames,
    };

    formData.append(
      "param",
      new Blob([JSON.stringify(SampleRequestDoc)], {
        type: "application/json",
      })
    );

    response = await OrderApiService.uploadSampleRequestDocs(formData);
  };

  const uploadOrderRequestDocs = async () => {
  
    if(ttbFile.length == 0) {
      console.log("come inside this order request doc file");
      setErrorMessage("Please select file")
      return 0;
    }

    const formData = new FormData();
    let response;
    let OrderRequestDoc = {};

    if (ttbFile && Array.isArray(ttbFile)) {
      ttbFile.forEach(function (file: any) {
        formData.append("file", file);
      });
    }

    const fileNames = ttbFile.map((file) => file.name).join(", ");

    OrderRequestDoc = {
      orderRequestId: id,
      description: "uploaded " + fileNames + " document",
      userId: userId,
      title: fileNames,
      companyId: companyId
    };

    formData.append(
      "param",
      new Blob([JSON.stringify(OrderRequestDoc)], {
        type: "application/json",
      })
    );

    response = await OrderApiService.uploadOrderRequestDocs(formData);
  };

  const uploadOfferRequestDocs = async () => {

    console.log("hello come inside it");
    if(ttbFile.length == 0) {
      setErrorMessage("Please select file")
      return 0;
    }

    const formData = new FormData();
    let response;
    let OfferRequestDoc = {};

    if (ttbFile && Array.isArray(ttbFile)) {
      ttbFile.forEach(function (file: any) {
        formData.append("file", file);
      });
    }

    const fileNames = ttbFile.map((file) => file.name).join(", ");

    OfferRequestDoc = {
      offerRequestId: id,
      description: "uploaded " + fileNames + " document",
      userId: userId,
      title: fileNames,
    };

    formData.append(
      "param",
      new Blob([JSON.stringify(OfferRequestDoc)], {
        type: "application/json",
      })
    );

    response = await OrderApiService.uploadOfferRequestDocs(formData);
  };

  return (
    <>
      {uploadCSV &&
        (makeDisable ? (
          <Tooltip title="Button enabled when sell request is 'Pending Review' or 'Rejected'.">
            <span style={{ cursor: "not-allowed" }}>
              <Button
                variant="none"
                className="primary-btn d-inline-flex"
                onClick={handleShow}
                disabled={makeDisable}
              >
                Upload Inv. File
                <FileUploadOutlinedIcon sx={{ ml: 1 }} />
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            variant="none"
            className="primary-btn d-inline-flex"
            onClick={handleShow}
            disabled={makeDisable}
          >
            Upload Inv. File
            <FileUploadOutlinedIcon sx={{ ml: 1 }} />
          </Button>
        ))}
      {!uploadCSV && (
        <Button
          variant="none"
          className="primary-btn d-inline-flex"
          onClick={handleShow}
        >
          Upload Document
          <FileUploadOutlinedIcon sx={{ ml: 1 }} />
        </Button>
      )}
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="modal__wd modal__inviteUser modal__wd--submitSellReq"
      >
        <Modal.Header
          className="flex-column align-items-start"
          style={{ marginBottom: "0px" }}
        >
          <Modal.Title>
            {uploadCSV && "Upload Inventory File"}
            {!uploadCSV && "Upload Documents"}
          </Modal.Title>
          {isInvited && (
            <p className="modal-text text-start">
              Your submission has been submitted successfully, You will receive
              a notification upon its completion.
            </p>
          )}
        </Modal.Header>
        {!isInvited && (
          <Modal.Body className="border-0">
            <Form
              className="form__inner row d-flex"
              onSubmit={uploadFilesAndDocs}
            >
              <Form.Group>
                {uploadCSV && (
                  <div className="file__upload--container">
                    <div
                      className={`text-center mb-3 ${
                        validated && !file
                          ? "file__upload--dropzone-danger"
                          : "file__upload--dropzone"
                      }`}
                      id="drop_zone"
                      onDrop={onDropHandler}
                      onDragOver={onDragOver}
                    >
                      <div>
                        {/* <FileUploadOutlinedIcon
                    color="disabled"
                    sx={{ fontSize: 52 }}
                  /> */}
                        <img src={CloudArrowUp} alt="CloudArrowUp" />
                        <br />
                        <span className="fw-bold">
                          Drag & Drop to Upload File
                        </span>
                      </div>
                      <div className="my-2 fw-bold">OR</div>
                      <label
                        htmlFor={"file_picker"}
                        className="primary-btn fp-label fw-bold"
                      >
                        Browse file
                      </label>
                      <Form.Control
                        required
                        type="file"
                        id="file_picker"
                        onChange={(ev: any) => {
                          setFile(ev.target.files[0]);
                        }}
                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        style={{ display: "none" }}
                      />

                      {/* <input
                        id="file_picker"
                        type="file"
                        onChange={(ev: any) => {
                          setFile(ev.target.files[0]);
                        }}
                        accept=".csv"
                        style={{ display: "none" }}
                      ></input> */}
                    </div>
                    <p className="error-message">
                      {validated && !file && (
                        <span>Please select a csv file</span>
                      )}
                    </p>
                    {file?.name && (
                      <>
                        <div className="file__upload--info d-flex align-items-center">
                          <div className="me-2">
                            <DescriptionOutlinedIcon />
                          </div>
                          <div
                            className="file-name me-2"
                            key={file?.name}
                            style={{ fontWeight: "bold" }}
                          >
                            {file?.name}
                          </div>
                          <div
                            className="ms-auto file-delete"
                            onClick={() => setFile(null)}
                          >
                            <HighlightOffRoundedIcon fontSize="small" />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {!uploadCSV && (
                  <div className="file__upload--container">
                    <div
                      className={`text-center mb-3 ${
                        validated && (ttbFile.length <= 0)
                          ? "file__upload--dropzone-danger"
                          : "file__upload--dropzone"
                      }`}
                      id="drop_zone"
                      onDrop={onDropHandler}
                      onDragOver={onDragOver}
                    >
                      <div>
                        <img src={CloudArrowUp} alt="CloudArrowUp" />
                        <br />
                        <span className="fw-bold">
                          Drag & Drop to Upload File
                        </span>
                      </div>
                      <div className="my-2 fw-bold">OR</div>
                      <label
                        htmlFor={"file_picker"}
                        className="primary-btn fp-label fw-bold"
                      >
                        Browse file
                      </label>
                      <Form.Control
                        required
                        type="file"
                        id="file_picker"
                        onChange={handleTTBFileUpload}
                        style={{ display: "none" }}
                        multiple
                      />
                    </div>

                    <p className="invalid-message">
                      { validated && (ttbFile.length <= 0)&& (
                        <span>Please select a file</span>
                      )}
                    </p>
                    {ttbFile?.map((item, index) => (
                      <>
                        {/* <LinearProgress variant="determinate" value={50} /> */}
                        <div
                          className="file__upload--info d-inline-flex align-items-center mt-1"
                          key={index}
                        >
                          {/* <div className="me-1">
                      <DescriptionOutlinedIcon fontSize="small" />
                    </div> */}
                          <div className="file-name me-2">{item.name}</div>
                          <div
                            className="ms-auto file-delete"
                            onClick={() => handleDeleteFile(index)}
                          >
                            <HighlightOffRoundedIcon fontSize="small" />
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
                {uploadCSV && (
                  <div className="d-block file__upload--container-ttb mt-3 form__inner">
                    <strong>Add Summary</strong>
                    <Form.Control
                      as="textarea"
                      aria-label="With textarea"
                      className={`form-control mt-2 ${summaryError ? "is_invalid" : ""}`}
                      value={descriptionValue}
                      onChange={handleInputChange}
                      rows={5}
                      style={{resize:"none"}}
                      placeholder="Add Summary..."
                    />
                  {summaryError && <div className="error-message">{summaryError}</div>}

                  </div>
                )}
              </Form.Group>
            </Form>
          </Modal.Body>
        )}
        {!isInvited && (
          <Modal.Footer className="justify-content-center align-items-center">
            <button
              className="secondary-btn px-5"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="primary-btn px-5"
              onClick={uploadFilesAndDocs}
              disabled={isLoading}
            >
              {isLoading && <Spinner />} Upload
            </button>
          </Modal.Footer>
        )}
        {isInvited && (
          <Modal.Footer className="justify-content-center bg-transparent">
            <div className="btn-group row d-flex justify-content-center w-100">
              <div className="col-12">
                <button className="secondary-btn w-100" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
