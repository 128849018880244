import {
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { Service } from "../../Service/ApiService";
import { useEffect, useRef, useState } from "react";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
} from "@mui/icons-material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/InventoryCSV.scss";
import SearchIcon from "../../assets/images/icons/search.svg";
import React from "react";
import { InventoryApiService } from "../../Service/InventoryApiService";
import { CookieService } from "../../Service/CookieService";
import { Link, useHistory, useParams } from "react-router-dom";
import UploadCSV from "../popups/UploadCSV";
import SingleApproveCSV from "../popups/SingleApproveCSV";
import SingleRejectCSV from "../popups/SingleRejectCSV";
import SinglePublishCSV from "../popups/SinglePublishCSV";
import { Button } from "react-bootstrap";
import ProductDetail from "./ProductDetail";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Swal from "sweetalert2";
import StatusDropdown from "../Common/StatusDropdown";
import SearchBar from "../Static/SearchBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadCSVForUser from "../popups/UploadCSVForUser";


const InventoryCSV = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };






  const [isLoading, setIsLoading] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const [handleShow, setHandleShow] = useState(false);
  const statusDetailsRef = useRef({ id: "", status: "", fileName: "", feeType: "", platformFee: "" });
  const [status, setStatus] = useState<any>();

  const handleStatusChange = async (status: string, id: string, fileName: string, feeType: any, platformFee:any) => {
    console.log(status);

    if (status != "Accepted" && status != "Rejected" && status != "Published") {
      const orderStatus = status?.replace(/ /g, "_");

      //create the object to send
      const sd = {
        id: id,
        status: orderStatus?.toUpperCase(),
        fileName: fileName,
        feeType: feeType,
        platformFee: platformFee,

      };

      console.log("this is calling and this is the data " + sd.fileName);
      statusDetailsRef.current = sd;
      InventoryApiService.updateInventoryCSV(statusDetailsRef.current).then(
        (response) => {
          CommonService.Toast.fire({
            title: "Sell request status updated successfully",
            icon: "success",
          });
          fetchData();
        }
      );
    } else {
      setHandleShow(true);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setHandleShow(false);
      const response: any = await InventoryApiService.getInventoryCSV(CommonService.isAdmin()? id : CommonService.getCompanyId());
      console.log(response.data);
      setTableData(response.data);
      setIsLoading(false);
    } catch (error) { 
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    console.log("Visibility button clicked for row:", params.row);
    event.stopPropagation(); // Prevent event from bubbling up to the row
    CommonService.Toast.fire({
      title: "View icon clicked",
    });
  };

  const columns: GridColDef[] = [];

  if (CommonService.isAdmin()) {
    columns.push({
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.ecommUser.firstName || ""} ${row.ecommUser.lastName || ""}`,
      headerClassName: "table-header",
    });

    columns.push({
      field: "ecommUser.email",
      headerName: "Email",
      headerClassName: "table-header",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.ecommUser.email || ""} `,
    });

    columns.push({
      field: "companyName",
      headerName: "Company Name",
      headerClassName: "table-header",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.companyName || ""} `,
    });
  }

  columns.push({
    field: "fileName",
    headerName: "Inventory File",
    headerClassName: "table-header",
    flex: 1,
    renderCell: (params) => {
      const csvFileName = params.value;
      if (csvFileName) {
        const parts = csvFileName.split("_");
        const fileNameToShow = parts.slice(1).join("_");
        return fileNameToShow;
      } else {
        return "";
      }
    },
  });

  columns.push({
    field: "requestNumber",
    headerName: "Request Number",
    flex: 1,
    valueGetter: (value: any, row: any) =>
      `${row.requestNumber || "-"}`,
    headerClassName: "table-header",
  });

  columns.push({
    field: "sellRequestName",
    headerName: "Name of Sell Request",
    flex: 1,
    valueGetter: (value: any, row: any) =>
      `${row.sellRequestName || "-"}`,
    headerClassName: "table-header",
  });
  
  columns.push({
    field: "createdDate",
    headerName: "Submitted On",
    flex: 1,
    valueGetter: (value: any, row: any) => {
      const dateTimeString = row.createdDate ? row.createdDate : null;
      if (dateTimeString) {
        //call the method which formate the time
        return CommonService.formatDateTime(dateTimeString);
      }
    },
    headerClassName: "table-header",
  });

  columns.push({
    cellClassName: "purple-cell",
    field: "platformFee",
    headerName: "Fee(per Barrel)",
    headerClassName: "table-header",
    flex: 1,
    valueGetter: (value: any, row: any) => {
      if (row.feeType != null && row.platformFee != null) {
        if (row.feeType == "PERCENTAGE") {
          return row.platformFee + "%" + " / " + "Barrel";
        } else return "$" + row.platformFee + " / " + "Barrel";
      } else {
        return "";
      }
    },
  });

  columns.push({
    field: "status",
    headerName: "Status",
    headerClassName: "table-header",
    cellClassName: "status-badge",
    flex: 1,
    renderCell: (params: any) => (
      <div>
        
          {/* <Button
            style={{
              background:
                params.row.status === "APPROVED"
                  ? "green" // Green for 'approved'
                  : params.row.status === "REJECTED"
                  ? "red" // Green for 'approved'
                  : params.row.status === "PENDING_REVIEW"
                  ? "#e8eaeb" // Gray for 'submitted'
                  : params.row.status === "FINALIZED"
                  ? "#50b1ba"
                  : params.row.status === "PUBLISHED"
                  ? "#50b1ba"
                  : "",
              color:
                params.row.status === "PENDING_REVIEW" ? "#000000DE" : "white", // Gray for 'submitted'
              border: "1px transparent",
              padding: "revert", // Default color if none of the conditions are met
            }}
            size="sm"
          >
            {" "}
            {toTitleCase(params.row.status)}
          </Button> */}
          {/* <StatusDropdown
            currentStatus={params.row.status}
            disableCondition = {CommonService.isAdmin() || CommonService.isUser()}
            onStatusChange={(newStatus)=>{
              
            }}
            allowedLabels={[]} */}

<div onClick={(e) => e.stopPropagation()}>

          {(params.row?.status === "PENDING_REVIEW" || params.row?.status === "UNDER_REVIEW") && (
                <>
                  {CommonService.isUser() && params.row?.status === "PENDING_REVIEW" && (<StatusDropdown
                    currentStatus={params.row?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus,params.row?.id, params.row?.fileName, params.row?.feeType, params.row?.platformFee);
                    }}
                    disableCondition={false}
                    allowedLabels={['Cancelled']}
                  />)}

                {CommonService.isUser() && params.row?.status === "UNDER_REVIEW" && (<StatusDropdown
                    currentStatus={params.row?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus,params.row?.id, params.row?.fileName, params.row?.feeType, params.row?.platformFee);
                    }}
                    disableCondition={false}
                    allowedLabels={[]}
                  />)}  

                  {CommonService.isAdmin() && (
                    <>
                      <StatusDropdown
                        currentStatus={params.row?.status}
                        onStatusChange={(newStatus) => {
                          setStatus(newStatus);
                          console.log(newStatus);
                          handleStatusChange(newStatus,params.row?.id, params.row?.fileName, params.row?.feeType, params.row?.platformFee);
                        }}
                        disableCondition={false}
                        allowedLabels={['Accepted', 'Rejected', 'Under Review']}
                      />

                      {status == "Accepted" && handleShow && (
                        <SingleApproveCSV
                                  fetchData={fetchData}
                                  inventoryCsv={params.row}
                                  isFromDropdown={true}
                                  popupShow = {handleShow}
                                  
                                />
                      )}

                      {status == "Rejected" && handleShow && (
                         <SingleRejectCSV
                                   fetchData={fetchData}
                                   rejectUser={params.row}
                                   isFromDropdown={true}
                                   popupShow = {handleShow}
                                 />
                      )}
                    </>
                  )}
                </>
              )}

              {params.row?.status === "ACCEPTED" && (
                <>

                  <StatusDropdown
                    currentStatus={params.row?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus,params.row?.id, params.row?.fileName, params.row?.feeType, params.row?.platformFee);
                    }}
                    disableCondition={CommonService.isAdmin()}
                    allowedLabels={["Finalized"]}
                  />

                  {status === "Finalized" && handleShow && (
                   <SinglePublishCSV
                   fetchData={fetchData}
                   inventoryCsv={params.row}
                   isFromDropdown={true}
                   popupShow = {true}
           />
                  )}
                </>
              )}

              {params.row?.status === "FINALIZED" && (
                <>
                  <StatusDropdown
                    currentStatus={params.row?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus,params.row?.id, params.row?.fileName, params.row?.feeType, params.row?.platformFee);
                    }}
                    disableCondition={CommonService.isUser()}
                    allowedLabels={["Published"]}
                  />
                  {status === "Published" && handleShow && (
                      <SinglePublishCSV
                                fetchData={fetchData}
                                inventoryCsv={params.row}
                                isFromDropdown={true}
                                popupShow = {true}
                        />
                  )}
                </>
              )}

              {(params.row?.status === "CANCELLED" ||
                params.row?.status === "REJECTED" ||
                params.row?.status === "PUBLISHED") && (
                  <StatusDropdown
                    currentStatus={params.row?.status}
                    onStatusChange={() => { }}
                    disableCondition={true}
                    allowedLabels={[]}
                  />
                )}

          

       
        {params.row.status === "REJECTED" && (
          <Tooltip title={`Rejection Note: ${params.row.rejectionNote}`} arrow>
            <StickyNote2Icon
              className="note-icon cursor-pointer"
              style={{ color: "gray" }}
            />
          </Tooltip>
        )}
        </div>
      </div>
    ),
  });



  
 
    
  


 

  // columns.push({
  //   field: "action",
  //   headerName: "Action",
  //   sortable: false,
  //   width: 200,
  //   headerClassName: "table-header",


  //   renderCell: (params: any) => (
  //     <div onClick={(e) => e.stopPropagation()}>
  //       {params.row.status === "APPROVED" && CommonService.isUser() && (
  //         <SinglePublishCSV
  //           fetchData={fetchData}
  //           inventoryCsv={params.row}
  //           isFromDropdown={false}
  //           popupShow = {false}
  //         />
  //       )}
  //       {params.row.status === "PENDING_REVIEW" && CommonService.isAdmin() && (
  //         <SingleApproveCSV
  //           fetchData={fetchData}
  //           inventoryCsv={params.row}
  //           isFromDropdown={false}
  //           popupShow = {false}
            
  //         />
  //       )}
  //       {params.row.status === "PENDING_REVIEW" && CommonService.isAdmin() && (
  //         <SingleRejectCSV
  //           fetchData={fetchData}
  //           rejectUser={params.row}
  //           isFromDropdown={false}
  //           popupShow = {false}
  //         />
  //       )}
  //       {params.row.status === "FINALIZED" && CommonService.isAdmin() && (
  //         <SinglePublishCSV
  //           fetchData={fetchData}
  //           inventoryCsv={params.row}
  //           isFromDropdown={false}
  //           popupShow = {false}
  //         />
  //       )}
  //     </div>
  //   ),
  // });

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    console.log(newSelection.length);
  };

  // Filter rows based on search query
  const filteredRows = tableData.filter(
    (row) =>
      CommonService.isAdmin() ?
        row.ecommUser.email?.toLowerCase().includes(search?.toLowerCase()) ||
        row.ecommUser.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.ecommUser.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.ecommUser.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.fileName?.toLowerCase().includes(search?.toLowerCase()) ||
        (row.ecommUser.firstName + " " + row.ecommUser.lastName).toLowerCase().includes(search?.toLowerCase())
      :
        row.fileName?.toLowerCase().includes(search?.toLowerCase())
  );

  // Map filtered rows with unique IDs
  const tableDataWithIds = filteredRows.map((rowData, index) => ({
    ...rowData
  }));

  const handleRowClick = (params) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
    history.push(`${linkPrefix}/view-sell-request/${params.row.id}`);
  }

  const handleBack = () => {
    history.goBack();
  }

  return (
    <div className="usersList">
      <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
      {CommonService.isAdmin() && (
            <IconButton
              onClick={handleBack}
              aria-label="ArrowBackIcon"
              sx={{
                backgroundColor: "#FFF",
                mb: 1,
                "&:hover": { backgroundColor: "#FFF" },
              }}
            >
              <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
            </IconButton>
      )}

      {CommonService.isAdmin() && (
            <div className="header--title mb-2 me-2">
              <h1 className="title mb-0 ms-2">
                {CommonService.isAdmin() && "Inventory Sell Requests"}
                <i className="bi bi-heart-fill"></i>
              </h1>
            </div>
      )}
        <div className="header--menu ms-auto mb-2">
          <div className="header--menu-items row d-flex justify-content-end">
            <div className="col-auto mb-2">
              <SearchBar search={search} setSearch={setSearch} />
            </div>

            <div className="col-auto menu-items__btnGroup btnGroup-border d-inline-flex mb-2">
              {CommonService.isAdmin() && <UploadCSV fetchData={fetchData} />}

            {CommonService.isUser() &&  <UploadCSVForUser fetchData={fetchData} inventoryCount={tableData.length} />}
             {/* <button className={`white-outline-btn d-inline-flex justify-content-between align-items-center ms-1 pe-1`} >
                          Need Review
                          <span className="review--number d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2">{reviewUserCount}</span>
                      </button> */}
            </div>
           

            <div className="col-auto menu-items__btnGroup d-inline-flex mb-2">
              {/* {isNeedReviewClicked && <button className="white-outline-btn me-1" >
                                Approve <i className="bi bi-check"></i>
                            </button>}
                            {isNeedReviewClicked && <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                                Reject
                            </button>} */}
              <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                Delete
              </button>
              <span className="counting d-inline-flex justify-content-between align-items-center ms-2">
                {selectionModel.length}
              </span>
            </div>
            {/* <div className={`flex w-[23%] justify-center rounded-[20px] border border-solid border-gray-50 md:w-full ${isButtonClicked ? 'bg-white' : ''}`}>
        <div onClick={handleButtonClick}

            className="my-[3px] flex items-center gap-[13px] cursor-pointer"
        >
            <div className={`flex ${isButtonClicked ? 'bg-white' : ''}`}>
                <p>
                    Need Review
                </p>
            </div>
            <h2 className={`flex h-[32px] w-[32px] items-center justify-center rounded-[16px] text-center  ${isButtonClicked ? '' : 'bg-white'}`}
            >
                {reviewUserCount}
            </h2>
        </div>
    </div>
    <img src="../images/img_vert_line.svg" alt="vertline_three" className="h-[35px] md:w-full" />
    <div className="flex gap-2.5">
        <button className="min-w-[129px] gap-2 !text-gray-500">
            Approve
        </button>
        <button
            className="min-w-[111px] gap-2 !text-gray-500"
        >
            Reject
        </button>
        <h3
            className="flex h-[40px] items-center justify-center rounded-[20px] border border-solid border-white-A700 text-center !text-gray-500"
        >
            0
        </h3>
    </div> */}
          </div>

        </div>
      </div>
      <div className="user-table-container">
        <div className="userTable">
          <DataGrid
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            disableRowSelectionOnClick={true}
            localeText={localeText}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            onRowClick={handleRowClick}
          />
        </div>
      </div>
    </div>
  );
};

export default InventoryCSV;