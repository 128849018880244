import React, { useState } from 'react';
import { Typography, TextField, Button, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import initialFaqData from '../../assets/F&Q/FAQ.json';
import Accordion from 'react-bootstrap/Accordion';

interface FAQItem {
  id: any;
  question: string;
  answer: string;
}

const FAQList: React.FC = () => {
  const [faqData, setFaqData] = useState<FAQItem[]>(initialFaqData.FAQData);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');
  const [visibleAnswers, setVisibleAnswers] = useState<{ [key: number]: boolean }>({});

  const toggleAnswerVisibility = (id: number) => {
    setVisibleAnswers(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const addNewQuestion = () => {
    if (newQuestion && newAnswer) {
      const newItem: FAQItem = {
        id: faqData.length + 1,
        question: newQuestion,
        answer: newAnswer
      };
      setFaqData([...faqData, newItem]);
      setNewQuestion('');
      setNewAnswer('');
    }
  };

  return (
    <div>
      {/* <Typography variant="h4" gutterBottom>FAQ</Typography>
      
      {faqData.map((item) => (
        <Box key={item.id} sx={{ marginBottom: '20px' }}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" sx={{ flexGrow: 1 }}>{item.question}</Typography>
            <IconButton onClick={() => toggleAnswerVisibility(item.id)}>
              {visibleAnswers[item.id] ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </Box>
          {visibleAnswers[item.id] && (
            <Typography>{item.answer}</Typography>
          )}
        </Box>
      ))} */}
      <div className='upload_csv--user__faq'>
        <Accordion>
          {faqData.map((item, index) => (
            <Accordion.Item eventKey={item.id.toString()} key={item.id}>
              <Accordion.Header>{item.question}</Accordion.Header>
              <Accordion.Body>
                {item.answer}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQList;