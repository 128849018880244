import { ListFormat } from 'typescript';
import AxiosApiService from './AxiosService';
import { Url } from '../Constants/Url';

export const MessageApiService = {
    getActiveMessagesByRequestType,saveMessage,getGroupsMessage, getAdminMessageGroups
}

async function getActiveMessagesByRequestType(params: { requestId: string; requestType: string,isSeller: boolean, sellerId:String; }) {
    return await AxiosApiService.getWithParams<Object>(Url.GET_ACTIVE_MESSAGES_BY_TYPE, {
        params: {
            requestId: params.requestId,
            requestType: params.requestType,
            isSeller: params.isSeller,
            sellerId: params.sellerId,
        }
    });
}

async function saveMessage(message: any) {
    return await AxiosApiService.post<any>(Url.SAVE_MESSAGES, message);
}

async function getGroupsMessage(tab: any) {
    return await AxiosApiService.get<any>(Url.GET_GROUP_MESSAGES + '/' + tab);
}

async function deleteOrderItem(orderId: any) {
    return await AxiosApiService.delete<any>(Url.DELETE_ITEM + '/' + orderId);
}

async function saveSampleRequest(sampleOrder: any) {
    return await AxiosApiService.post<any>(Url.SAMPLE_ORDER_REQUEST, sampleOrder);
}

async function isSampleRequestCreated(sampleOrder: any) {
    return await AxiosApiService.post<any>(Url.IS_SAMPLE_ORDER_REQUEST_CREATED, sampleOrder);
}

async function updateSampleRequest(sampleOrder: any) {
    return await AxiosApiService.post<any>(Url.UPDATE_SAMPLE_ORDER_REQUEST, sampleOrder);
}

async function savePurchaseOffer(purchaseOfferDetails: any) {
    return await AxiosApiService.post<any>(Url.SAVE_PURCHASE_OFFER, purchaseOfferDetails);
}

async function isPurchaseOfferCreated(purchaseOfferDetails: any) {
    return await AxiosApiService.post<any>(Url.IS_PURCHASE_OFFER_REQUEST_CREATED, purchaseOfferDetails);
}

async function updatePurchaseOfferRequest(purchaseOfferDetails: any) {
    return await AxiosApiService.post<any>(Url.UPDATE_PURCHASE_OFFER_REQUEST, purchaseOfferDetails);
}

async function updateOrderStatus(order: any) {
    return await AxiosApiService.put<any>(Url.PLACE_ORDER, order);
}

async function getActiveOrders() {
    return await AxiosApiService.get<any>(Url.GET_ACTIVE_ORDERS);
}

async function getActiveSampleRequests() {
    return await AxiosApiService.get<any>(Url.GET_SAMPLE_REQUEST);
}

async function getActiveOfferRequests() {
    return await AxiosApiService.get<any>(Url.GET_OFFER_REQUEST);
}

async function addToWishlist(wishListRequest: any) {
    return await AxiosApiService.post<any>(Url.ADD_TO_WISH_LIST, wishListRequest);
}

async function removeFromWishlist(wishListRequest: any) {
    return await AxiosApiService.post<any>(Url.REMOVE_FROM_WISH_LIST, wishListRequest);
}

async function getUserWishlist(userId: any) {
    return await AxiosApiService.get<any>(Url.GET_WISH_LIST + '/' + userId);
}

async function isInventoryInWishlist(inventoryId: any) {
    return await AxiosApiService.get<boolean>(Url.IS_INVENTORY_IN_WISH_LIST + '/' + inventoryId);
}

async function getOrders(forReview: boolean) {
    return await AxiosApiService.get<any>(Url.GET_ORDERS + '?forReview=' + forReview);
}

async function getPendingOrders() {
    return await AxiosApiService.get<any>(Url.GET_PENDING_ORDERS);
}

async function getPendingOrdersCount() {
    return await AxiosApiService.get<any>(Url.GET_PENDING_ORDERS_COUNT);
}

async function updateOrderStatusByOrderId(statusDetails: any) {
    return await AxiosApiService.post<any>(Url.UPDATE_ORDER_STATUS_BY_ORDER_ID, statusDetails)
}

async function changeOrderStatus(statusDetails: any) {
    return await AxiosApiService.put<any>(Url.CHANGE_ORDER_STATUS, statusDetails)
}

async function changeOfferStatus(statusDetails: any) {
    return await AxiosApiService.put<any>(Url.CHANGE_OFFER_STATUS, statusDetails)
}

async function changeSampleRequestStatus(statusDetails: any) {
    return await AxiosApiService.put<any>(Url.CHANGE_SAMPLE_REQUEST_STATUS, statusDetails)
}

async function getItemsByOrderId(orderId: any) {
    return await AxiosApiService.get<any>(Url.GET_ORDER_ITEMS_BY_ORDER_ID + '/' + orderId);
}

async function getAdminMessageGroups(requestId: string) {
    return await AxiosApiService.getWithParams<Object>(Url.GET_ADMIN_MESSAGE_GROUP, {
        params: {
            requestId: requestId
        }
    });
}