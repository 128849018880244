import React, { useEffect, useState } from 'react';
import { Service } from '../../Service/ApiService';
import "../Dynamic/Dashboard.scss";
import CompanyIcn from '../../assets/images/icons/CompanyNew.svg';
import InventoryIcn from '../../assets/images/icons/InventoryNew.svg';
import UsersIcn from '../../assets/images/icons/UsersNew.svg';
import BarrelIcn from '../../assets/images/icons/Barrels.svg';
import ReservedBarrelIcn from '../../assets/images/icons/Reserved_BarrelsNew.svg';
import OrderReqIcn from '../../assets/images/icons/Order_Request_New.svg';
import OrderValueIcn from '../../assets/images/icons/Order_Value_New.svg';
import OfferReqIcn from '../../assets/images/icons/Offer_Request_New.svg';
import SampleReqIcn from '../../assets/images/icons/Sample_Request_New.svg';
import SellReqIcn from '../../assets/images/icons/Sell_Request_New.svg';
import InvChangeReqIcn from '../../assets/images/icons/Inventory_Change_Request.svg';
import HandPointerIcon from '../../assets/images/user_hand_pointer.svg';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Cookies from 'universal-cookie';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router";
import CurrencyFormatter from '../Static/CurrencyFormatter';
import RecentRequest from './RecentRequest';
import UnreadMessage from './UnreadMessage'
import { CommonService } from '../../Service/CommonService';
import { useRoute } from '../Common/route';
import { Typography } from '@mui/material';

const Dashboard: React.FC = () => {
    const [openRequestCount, setOpenRequestCount] = useState<any>({});
    const route = useRoute();
    const userName = new Cookies().get('userName');
    const [reviewUserCount, setReviewCount] = useState(0);

    const fetchOpenRequestCount = async () => {

        const response: any = await Service.fetchOpenRequestCount();
        console.log("this is your response of data " + response.data)
        setOpenRequestCount(response.data);

        const reviewUserCount: any = await  Service.getSubmittedUsersCount(null);
        console.log("this is your review user count" + reviewUserCount.data)
        setReviewCount(reviewUserCount.data);
    }

    const handleRoute = (routePath: string) => {
        if (CommonService.isAdmin()) {
            route(`/master/${routePath}`);
        } else {
            route(`/user/${routePath}`);
        }
    };

    useEffect(() => {
        fetchOpenRequestCount();
        localStorage.setItem("dashboardPrefrence", 'Owner');
    }, [])

    return (
        <div className='dashboard'>
            <div className="container-fluid pt-1 pb-3">
                <div className="dashboard__user">
                    <h4 className='dashboard__user--title my-3 px-2'>Welcome! {userName}</h4>
                </div>
                <div className="dashboard__inner custom-dashboard-inner">
                    <div className="row">
                        <div className="col-xl-7 col-xxl-8">
                            <div className="dashboard__data mb-5 mb-xl-0">
                                {CommonService.isAdmin() && <div className="dashboard__data--card cursor-pointer" onClick={() => handleRoute("company-list")}>
                                    <img src={CompanyIcn} alt="CompanyIcn" width={65} height={61} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count">{openRequestCount['companys']}</span>
                                    <span className="details">Companies</span>
                                    </div>
                                </div>}
                                <div className="dashboard__data--card">
                                    <img src={InventoryIcn} alt="CompanyIcn" width={68} height={60} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count">{openRequestCount['inventories']}</span>
                                    <span className="details">Total Inventories</span>
                                    </div>
                                </div>
                                <div className="dashboard__data--card cursor-pointer" onClick={() => handleRoute("user-list")}>
                                    <img src={UsersIcn} alt="CompanyIcn" width={67} height={65} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count">{openRequestCount['users']}</span>
                                    <span className="details">Users</span>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <span className='hand-pointer-wrapper'>
                                        <img src={HandPointerIcon} alt="HandPointer" /> 
                                        <span className='pointer-count'>{reviewUserCount}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="dashboard__data--card">
                                    <img src={BarrelIcn} alt="CompanyIcn" width={51} height={52} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count">{openRequestCount['avilableBarrels'] ? CommonService.numberFormatter(openRequestCount['avilableBarrels']) : 0}</span>
                                    <span className="details">Total Available Barrels</span>
                                    </div>
                                </div>
                                <div className="dashboard__data--card">
                                    <img src={ReservedBarrelIcn} alt="CompanyIcn" width={51} height={52} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count">{openRequestCount['reservedBarrels'] ? openRequestCount['reservedBarrels'] : 0}</span>
                                    <span className="details">Total Reserved Barrels</span>
                                    </div>
                                </div>
                                <div className="dashboard__data--card cursor-pointer" onClick={() => handleRoute("active-order")}>
                                    <img src={OrderReqIcn} alt="CompanyIcn" width={70} height={65} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count">{openRequestCount['orderRequestCount']}</span>
                                    <span className="details">Order Requests</span>
                                    </div>
                                </div>
                                <div className="dashboard__data--card">
                                    <img src={OrderValueIcn} alt="CompanyIcn" width={61} height={60} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count"><CurrencyFormatter amount={openRequestCount['pendingOrderValue'] ? openRequestCount['pendingOrderValue'] : 0} locale="en-US" currency="USD" /></span>
                                    <span className="details">Pending Order Value</span>
                                    </div>
                                </div>
                                <div className="dashboard__data--card cursor-pointer" onClick={() => handleRoute("offer-request")}>
                                    <img src={OfferReqIcn} alt="CompanyIcn" width={61} height={60} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count">{openRequestCount['offerRequestCount']}</span>
                                    <span className="details">Offer Requests</span>
                                    </div>
                                </div>
                                <div className="dashboard__data--card cursor-pointer" onClick={() => handleRoute("sample-request")}>
                                    <img src={SampleReqIcn} alt="CompanyIcn" width={61} height={60} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count">{openRequestCount['sampleRequestCount']}</span>
                                    <span className="details">Sample Requests</span>
                                    </div>
                                </div>
                                <div className="dashboard__data--card cursor-pointer" onClick={() => handleRoute("inventory-sell-requests")}>
                                    <img src={SellReqIcn} alt="CompanyIcn" width={65} height={60} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count">{openRequestCount['sellRequestCount']}</span>
                                    <span className="details">Sell Requests</span>
                                    </div>
                                </div>
                                {/* <div className="dashboard__data--card cursor-pointer" onClick={() => handleRoute("inventory-change-requests")}>
                                    <img src={InvChangeReqIcn} alt="InvChangeReqIcn" width={65} height={60} />
                                    <span className='explore d-inline-flex align-items-center'>
                                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 600, marginRight: '-4px' }}>
                                            More
                                        </Typography>
                                        <ChevronRightRoundedIcon />
                                    </span>
                                    <div className='d-flex flex-column'>
                                    <span className="count">{openRequestCount['inventoryChangeRequestCount']}</span>
                                    <span className="details">Open Inventory Change Requests</span>
                                    </div>
                                </div> */}
                            </div>
                            <div className='custom-table-outter'>
                            <RecentRequest />
                            </div>
                        </div>
                        {/* hare i am sending the inventory count just for handling error */}
                        <UnreadMessage inventoryCount={1} />
                    </div>
                    {/* <RecentRequest /> */}
                    {/* <div style={{ display: 'flex' }}>
                        <div style={{ float: 'left', width: '47%' }}><DisplayGrid tableData={tableData} columns={columns} localeText={localeText} title={title} /></div>
                        <div style={{ float: 'right', width: '47%', marginLeft: '2%' }}><RecentRequest /></div>
                    </div> */}

                </div>
            </div>
        </div>
    );
};

export default Dashboard;
