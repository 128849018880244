import React, { useEffect, useState } from 'react';
import CurrencyFormatter from '../Static/CurrencyFormatter';
import { Table } from 'react-bootstrap';
import AccountTransferIcon from "../../assets/images/icons/AccountTransferIcon.svg";
import AccountTransferCrossIcon from "../../assets/images/icons/AccountTransferCrossIcon.svg";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ShippingCrossIcon from "../../assets/images/icons/LocalShippingCrossIcon.svg";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CommonService } from '../../Service/CommonService';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { VisibilityOutlined, EditOutlined, DeleteOutlined } from "@mui/icons-material";
import StatusDropdown from "../Common/StatusDropdown";
import { OrderApiService } from '../../Service/OrderApiService';
import { Tooltip, Typography } from '@mui/material';
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Offcanvas from 'react-bootstrap/Offcanvas';
import ViewProductDetail from './ViewProductDetail';

interface CartProps {
    cartItems: any[];
    onEditClick: (itemId: string) => void;
    onDeleteClick: (itemId: string) => void;
    onOrderItemStatusChange:(newStatus: string, orderItemId: any, inventoryId: any, inventoryName: any) => void;
    viewDetails: any;
    handleViewClose: any;
    handleViewShow: any;
}

const InventoryFileListTable: React.FC<CartProps> = ({ cartItems, onEditClick, onDeleteClick, onOrderItemStatusChange, viewDetails, handleViewClose, handleViewShow }) => {
    const [isOpen, setIsOpen] = useState<boolean[]>();
    const [inventoryId, setinventoryId] = useState("");
    const [isSeller, setIsSeller] = useState<boolean>(false);

    useEffect(() => {
        setIsOpen(cartItems.map(() => false));

        const isSeller = cartItems.some((item) => {
            return item.typeSenseResponse.company_id === CommonService.getCompanyId() && CommonService.isUser()
        });
        setIsSeller(isSeller);
    }, [cartItems]);

    const toggleSection = (index: number) => {
        setIsOpen(prevState => prevState.map((open, i) => (i === index ? !open : open)));
    };

    //formate the status
    function formatStatus(status?: string): string {
        let formattedStatus = "";
        if (status !== undefined) {
        formattedStatus = status
            ?.split(/[_\\]/)
            .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
        }
        return formattedStatus;
    }

    return (
        <>
        <div className="table-responsive-lg table-responsive-fixed-header mb-lg-3">
            <Table className='orderRequest-inv-table text-center'>
                <thead>
                <tr>
                    <th className='text-nowrap text-start'>Inventory</th>
                    { !isSeller && <>
                    <th colSpan={2} className="text-center text-nowrap">Seller</th>
                    <th colSpan={2} className="text-center text-nowrap">Buyer</th>
                    </>}
                    <th className='text-nowrap text-center'>Total Qty.</th>
                    <th className='text-nowrap text-center'>Price</th>
                    <th className='text-nowrap text-center'>Total</th>
                    { !isSeller && <th colSpan={2} className="text-center text-nowrap">Fees</th>}
                    { (isSeller || CommonService.isAdmin()) && <th className='text-nowrap text-center'>Owner Status</th> }
                    <th className='text-nowrap text-center'>Action</th>
                </tr>
                <tr>
                    <th className='text-nowrap text-center'></th>
                    { !isSeller && <>
                    <th className='text-nowrap text-center'>Relocation</th>
                    <th className='text-nowrap text-center'>Cha. of Acc.</th>
                    <th className='text-nowrap text-center'>Relocation</th>
                    <th className='text-nowrap text-center'>Cha. of Acc.</th>
                    </>}
                    <th className='text-nowrap text-center'></th>
                    <th className='text-nowrap text-center'></th>
                    <th className='text-nowrap text-center'></th>
                    { !isSeller && <>
                    <th className='text-nowrap text-center'>Handling</th>
                    <th className='text-nowrap text-center'>Services</th>
                    </>}
                    {(isSeller || CommonService.isAdmin()) && <th className='text-nowrap text-center'></th>}
                    <th className='text-nowrap text-center'></th>
                </tr>
                </thead>
                <tbody>
                    {cartItems.length > 0 ? (
                        cartItems.map((item: any, index: number) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td className='text-start'>
                                        <div className="header d-flex flex-column" onClick={() => toggleSection(index)}>
                                            {/* <div className={`icon ${isOpen[index] ? 'minus' : 'plus'}`}></div> */}
                                            {/* <>
                                                {isOpen[index] ? <RemoveCircleOutlineIcon sx={{ color: "#737376", mr: 1, cursor: 'pointer' }} /> : <AddCircleOutlineIcon sx={{ color: "#737376", mr: 1, cursor: 'pointer' }} />}
                                            </> */}
                                            <p className='m-1'>{item.inventoryName}</p>
                                            <Typography component="p" sx={{ color: "#737376", fontSize: "13px", fontWeight: "400", lineHeight:"21.28px" }}>
                                                {item.inventoryOwnerCompanyName}
                                            </Typography>

                                        </div>
                                    </td>
                                    { !isSeller && <>
                                    <td className='text-center'>
                                        {item.isRelocationRequired ? (
                                            <>
                                                <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                            </>
                                        ) : (
                                            <>
                                                <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                            </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {item.isAccountTransfer ? (
                                            <>
                                               <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                           </>
                                        ) : (
                                           <>
                                               <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                           </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {item.typeSenseResponse?.is_relocation_required ? (
                                            <>
                                                <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                            </>
                                        ) : (
                                            <>
                                                <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                            </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {item.typeSenseResponse?.is_account_transfer ? (
                                            <>
                                               <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                           </>
                                        ) : (
                                           <>
                                               <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                           </>
                                        )}
                                    </td>
                                    </>}
                                    <td className='text-center'>{item.quantity} barrels
                                        <div>
                                            <button className='in-stock-btn green-btn text-nowrap'>305 In  Stock</button>
                                            {/* <button className='in-stock-btn red-btn text-nowrap'>53 In  Stock</button> */}
                                        </div>
                                    </td>
                                    <td className='text-center'><CurrencyFormatter amount={!isSeller ? item.price : item.price - item.platformFees} locale="en-US" currency="USD" /></td>
                                    <td className='text-center'><CurrencyFormatter amount={!isSeller ? item.totalPrice : item.totalPrice  - (item.platformFees * item.quantity)} locale="en-US" currency="USD" /></td>
                                    { !isSeller && <>
                                    <td className='text-center'><CurrencyFormatter amount={item.handlingFees} locale="en-US" currency="USD" /></td>
                                    <td className='text-center'><CurrencyFormatter amount={item.serviceFees} locale="en-US" currency="USD" /></td>
                                    </>}
                                    {(isSeller || CommonService.isAdmin()) && <> 
                                    <td className='text-center'>
                                        {CommonService.isAdmin() && <StatusDropdown currentStatus={formatStatus(item?.status)}
                                            onStatusChange={(newStatus) =>
                                                onOrderItemStatusChange(newStatus,item['id'],item['inventoryId'],item['inventoryName'])
                                            }
                                            disableCondition={item.userId === CommonService.getUserId() || item?.status === "REJECTED" || item?.status === "ADMIN_REJECTED"}
                                            allowedLabels={(item?.status === "ACCEPTED" || item?.status === "ADMIN_ACCEPTED") ? ["Reject for Owner"] 
                                                            : item?.status === "PENDING_REVIEW" ? ["Reject for Owner", "Accept for Owner"] 
                                                            :["Reject for Owner", "Accept for Owner", "Send to Owner"]}/>}

                                        {CommonService.isUser() && <StatusDropdown currentStatus={formatStatus(item?.status)}
                                            onStatusChange={(newStatus) =>
                                                onOrderItemStatusChange(newStatus,item['id'],item['inventoryId'],item['inventoryName'])
                                            }
                                            disableCondition={item.userId === CommonService.getUserId() || item?.status === "REJECTED" || item?.status === "ADMIN_REJECTED"}
                                            allowedLabels={item?.status === "ADMIN_ACCEPTED" ? ["Rejected"] : ["Accepted", "Rejected"]}/>}

                                        {(item?.status === "REJECTED" || item?.status === "ADMIN_REJECTED") && (
                                            <Tooltip
                                            title={`Rejection Note: ${item.rejectionNote}`}
                                            arrow
                                            >
                                            <StickyNote2Icon
                                                className="note-icon cursor-pointer"
                                                style={{ color: "gray" }}
                                            />
                                            </Tooltip>
                                        )}

                                    </td></>}
                                    
                                        <td className='text-center'>
                                            <div className='d-flex justify-content-center gap-3'>
                                            {(CommonService.isAdmin() && (item?.orderStatus === "PENDING_REVIEW" || item?.orderStatus === "UNDER_REVIEW"))&& <><BorderColorIcon 
                                                onClick={() => onEditClick(item.id)} 
                                                style={{ cursor: 'pointer' }}
                                            />
                                            {cartItems.length > 1 && (<DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteClick(item.id)}/>)}
                                            </>
                                            }
                                            <VisibilityOutlined style={{ cursor: 'pointer' }}  onClick={() => {handleViewShow(); setinventoryId(item.inventoryId); }}/>
                                            </div>
                                        </td>
                                    
                                    {/* {CommonService.isAdmin() && (
                                        <td>
                                            <DeleteOutlined style={{ cursor: 'pointer' }} />
                                        </td>
                                    )}
                                    {CommonService.isAdmin() && (
                                        <td>
                                            <VisibilityOutlined style={{ cursor: 'pointer' }} />
                                        </td>
                                    )} */}
                                </tr>
                                {/* {isOpen[index] && (
                                    <tr className="product-info">
                                        <td colSpan={7}>
                                            <div className="details">
                                                <span className="title d-inline-block me-2">Proof Gallons :</span>
                                                <span className="text d-inline-block">{item.typeSenseResponse?.proof_gallons}pf-gal</span>
                                                <span className="title d-inline-block me-2">Spirit Age:</span>
                                                <span className="text d-inline-block">{item.typeSenseResponse?.spirit_age_year}{item.typeSenseResponse.spirit_age_month === 0 ? '+' : ''} years</span>
                                                <span className="title d-inline-block me-2">Lot ID:</span>
                                                <span className="text d-inline-block">{item.typeSenseResponse?.lot_no}</span>
                                                <span className="title d-inline-block me-2">Batch Number:</span>
                                                <span className="text d-inline-block">{item.typeSenseResponse?.barrel_number}</span>
                                            </div> */}
                                            {/* <b>Proof Gallons:</b> {item.typeSenseResponse.proof_gallons}pf-gal | 
                                            <b>Spirit Age:</b> {item.typeSenseResponse.spirit_age_year}{item.typeSenseResponse.spirit_age_month === 0 ? '+' : ''} years | 
                                            <b>Lot ID:</b> {item.typeSenseResponse.lot_no} | 
                                            <b>Batch Number:</b> {item.typeSenseResponse.barrel_number} */}
                                        {/* </td>
                                    </tr>
                                )} */} 
                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={12} style={{ textAlign: 'center' }}>Fetching records...</td>
                        </tr>
                    )}

                    {/* <tr className="product-info">
                        <td colSpan={!isSeller ? 6 : 2} className='text-nowrap text-end'>
                            <Typography component="span" sx={{ color: "#333333", fontSize: "13px", fontWeight: "400", lineHeight: "17.29px" }}>
                                The price does not include shipping costs.
                            </Typography>
                        </td>
                        <td className='text-nowrap text-center'> <Typography
                            component="span"
                            sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight: "23.94px" }}
                        >
                            Subtotal:
                        </Typography>
                        </td>
                        <td className='text-nowrap'>
                            <Typography
                                component="span"
                                sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight: "21.28px" }}
                            >
                                $546
                            </Typography>
                        </td>
                        {!isSeller && <><td className='text-nowrap'>
                            <Typography
                                component="span"
                                sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight: "21.28px" }}
                            >
                                $345
                            </Typography>
                        </td>
                            <td className='text-nowrap'>
                                <Typography
                                    component="span"
                                    sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight: "21.28px" }}
                                >
                                    $345
                                </Typography>
                            </td></>}
                        <td className={`text-nowrap ${(isSeller || CommonService.isAdmin()) && "text-end"}`}>
                            <Typography
                                component="span"
                                sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight: "23.94px" }}
                            >
                                Total:
                            </Typography>
                            {(!isSeller && CommonService.isUser())&& (
                                <Typography
                                    component="span"
                                    sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight: "23.94px", paddingLeft: "10px" }}
                                >
                                    $37352
                                </Typography>
                            )}
                        </td>
                        {(isSeller || CommonService.isAdmin()) && (
                            <td>
                                <Typography
                                    component="span"
                                    sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight: "23.94px" }}
                                >
                                    $37352
                                </Typography>
                            </td>
                        )}
                    </tr> */}
                </tbody>
            </Table>
        </div>
            {viewDetails &&
                <ViewProductDetail handleViewClose={handleViewClose} inventoryId={inventoryId}/>}
        </>
    );
};

export default InventoryFileListTable;