import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  cardHeaderClasses,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { Service } from "../../Service/ApiService";
import { useEffect, useRef, useState } from "react";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  Iso,
} from "@mui/icons-material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/UserList.scss";
import SearchIcon from "../../assets/images/icons/search.svg";
import InviteUser from "../popups/InviteUser";
import React from "react";
import SingleApprove from "../popups/SingleApprove";
import SingleReInvite from "../popups/SingleReInvite";
import SingleReject from "../popups/SingleReject";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import DeleteUser from "../popups/DeleteUser";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../Static/SearchBar";
import { isDebuggerStatement } from "typescript";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { OrderApiService } from "../../Service/OrderApiService";
import Cookies from "universal-cookie";
import Constant from "../../Constant";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modal from "react-bootstrap/Modal";
import InfoIcon from "@mui/icons-material/Info";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import StatusDropdown from "../Common/StatusDropdown";
import { common } from "@mui/material/colors";
import Spinner from "../Common/Spinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SampleRequest = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [tableData, setTableData] = useState<any[]>([]);
  const [reviewUserCount, setReviewCount] = useState(0);
  const [search, setSearch] = useState("");
  let approved: any[] = [];
  const approvedUserRef = useRef(approved);
  const disableAction = useRef(false);
  const isNeedReviewClicked = useRef(false);
  const selectedRows = useRef(approved);
  const [userRole, setUserRole] = useState<string | null>(null);
  const sampleRequestStatusRefrence = useRef({
    id: "",
    inventoryId: "",
    sampleRequestStatus: "",
    trackNumber: "",
    rejectionNote: "",
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const rejectionNote = useRef("");
  const trackNumber = useRef("");
  const handleClose = () => {
    setShow(false);
    setError("");
    setNote("");
    setTrackNumber("");
  };
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [trackNumberValue, setTrackNumber] = useState<string>("");
  const [isApproved, setIsApproved] = useState(false);
  const [error, setError] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;



  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const [isLoading, setIsLoading] = useState(false);

  const [isSpinner, setSpinner] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const history = useHistory();

  const fetchAllActiveOrders = async () => {
    try {
      setIsLoading(true); // Set the loading flag to true before the API call
      const response = await OrderApiService.getActiveSampleRequests();
      approved = Object.values(response.data); // Convert object to array
      approvedUserRef.current = approved;
      // Access updated value of approvedUser using approvedUserRef.current
      setTableData(approvedUserRef.current);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openSampleRequests = async () => {
    try {
      setIsLoading(true); // Set the loading flag to true before the API call
      const response = await OrderApiService.getCompanyWiseSampleRequests(
        params,
        false,CommonService.getDashboardPrefrence()
      ); // Applies the status filter only if isFetchOpenRequests is true;

      setTableData(Object.values(response.data)); // Convert object to array
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchUserRole = () => {
    const cookies = new Cookies();
    const role = cookies.get("userRole");
    setUserRole(role);
  };

  useEffect(() => {
    fetchUserRole();
  }, []);

  useEffect(() => {
    if (
      currentPath === "/master/my-warehouse/sample-request/" + id ||
      currentPath === "/user/my-warehouse/sample-request"
    )
      openSampleRequests();
    else fetchAllActiveOrders();
  }, []);

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    event.stopPropagation(); // Prevent event from bubbling up to the row
    CommonService.Toast.fire({
      title: "View icon clicked",
    });
  };

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //this code is for dropdown
  const [rowStatusMap, setRowStatusMap] = React.useState<{
    [key: string]: string;
  }>({});

  // const getVariant = (status: any) => {
  //   switch (status) {
  //     case "ACCEPTED":
  //       return "success";
  //     case "PENDING_REVIEW":
  //       return "primary";
  //     case "REJECTED":
  //       return "danger";
  //     default:
  //       return "secondary";
  //   }
  // };

  const handleStatusChange = async (
newStatus: string, rowId: string, inventoryId:any ) => {
    console.log(newStatus);
    console.log("handle status is running");
    // const statusMap: { [key: string]: string } = {
    //   "1": "Approved",
    //   "2": "Pending Review",
    //   "3": "Rejected",
    // };

    // const newStatus = statusMap[eventKey];

    const sampleRequestStatus = newStatus?.replace(/ /g, "_");
    //create the object to send
    const currentStatus = {
      id: rowId,
      inventoryId: inventoryId,
      sampleRequestStatus: sampleRequestStatus.toUpperCase(),
      trackNumber: "",
      rejectionNote: "",
    };
    console.log(
      "this is my current status" + currentStatus.sampleRequestStatus
    );
    sampleRequestStatusRefrence.current = currentStatus;

    if (
      sampleRequestStatusRefrence.current.sampleRequestStatus ===
      Constant.SampleRequestStatus.REJECTED
    ) {
      setIsApproved(false);
      handleShow();
    } else if (
      sampleRequestStatusRefrence.current.sampleRequestStatus ===
      Constant.SampleRequestStatus.ACCEPTED
    ) {
      setIsApproved(true);
      handleShow();
    } else {
      //if status is not ACCEPTED and REJECTED then call method don't open any popup
      changeSampleRequestStatus();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (
      sampleRequestStatusRefrence.current.sampleRequestStatus ===
      Constant.SampleRequestStatus.REJECTED
    ) {
      setNote(event.target.value);
      setError("");
      rejectionNote.current = event.target.value;
    } else {
      setTrackNumber(event.target.value);
      setError("");
      trackNumber.current = event.target.value;
    }
  };

  const changeSampleRequestStatus = async () => {
    if (
      sampleRequestStatusRefrence.current.sampleRequestStatus ===
        Constant.SampleRequestStatus.REJECTED &&
      rejectionNoteValue.trim().length === 0
    ) {
      setError("Please enter rejection note");
      return;
    }

    if (
      sampleRequestStatusRefrence.current.sampleRequestStatus ===
        Constant.SampleRequestStatus.ACCEPTED &&
      trackNumberValue.trim().length === 0
    ) {
      setError("Please enter truck number");
      return;
    }

    setSpinner(true);
    try {
      if (
        sampleRequestStatusRefrence.current.sampleRequestStatus ===
        Constant.SampleRequestStatus.REJECTED
      ) {
        sampleRequestStatusRefrence.current.rejectionNote =
          rejectionNote.current;
      } else if (
        sampleRequestStatusRefrence.current.sampleRequestStatus ===
        Constant.SampleRequestStatus.ACCEPTED
      ) {
        sampleRequestStatusRefrence.current.trackNumber = trackNumber.current;
      }

      await OrderApiService.changeSampleRequestStatus(
        sampleRequestStatusRefrence.current
      );
      setSpinner(false);
      fetchAllActiveOrders();
      CommonService.Toast.fire({
        title: "Sample Request updated successfully",
        icon: "success",
      });
      const rowIndex = tableData.findIndex(
        (row) => row.id === sampleRequestStatusRefrence.current.id
      );

      if (rowIndex !== -1) {
        // Create a new array with the updated status for the found object
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex] = {
          ...updatedTableData[rowIndex],
          status: sampleRequestStatusRefrence.current.sampleRequestStatus,
        };

        // Update the tableData state with the new array
        setTableData(updatedTableData);
      }
      handleClose();
    } catch (error) {
      console.log("error occurred which api calling");
    }
  };

  //formate the status
  function formatStatus(status: string): string {
    const formattedStatus = status
      .split(/[_\\]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return formattedStatus;
  }

  const revertStatus = async () => {
    let previous = tableData.find(
      (row: any) => row.id === sampleRequestStatusRefrence.current.id
    );
    const rowIndex = tableData.findIndex(
      (row) => row.id === sampleRequestStatusRefrence.current.id
    );

    if (rowIndex !== -1) {
      // Create a new array with the updated status for the found object
      const updatedTableData = [...tableData];
      updatedTableData[rowIndex] = {
        ...updatedTableData[rowIndex],
        status: previous.status,
      };

      // Update the tableData state with the new array
      setTableData(updatedTableData);
    }

    setTableData(tableData);
    handleClose();
  };

  const handleRowClick = (params) => {
    const linkPrefix = userRole === "SUPER_ADMIN" ? "/master" : "/user";
    history.push(`${linkPrefix}/view-sample-request/${params.row.id}`);
  }

  const columns: GridColDef[] = [
    {
      field: "inventoryName",
      headerName: "Inventory",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.row.inventoryName}/>
    },
    {
      field: "requestNumber",
      headerName: "Request Number",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.row.requestNumber || '-'}/>
    },
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    userRole === "SUPER_ADMIN" && {
      field: "requestedBy",
      headerName: "Requested By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "requestedOn",
      headerName: "Requested On",
      headerClassName: "table-header",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
    },
    {
      field: "size",
      headerName: "Sample Size",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-IN"
          currency="INR"
        />
      ),
    },
    {
      field: "purpose",
      headerName: "Purpose",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    // {
    //   field: "finalAmount",
    //   headerName: "Total Amount",
    //   headerClassName: "table-header",
    //   flex: 1,
    //   renderCell: (params) => <CurrencyFormatter amount={params.value} locale="en-US" currency="USD" />,
    // },
    {
      field: "status",
      headerName: "Order Status",
      headerClassName: "table-header",
      cellClassName: "status-badge",
      width: 150, // Set an initial width
      minWidth: 150, // Ensure the column does not shrink below this width
      flex: 1,
      renderCell: (params: any) => (
        // <div className="mb-2">
        //   <DropdownButton
        //     as={ButtonGroup}
        //     key={'up'}
        //     id={`dropdown-button-drop-${'up'}`}
        //     drop={'start'}
        //     title={formatStatus(params.row.status)}
        //     className="dropdown-status"
        //     disabled={userRole === "USER"}
        //     variant={getVariant(params.row.status)}
        //     onSelect={(eventKey) => handleStatusChange(eventKey as string, params.row.id, params.row.inventoryId)}
        //   >
        //     {/* Dropdown items */}
        // {(params.row.status !== 'PENDING_REVIEW' && params.row.status !== 'APPROVED' && params.row.status !== 'REJECTED') && (
        //   <Dropdown.Item eventKey="2" className="dropdown-item-pendingReview"><PendingActionsIcon fontSize="small" sx={{ mr: 1 }} /> Pending Review</Dropdown.Item>
        // )}
        // {params.row.status !== 'APPROVED' && (
        //   <Dropdown.Item eventKey="1" className="dropdown-item-accepted"><HowToRegIcon fontSize="small" sx={{ mr: 1 }} /> Approved</Dropdown.Item>
        // )}
        // {params.row.status !== 'REJECTED' && (
        //   <Dropdown.Item eventKey="3" className="dropdown-item-rejected"><PersonOffIcon fontSize="small" sx={{ mr: 1 }} /> Rejected</Dropdown.Item>
        // )}
        //   </DropdownButton>
        // {params.row.status === "REJECTED" && (
        //   <Tooltip title={`Rejection Note: ${params.row.rejectionNote}`} arrow>
        //     <InfoIcon className="info-icon" />
        //   </Tooltip>
        // )}
        // </div >
        <div className="dropdown-nospacing" onClick={(e) => e.stopPropagation()}>

        <>
          {params.row?.status === "PENDING_REVIEW" &&
            CommonService.isUser() && (
              <StatusDropdown
                currentStatus={params.row.status}
                onStatusChange={(newStatus) =>
                  handleStatusChange(
                    newStatus,
                    params.row.id,
                    params.row.inventoryId
                  )
                }
                disableCondition={false}
                allowedLabels={["Cancelled"]}
              />
            )}

{((params.row?.status == "PENDING_REVIEW" ||  params.row?.status == "UNDER_REVIEW" ) && CommonService.isAdmin()) &&
                <StatusDropdown
                  currentStatus={params.row?.status}
                  onStatusChange={(newStatus) =>
                    handleStatusChange(
                      newStatus,
                      params.row.id,
                      params.row.inventoryId
                    )
                  }
                  disableCondition={CommonService.isUser() || params.row?.status === "CANCELLED"}
                  allowedLabels={["Rejected", "Accepted", "Under Review"]}
                />}

{params.row?.status == "UNDER_REVIEW" && CommonService.isUser() &&
                <StatusDropdown
                  currentStatus={params.row?.status}
                  onStatusChange={(newStatus) =>
                    handleStatusChange(
                      newStatus,
                      params.row.id,
                      params.row.inventoryId
                    )
                  }
                  disableCondition={true}
                  allowedLabels={[]}
                />}

{(params.row?.status == "ACCEPTED" || params.row?.status == "INVOICE_SENT" || params.row?.status == "IN_TRANSIT") &&
                <StatusDropdown
                  currentStatus={params.row?.status}
                  onStatusChange={(newStatus) =>
                    handleStatusChange(
                      newStatus,
                      params.row.id,
                      params.row.inventoryId
                    )
                  }
                  disableCondition={CommonService.isUser() || params.row?.status === "CANCELLED"}
                  allowedLabels={["Rejected", "Invoice Sent", "Payment Received", "In Transit"]}
                />}

{params.row?.status == "PAYMENT_RECEIVED"  &&
                <StatusDropdown
                  currentStatus={params.row?.status}
                  onStatusChange={(newStatus) =>
                    handleStatusChange(
                      newStatus,
                      params.row.id,
                      params.row.inventoryId
                    )
                  }
                  disableCondition={CommonService.isUser()}
                  allowedLabels={["Delivered", "Rejected"]}
                />}


{(params.row?.status == "DELIVERED" || params.row?.status == "CANCELLED" || params.row?.status == "REJECTED") &&
                <StatusDropdown
                  currentStatus={params.row?.status}
                  onStatusChange={(newStatus) =>
                    handleStatusChange(
                      newStatus,
                      params.row.id,
                      params.row.inventoryId
                    )
                  }
                  disableCondition={true}
                  allowedLabels={[]}
                />}

          {/* {params.row.status == "APPROVED" && (
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row.id,
                  params.row.inventoryId
                )
              }
              disableCondition={CommonService.isUser()}
              allowedLabels={["Rejected"]}
            />
          )}

          {params.row.status == "REJECTED" &&(
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row.id,
                  params.row.inventoryId
                )
              }
              disableCondition={CommonService.isUser()}
              allowedLabels={["Approved"]}
            />
          )} */}

          {params.row.status === "REJECTED" && (
            <Tooltip
              title={`Rejection Note: ${params.row.rejectionNote}`}
              arrow
            >
              <InfoIcon className="info-icon" />
            </Tooltip>
          )}
        </>
        </div>
      ),
    },
  ].filter(Boolean) as GridColDef[];

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return tableData.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    selectedRows.current = newSelectedRows;
    let flag: any = false;
    flag = selectedRows.current.some((user) => user.status === "INVITED");
    disableAction.current = flag;
  };

  // Filter rows based on search query
  const filteredRows = tableData.filter((row) =>
    CommonService.isAdmin()
      ? row.purpose?.toLowerCase().includes(search?.toLowerCase()) ||
        row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.inventoryName?.toLowerCase().includes(search?.toLowerCase()) ||
        (row.firstName + " " + row.lastName)
          .toLowerCase()
          .includes(search?.toLowerCase())
      : row.purpose?.toLowerCase().includes(search?.toLowerCase()) ||
        row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.inventoryName?.toLowerCase().includes(search?.toLowerCase())
  );

  // Map filtered rows with unique IDs
  const tableDataWithIds = filteredRows.map((rowData, index) => ({
    ...rowData,
  }));

  const handleBack = () => {
    history.goBack();
  }

  return (
    <>
      <div className="usersList">
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        {CommonService.isAdmin() && (<IconButton
                  onClick={handleBack}
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    mb: 1,
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>)}
          <div className="header--title mb-2 me-2">
            <h1 className="title mb-0 ms-2">
              {/* {userRole == "ADMIN" && "My Sample Requests"} */}
              {userRole == "SUPER_ADMIN" && "Sample Requests"}{" "}
              <i className="bi bi-heart-fill"></i>
            </h1>
          </div>
          <div className="header--menu ms-auto mb-2">
            <div className="header--menu-items row d-flex justify-content-end">
              {/* <div className="col-auto input-container menu-items__search d-inline-flex mb-2 position-relative">
                                <input type="search" id="gsearch" name="gsearch" />
                                <img src={SearchIcon} alt="SearchIcon" className="icon position-absolute top-50 start-50 translate-middle" />
                            </div> */}
              {/* <input placeholder="Search something..." className="input" name="text" type="text"></input> */}
              <div className="col-auto mb-2">
                <SearchBar search={search} setSearch={setSearch} />
              </div>
              {/* <img src="../images/img_vert_line.svg" alt="vertline_one" className="h-[35px] md:w-full" /> */}
              {/* <div className="col-auto menu-items__btnGroup btnGroup-border d-inline-flex mb-2"></div> */}
              {/* <div className="col-auto menu-items__btnGroup d-inline-flex mb-2">
                {isNeedReviewClicked.current && !disableAction.current && (
                  <button className="white-outline-btn me-1"></button>
                )}
                {isNeedReviewClicked.current && !disableAction.current && (
                  <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1"></button>
                )}
                <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1"></button>
                <span className="counting d-inline-flex justify-content-between align-items-center ms-2">
                  {selectionModel.length}
                </span>
              </div> */}
              {/* <div className={`flex w-[23%] justify-center rounded-[20px] border border-solid border-gray-50 md:w-full ${isButtonClicked ? 'bg-white' : ''}`}>
            <div onClick={handleButtonClick}
    
                className="my-[3px] flex items-center gap-[13px] cursor-pointer"
            >
                <div className={`flex ${isButtonClicked ? 'bg-white' : ''}`}>
                    <p>
                        Need Review
                    </p>
                </div>
                <h2 className={`flex h-[32px] w-[32px] items-center justify-center rounded-[16px] text-center  ${isButtonClicked ? '' : 'bg-white'}`}
                >
                    {reviewUserCount}
                </h2>
            </div>
        </div>
        <img src="../images/img_vert_line.svg" alt="vertline_three" className="h-[35px] md:w-full" />
        <div className="flex gap-2.5">
            <button className="min-w-[129px] gap-2 !text-gray-500">
                Approve
            </button>
            <button
                className="min-w-[111px] gap-2 !text-gray-500"
            >
                Reject
            </button>
            <h3
                className="flex h-[40px] items-center justify-center rounded-[20px] border border-solid border-white-A700 text-center !text-gray-500"
            >
                0
            </h3>
        </div> */}
            </div>
          </div>
        </div>
        <div className="user-table-container">
          <div className="userTable">
            <DataGrid
              rows={tableDataWithIds}
              columns={columns}
              className="data-grid"
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              // checkboxSelection
              disableRowSelectionOnClick={true}
              localeText={localeText}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={handleSelectionModelChange}
              onRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Sample Request Status</Modal.Title>
          {isApproved && (
            <p className="modal-text">
              Are you sure, you want to change status to Approved?
            </p>
          )}
          {!isApproved && (
            <p className="modal-text">
              Are you sure, you want to change status to Rejected?
            </p>
          )}
        </Modal.Header>
        <Modal.Body>
          {isApproved && (
            <div className="form__inner">
              <Form.Control
                className={`form-control ${error ? "is_invalid" : ""}`}
                type="text"
                value={trackNumberValue}
                onChange={handleInputChange}
                placeholder="Enter Tracking Number..."
              />
              {error && <div className="error-message">{error}</div>}
            </div>
          )}
          {!isApproved && (
            <div className="form__inner">
              <textarea
                className={`form-control ${error ? "is_invalid" : ""}`}
                aria-label="With textarea"
                value={rejectionNoteValue}
                onChange={handleInputChange}
                rows={8}
                style={{ resize: "none" }}
                placeholder="Enter Rejection Note..."
              />
              {error && <div className="error-message">{error}</div>}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={revertStatus}
                disabled={isSpinner}
              >
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={changeSampleRequestStatus}
                disabled={isSpinner}
              >
                {isSpinner && <Spinner />} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SampleRequest;
