import React from 'react';
import { Typography } from "@mui/material";
import groupDiscussionMeetingIcon from '../../assets/images/icons/group-discussion-meeting.svg';

interface SupportCommentBoxProps {
  openRequestCount: any;
  closeRequestCount: any;
}

const NoTicketCreated: React.FC<SupportCommentBoxProps>  = ({openRequestCount, closeRequestCount}) => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center my-3'>
      <img src={groupDiscussionMeetingIcon} alt="groupDiscussionMeetingIcon" />
      <Typography variant="body2" sx={{ color: '#737376', fontSize: '16px', fontWeight: 600 }}>
        {(openRequestCount == 0 && closeRequestCount == 0) ? "No tickets created":  "No tickets found"}
      </Typography>
    </div>
  )
}

export default NoTicketCreated