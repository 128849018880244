import React, { useEffect, useRef, useState } from 'react';
import { Form, ListGroup, Card } from 'react-bootstrap';
import { MessageApiService } from '../../Service/MessageApiService';
import { CookieService } from '../../Service/CookieService';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { format, isToday, isYesterday } from 'date-fns';
import { Service } from '../../Service/ApiService';
import { CommonService } from '../../Service/CommonService';
import DownloadSimpleIcon from "../../assets/images/icons/DownloadSimple.svg";
import TrashIcon from "../../assets/images/icons/Trash.svg";
import { useWebSocket } from '../../Websocket/WebSocketProvider';



interface SupportCommentBoxProps {
  supportRequestId: any;
  status: any;
}

const SupportCommentBox: React.FC<SupportCommentBoxProps> = ({ supportRequestId, status }) => {
  const [messages, setMessages] = useState<any[]>([]);
  const [messageContent, setMessageContent] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const userId = CookieService.getCookie("userId");
  let isTodayLineDisplay = false;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { webSocketService } = useWebSocket();

  useEffect(() => {
    const module = 'myModule';
    const callbackMethod = (data: any) => {
      setMessages(prevMessages => [...prevMessages, data?.comment]);

    };

    webSocketService.subscribe(module, callbackMethod);

    return () => {
      // Clean up subscriptions if necessary
    };
  }, [webSocketService]);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteFile = (indexToDelete: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      // Replace old files with new ones
      const newFiles = Array.from(event.target.files);
      setFiles(newFiles);

      // Clear the input value to allow selecting the same file again
      event.target.value = "";
    }
  };

  useEffect(() => {
    fetchComments(supportRequestId);
  }, [supportRequestId]);


  const downloadFile = async (fileName:any, message:any) => {
  
    try {

      const requestData = {
        fileName: fileName,
        companyId: message.companyId,
        creatorId: message.creatorId,
        commentId: message.commentId,
        supportRequestId: message.supportRequestId,
      }
      const response: any = await Service.downloadFile(requestData);
      // Assuming response.data is the blob data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName // Specify the download filename
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const fetchComments = async (id: any) => {
    setIsLoading(true); // Set loading state
    try {
      const response = await Service.getAllComments(id);
      setMessages(response.data); // Set messages from response
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

 

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!messageContent || messageContent.trim() === '') return; // Prevent sending empty messages

    const formData = new FormData();
    if(files) {
    Array.from(files).forEach(file => {
        formData.append("file[]", file);
    });

  }



    const newMessage: any = {
      content: messageContent,
      creatorId: CommonService.getUserId(),
      supportRequestId: supportRequestId,
      companyId: CommonService.getCompanyId(),
      // Include other properties if needed
    };

    formData.append("param", new Blob([JSON.stringify(newMessage)], { type: "application/json" }));


    try {
        const response = await Service.saveSupportRequest(formData);
        console.log('Response:', response.data);
        setFiles(null);
        setMessageContent("");
        fetchComments(supportRequestId);
    } catch (error) {
        console.error('Error submitting support request:', error);
        CommonService.Toast.fire({
          title: "Error while sending support comment:",
          icon: "error",
        });
    }
  };

  const formatTimestamp = (timestamp: string) => {
    if (timestamp) {
      const date = new Date(timestamp);
      if (isToday(date)) {
        const differenceInMinutes = Math.floor((Date.now() - date.getTime()) / (1000 * 60));
        return differenceInMinutes < 1 ? "now" : `${differenceInMinutes} min`;
      } else if (isYesterday(date)) {
        return `Yesterday ${format(date, 'hh:mm a')}`;
      } else {
        return format(date, 'yyyy-MM-dd hh:mm a');
      }
    }
    return '';
  };


  const todayLine = (timestamp: string) => {
    if (timestamp) {
      const date = new Date(timestamp);

      if (isToday(date) && !isTodayLineDisplay) {
        return isTodayLineDisplay = true;
      } 
    } 
  };

  useEffect(() => {
    console.log("scrooling")
    setTimeout(() => {

      const messageId = 'message-' + (messages.length - 1);
      console.log(messageId)
      const messageElement: any = document.querySelector(`div[id="${messageId}"]`);

      console.log(messageElement)
      if (messageElement) {
        messageElement.focus();

        setTimeout(() => {
          messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);

      }
    });
  }, [messages]);

  const filteredMessages = messages;

  return (
    <Card>
      {/* <Card.Header>Messages between {user} and {admin}</Card.Header> */}
      <Card.Body>
        <ListGroup variant="flush" style={{
                    height:
                    CommonService.isAdmin()
                        ? "765px"
                        : '',
                  }}>
          {filteredMessages.map((message, index) => (
            <ListGroup.Item key={message.id} id={`message-${index}`}>
               {todayLine(message.createdDate) && <div className="today-line-wrapper">
                <span className='today-text'>Today</span>
                <div className='hz-line'></div>
              </div>}
               
              {message.creatorId === userId ? (
                <>
                  <div className="d-flex justify-content-end">
                    <div className="sender">
                      <div className="sender__msg ms-auto">
                        <p className="message-content m-0">{message.content}</p>
                      {/* <div>
                        {message.files?.map((file) => (
                          <p className='m-0' onClick={() => downloadFile(file.fileName, message)} key={file.id}>{file.fileName.split('_')[1] || file.fileName}<img src={DownloadSimpleIcon} alt="DownloadSimpleIcon" className="me-1" /></p>
                        ))}
                        
                      </div> */}
                      <div className={`d-flex gap-2 flex-wrap ${message.files == 0 ? 'mt-0' : 'mt-2'}`}>
                        {message.files?.map((file) => (<div className='uploaded-file-wrapper'>
                       
                          <div className='m-0 d-flex gap-2' onClick={() => downloadFile(file.fileName, message)} key={file.id}><span title={file.fileName}> {file.fileName.split('_')[1] || file.fileName}</span><div className='download-img-wrapper d-flex justify-content-center align-items-center'><img src={DownloadSimpleIcon} alt="DownloadSimpleIcon" /></div></div>
                        
                      </div>))}
                      </div>
                      </div>
                      <div className="sender__info ms-auto mt-1 text-end">
                        {/* <p className="text-end mb-0"> */}
                          <span className="sender__info--name me-1">{message.name}</span>
                          <span className="sender__info--time me-1">{CommonService.formatDateTime(message.createdDate)}</span>
                          <span className="sender__info--status"><CheckIcon fontSize="small" /></span>
                        {/* </p> */}
                      </div>
                    </div>
                    <div className="avatar">
                    <span>{message?.name ? message.name[0] : 'U'}</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex">
                    <div className="avatar">
                      <span>A</span>
                    </div>
                    <div className="receiver">
                      <div className="receiver__info ms-1">
                        <p className="text-start mb-0">
                          <span className="receiver__info--name me-1">{message.name}</span>
                        </p>
                      </div>
                      <div className="receiver__msg">
                        <p className="message-content m-0">{message.content}</p>
                        <div className={`d-flex gap-2 flex-wrap ${message.files == 0 ? 'mt-0' : 'mt-2'}`}>
                        {message.files?.map((file) => (<div className='uploaded-file-wrapper'>
                       
                        <div
  className="m-0 d-flex gap-2"
  onClick={() => downloadFile(file.fileName, message)}
  key={file.id}
>
  <span
    title={file.fileName}
  >
    {file.fileName.split('_')[1] || file.fileName}
  </span>
  <div className="download-img-wrapper d-flex justify-content-center align-items-center">
    <img src={DownloadSimpleIcon} alt="DownloadSimpleIcon" />
  </div>
</div>

                      </div>))}
                      </div>
                      </div>
                      <div className="receiver__info ms-1 mt-1">
                        <p className="text-start mb-0">
                          <span className="receiver__info--time me-1">{CommonService.formatDateTime(message.createdDate)}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </ListGroup.Item>
          ))}

        </ListGroup>
      </Card.Body>
      {status == "OPEN" && (<Card.Footer className='bg-transparent'>
      <Form onSubmit={handleSubmit} className="send-msg position-relative">
        <div className='send-input-wrapper'>
          <Form.Group controlId="messageContent" className='send-input-inner'>
            <Form.Control
              type="text"
              placeholder="Type a reply..."
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
              style={{
                // paddingRight: '60px',  // Make room for the send button
                whiteSpace: 'nowrap',  // Prevent text wrapping
                overflowX: 'auto',     // Enable horizontal scrolling
                textOverflow: 'ellipsis' // Show ellipsis when text overflows
              }}
            />
          </Form.Group>
          <div style={{ position: 'relative' }} className='mt-2'>
      
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
        onChange={handleFileChange}
      />
        <div className='d-flex align-items-center gap-2 flex-wrap'>
        {files?.map((file, index) => (
                      <div
                        className="file__upload--info d-inline-flex align-items-center gap-2"
                        key={index}
                      >
                        
                          <p className="file-name m-0">{file.name}</p>
                        
                        <div className='trash-img-wrapper'>
                        <img
                          src={TrashIcon}
                          alt="TrashIcon"
                          className="file-delete"
                          onClick={() => handleDeleteFile(index)}
                        />
                        </div>
                      </div>
       ))}

</div>
      {/* {files && (
        <ul>
          {Array.from(files).map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      )} */}
    </div>
    </div>
          <div className='d-flex flex-nowrap flex-row gap-2'>
          <IconButton
        type="button"
        aria-label="submit"
        sx={{
          transform: 'rotate(45deg) ',
          backgroundColor:'#F7F7F7',
          padding:'12px',
          // position: 'absolute', top: '12px', right: '70px'

        }}
        onClick={handleButtonClick}
      >
        <AttachFileIcon />
      </IconButton>
          <IconButton type="submit" aria-label="submit" sx={{backgroundColor:'#FFFFFF'}}>
            <SendOutlinedIcon />
          </IconButton>
          </div>
        </Form>
      
      </Card.Footer>)}
    </Card>
  );
};

export default SupportCommentBox;
