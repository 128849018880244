import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "../Dynamic/LoginHistory.scss";
import "./DuplicateInventories.scss";
import { useHistory } from "react-router";
import SearchBar from "../Static/SearchBar";
import { Checkbox, IconButton, Tooltip, Typography } from "@mui/material";
import { InventoryApiService } from "../../Service/InventoryApiService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileCsvIcon from '../../assets/images/icons/FileCsv.svg';
import DatabaseIcon from '../../assets/images/icons/Database.svg';

interface InventoryData {
  fillDate: string;
  lotNo: string;
  batchName: string | null;
  batchType: string;
  barrelNumber: string;
  barrelProfile: string | null;
  barrelSize: string | null;
  volume: number;
  proof: number;
  proofGallons: number;
  dsp: string;
  spiritAge: number;
  facility: string;
  barrelCost: string | null;
  mashBill: string;
  ownerName: string | null;
  isDuplicate: boolean;
}

interface DuplicateInventoryResponse {
  file: InventoryData;
  inventory: InventoryData;
}

const DuplicateInventories: React.FC = () => {
  const [tableData, setTableData] = useState<DuplicateInventoryResponse[]>([]);
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Dummy data for testing
  const dummyData: DuplicateInventoryResponse[] = [
    {
      file: {
        fillDate: "2023-09-21, 08:10:21",
        lotNo: "File 24B21A",
        batchName: "20240221-8W-STB-155",
        batchType: "Bourbon Whisky",
        barrelNumber: "24-4368",
        barrelProfile: "New American Oak",
        barrelSize: "53.00 gal",
        volume: 52.32,
        mashBill: "72/13/0/15",
        proof: 160,
        proofGallons: 66.09,
        barrelCost: "$235.46",
        facility: "Green River Distilling Co",
        spiritAge: 29,
        ownerName: "Jane Smith, Company",
        dsp: "IN-15023",
        isDuplicate: true,
      },
      inventory: {
        fillDate: "2023-09-21, 08:10:21",
        lotNo: "Inv 24B21A",
        batchName: "20240221-8W-STB-155",
        batchType: "Bourbon Whisky",
        barrelNumber: "24-4368",
        barrelProfile: "New American Oak",
        barrelSize: "53.00 gal",
        volume: 52.32,
        mashBill: "72/13/0/15",
        proof: 160,
        proofGallons: 66.09,
        barrelCost: "$235.46",
        facility: "Green River Distilling Co",
        spiritAge: 29,
        ownerName: "Jane Smith, Company",
        dsp: "IN-15023",
        isDuplicate: false,
      },
    },
    {
      file: {
        fillDate: "2023-09-21, 08:10:21",
        lotNo: "File 24B21A",
        batchName: "20240221-8W-STB-155",
        batchType: "Bourbon Whisky",
        barrelNumber: "24-4368",
        barrelProfile: "New American Oak",
        barrelSize: "53.00 gal",
        volume: 52.32,
        mashBill: "72/13/0/15",
        proof: 160,
        proofGallons: 66.09,
        barrelCost: "$235.46",
        facility: "Green River Distilling Co",
        spiritAge: 29,
        ownerName: "John Doe, Company",
        dsp: "IN-15023",
        isDuplicate: true,
      },
      inventory: {
        fillDate: "2023-09-21, 08:10:21",
        lotNo: "Inv 24B21A",
        batchName: "20240221-8W-STB-155",
        batchType: "Bourbon Whisky",
        barrelNumber: "24-4368",
        barrelProfile: "New American Oak",
        barrelSize: "53.00 gal",
        volume: 52.32,
        mashBill: "72/13/0/15",
        proof: 160,
        proofGallons: 66.09,
        barrelCost: "$235.46",
        facility: "Green River Distilling Co",
        spiritAge: 29,
        ownerName: "John Doe, Company",
        dsp: "IN-15023",
        isDuplicate: false,
      },
    },
    // Add more dummy data as needed
  ];

  const getAllDuplicateInventory = async () => {
    try {
      setIsLoading(true);
      const response: any = await InventoryApiService.getAllDuplicateInventory();
      setTableData(response.data);
    } catch (error) {
      console.error("Error occurred while calling API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getAllDuplicateInventory();
  // }, []);

  // For dummy data :
  useEffect(() => {
    // Simulate API call with dummy data
    setIsLoading(true);
    setTimeout(() => {
      setTableData(dummyData);
      setIsLoading(false);
    }, 1000); // Simulate delay for loading
  }, []);

  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const handleBack = () => {
    history.goBack();
  };

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  const handleAction = (rowId: string, action: string) => {
    if (action === "ignore") {
      console.log(`Ignore action triggered for row: ${rowId}`);
      // Handle ignore logic here
    } else if (action === "replace") {
      console.log(`Replace action triggered for row: ${rowId}`);
      // Handle replace logic here
    }
  };

  const columns: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      width: 50,
      sortable: false, // Disable sorting for this column
      renderCell: (params) => (
        params.row.isDuplicate ? (
          <Checkbox />
        ) : null
      ),
    },
    {
      field: "icon",
      headerName: "",
      width: 50,
      sortable: false, // Disable sorting for this column
      renderCell: (params) => (
        params.row.isFile ? <img src={FileCsvIcon} alt="CSV Icon" /> : <img src={DatabaseIcon} alt="CSV Icon" />
      ),
    },
    {
      field: "fillDate",
      headerName: "Fill Date",
      flex: 1,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "lotNo",
      headerName: "Lot #",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "batchName",
      headerName: "Batch #",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "batchType",
      headerName: "Batch Type",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "barrelNumber",
      headerName: "Barrel #",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "barrelProfile",
      headerName: "Barrel Profile",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "barrelSize",
      headerName: "Size",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "volume",
      headerName: "Volume",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "mashBill",
      headerName: "Mash Bill",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "proof",
      headerName: "Proof",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "proofGallons",
      headerName: "Proof Gallons",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "barrelCost",
      headerName: "Cost",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "facility",
      headerName: "Facility",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "spiritAge",
      headerName: "Spirit Age",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "ownerName",
      headerName: "Owner",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "dsp",
      headerName: "DSP",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "isDuplicate",
      headerName: "Selected Actions",
      headerClassName: "table-header",
      flex: 0,
      minWidth: 175,
      renderCell: (params) => {
        // Show "Ignore | Replace" only for file rows (isFile)
        return params.row.isFile ? (
          <div>
            <button
              className="btn action-btn ignore-btn"
              onClick={() => handleAction(params.row.id, "ignore")}
            >
              Ignore
            </button>
            {" | "}
            <button
              className="btn action-btn replace-btn"
              onClick={() => handleAction(params.row.id, "replace")}
            >
              Replace
            </button>
          </div>
        ) : null; // Do not render any actions for inventory rows
      },
    }
  ];

  // const rows = tableData.map((item, index) => ({
  //   id: index,
  //   ...item.file,
  //   inventory: item.inventory,
  // }));

  // Map filtered rows with unique IDs
  //   const tableDataWithIds = filteredRows.map((rowData, index) => ({
  //     ...rowData,
  //     id: index + 1, // Assigning a unique ID based on the index
  //   }));

  // Alternate rows with file and inventory data
  const rows = tableData.flatMap((item, index) => [
    { id: `${index}-file`, ...item.file, isFile: true }, // Odd row (file data with checkbox)
    { id: `${index}-inventory`, ...item.inventory, isFile: false }, // Even row (inventory data, no checkbox)
  ]);

  return (
    <>
      <div className="usersList">
        <div className="usersList__header--wrapper d-flex flex-wrap align-items-center mb-3">
          <IconButton
            onClick={handleBack}
            aria-label="ArrowBackIcon"
            sx={{ backgroundColor: "#FFF", "&:hover": { backgroundColor: "#FFF" } }}
          >
            <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
          </IconButton>
          <Typography variant="h1" sx={{ color: '#333', fontSize: '25px', fontWeight: 600, ml: 2 }}>
            Duplicate Inventories
          </Typography>
          <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: 600, ml: 1 }}>
          <InfoOutlinedIcon sx={{ color: '#B19329', fontSize: '16px', fontWeight: 600, marginRight: '4px' }} />
             System identifies based on Mash Bill, Lot Id, Barrel and DSP
          </Typography>
          {/* <SearchBar
            search={search}
            setSearch={setSearch}
          /> */}
        </div>
        <div className="duplicate-inv-table-wrapper position-relative">
          <DataGrid
            rows={rows}
            columns={columns}
            className="data-grid-striped"
            autoHeight
            // sx={{
            //   fontFamily: "customfont",
            //   whiteSpace: "nowrap",
            //   overflowX: "scroll",
            //   "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            //     py: "8px",
            //   },
            //   "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            //     py: "15px",
            //   },
            //   "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            //     py: "22px",
            //   },
            // }}
            localeText={{
              noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DuplicateInventories;
