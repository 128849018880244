import "../../App.css";
import { CookieService } from "../../Service/CookieService";
import Cookies from "universal-cookie";
import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import ProfileBar from "../Static/Profile/ProfileBar";
import { Link } from "react-router-dom";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const Header = () => {
  const cookies = new Cookies();
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);

  const logout = () => {
    CookieService.removeAuthToken();
    history.push("/login");
  };

  // useEffect(() => {
  //     // Initialize Bootstrap components when the component mounts
  //     const dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'));
  //     dropdownElementList.map(function (dropdownToggleEl) {
  //       return new window.bootstrap.Dropdown(dropdownToggleEl);
  //     });
  //   }, []);

  useEffect(() => {
    const fetchUserRole = () => {
      const cookies = new Cookies();
      const role = cookies.get("userRole");
      setUserRole(role);
    };

    const fetchCurrentPath = () => {
      const currentPath = location.pathname;
      console.log(currentPath);
      // Update the activeTab state based on the current pathname
      if (currentPath === "/master/dashboard") {
        setActiveTab("dashboard");
      } else if (currentPath === "/master/user-list") {
        setActiveTab("user-list");
      } else if (currentPath === "/inventories") {
        setActiveTab("inventories");
      } else if (currentPath === "/my-inventory") {
        setActiveTab("my-inventory");
      } else if (currentPath === "/inventory-sell-requests") {
        setActiveTab("inventory-sell-requests");
      } else if (currentPath === "/login-history") {
        setActiveTab("login-history");
      } else if (currentPath.includes("/view-csv")) {
        setActiveTab("view-csv");
      } else if (currentPath.includes("/view-sell-request")) {
        setActiveTab("view-sell-request");
      } else if (currentPath.includes("/my-cart")) {
        setActiveTab("my-cart");
      } else if (currentPath.includes("/message")) {
        setActiveTab("my-cart");
      } else {
        setActiveTab("");
      }
    };

    fetchUserRole();

    fetchCurrentPath();
  }, [location.pathname]);

  function route(route: string) {
    history.push(route);
  }

  return (
    <header className="wd__header wd__header--user">
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <Logo size={"200px"} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/master/dashboard")}
                  role="button"
                  aria-expanded="false"
                >
                  Home
                </a>
                {/* <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another action</a></li>
                <li><hr className="dropdown-divider" /></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li>
              </ul> */}
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/master/manage-requests")}
                  role="button"
                  aria-expanded="false"
                >
                  Manage Requests
                </a>
              </li> */}
              {userRole === "SUPER_ADMIN" && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Requests
                    <ExpandMoreRoundedIcon />
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => route("/master/inventory-sell-requests")}
                      >
                        Inventory Sell Requests
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => route("/master/active-order")}
                      >
                        Order Requests
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => route("/master/sample-request")}
                      >
                        Sample Requests
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => route("/master/offer-request")}
                      >
                        Offer Requests
                      </a>
                    </li>
                    {/* <li><hr className="dropdown-divider" /></li> */}
                  </ul>
                </li>
              )}

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/master/messages")}
                  role="button"
                  aria-expanded="false"
                >
                  Inbox
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/master/login-history")}
                  role="button"
                  aria-expanded="false"
                >
                  Login History
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/master/support-requests")}
                  role="button"
                  aria-expanded="false"
                >
                  Support
                </a>
              </li>
            </ul>
          </div>
          {/* <div>
            <ProfileBar />
          </div> */}
          {/* <ul className="navbar-nav ms-auto mb-3 mt-2 mt-md-0 mb-md-0">
            <li className="nav-item dropdown ms-1">
              <ProfileBar />
            </li>
          </ul> */}
        </div>
        <div className="ms-auto me-2 mb-2 mb-lg-0">
          <ProfileBar />
        </div>
      </nav>

      {/* <div>
  
              <div className="logout"> 
                  <Button variant="contained" size="small" onClick={logout}>Logout</Button>
              </div>
          </div> */}
    </header>
  );
};

export default Header;
