import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { InventoryApiService } from '../../Service/InventoryApiService';
import { CommonService } from '../../Service/CommonService';
import { OrderApiService } from '../../Service/OrderApiService';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Button as BootstrapButton } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Constant from '../../Constant';
import { useHistory, useLocation } from "react-router";
import { darken } from '@mui/material/styles';
import Spinner from '../Common/Spinner';
import { useDispatch } from 'react-redux';
import { resetMyCartItems } from '../store/cartSlice';

interface ChildProps {
    addedCartItems: any;
    totalPrice: any;
}

const PlaceOrder: React.FC<ChildProps> = ({ addedCartItems, totalPrice }) => {

    const [show, setShow] = useState(false);
    const history = useHistory();
    const [isOrderPlaced, setOrderPlacement] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [orderNumber, setOrderNumber] = useState();
    const [orderId, setOrderId] = useState();


    const handleClose = () => {
        setShow(false);
        if(isOrderPlaced) {
                history.push({
                    pathname: '/user/order-success',
                    state: { orderId, orderNumber }
                });
         }
    };
    const handleShow = () => setShow(true);

   
    const dispatch = useDispatch();
      
    const resetCart = () => {
        //reset count for profile on remove
          dispatch(resetMyCartItems());
    };

    const placeOrderRequest = () => {

        //set the isLoading true

        setIsLoading(true);

        //make the json for place order
        let placeOrder = {
            orderRequest: {
                id: addedCartItems[0].orderId,
                status: Constant.OrderStatus.PENDING_REVIEW,
                totalAmount: totalPrice,
                companyId: CommonService.getCompanyId(),
                requestNumber: addedCartItems[0].requestNumber
            },
            orderItemRequestList: addedCartItems.map((item: any) => ({
                id: item.id,
                isAccountTransfer: item.isAccountTransfer,
                isRelocationRequired: item.isRelocationRequired,
                handlingFees: item.isRelocationRequired == true ? item.quantity * 10 : 0,
                serviceFees: Math.min(CommonService.isFacilityMatch(item?.typeSenseResponse?.facility) ? 0 :(item.isAccountTransfer == true ? item.quantity * 10 : 0), 5000), 
                quantity: item.quantity,
                totalPrice: item.totalPrice
            }))
        };

        console.log("this is place order", placeOrder);
        OrderApiService.updateOrderStatus(placeOrder)
            .then(res => {
                resetCart();
                console.log(res.data);
                setOrderNumber(res.data);
                setOrderId(placeOrder.orderRequest.id);
                setIsLoading(false);
                setOrderPlacement(true)
            })
        setIsLoading(false);
    }


    return (
        <>
            <Button variant="contained" onClick={handleShow} sx={{ backgroundColor: '#E15A0E', borderRadius: '100px', padding: '10px 48px', height: '42px', fontSize: '18px', fontWeight: 700, color: '#FFF', textTransform: 'none', '&:hover': {
          backgroundColor: darken('#E15A0E', 0.2), // Darkens the color by 20%
        }, }}>Submit Order Request</Button>

            <Modal show={show} onHide={handleClose} className='modal__wd modal__wd--submitSellReq'>
                <Modal.Header className='flex-column align-items-start'>
                    <Modal.Title>Order Request</Modal.Title>

                    {!isOrderPlaced && (
                        <p className="modal-text text-start">
                            Are you sure want to place this order ?</p>
                    )}
                    {isOrderPlaced && (
                        <p className="modal-text text-start">
                            Your order have been placed successfully! </p>
                    )}

                </Modal.Header>

                <Modal.Footer className={`justify-content-center ${isOrderPlaced ? 'bg-transparent' : 'bg-transparent'}`}>
                    <div className="btn-group row d-flex justify-content-between w-100">
                        <div className={isOrderPlaced ? "col-12" : "col-6 ps-0"}>
                            <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                                {!isOrderPlaced && ('Cancel')}
                                {isOrderPlaced && ('Close')}
                            </button>
                        </div>
                        {!isOrderPlaced && (
                            <div className="col-6 pe-0">
                                <button className="primary-btn w-100" onClick={placeOrderRequest} disabled={isLoading}>
                                  {isLoading && <Spinner/>}Yes
                                </button>
                            </div>
                        )}
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default PlaceOrder;