import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/UserList.scss";
import React from "react";

import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../Static/SearchBar";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { OrderApiService } from "../../Service/OrderApiService";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import Constant from "../../Constant";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import StatusDropdown from "../Common/StatusDropdown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

const OfferRequest = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [tableData, setTableData] = useState<any[]>([]);
  const [reviewUserCount, setReviewCount] = useState(0);
  const [search, setSearch] = useState("");
  let approved: any[] = [];
  const approvedUserRef = useRef(approved);
  const history = useHistory();
  const disableAction = useRef(false);
  const isNeedReviewClicked = useRef(false);
  const selectedRows = useRef(approved);
  const rejectionNote = useRef("");
  const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "" });
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;
  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const [isLoading, setIsLoading] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const fetchAllActiveOrders = async () => {
    try {
      setIsLoading(true);
      const response = await OrderApiService.getActiveOfferRequests();
      approved = Object.values(response.data); // Convert object to array
      approvedUserRef.current = approved;
      // Access updated value of approvedUser using approvedUserRef.current
      setTableData(approvedUserRef.current);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleRowClick = (params) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
    history.push(`${linkPrefix}/view-offer-request/${params.row.id}`);
  }

  const openOfferRequests = async () => {
    try {
      console.log(params);
      setIsLoading(true);
      const response = await OrderApiService.getCompanyWiseOfferRequests(
        params,
        false,CommonService.getDashboardPrefrence()
      ); // Applies the status filter only if isFetchOpenRequests is true;

      setTableData(Object.values(response.data)); // Convert object to array
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (
      currentPath === "/master/my-warehouse/offer-request/" + id ||
      currentPath === "/user/my-warehouse/offer-request"
    )
      openOfferRequests();
    else fetchAllActiveOrders();
  }, []);
  

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    event.stopPropagation(); // Prevent event from bubbling up to the row
    CommonService.Toast.fire({
      title: "View icon clicked",
    });
  };

  //add tootip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //this code is for dropdown
  const [rowStatusMap, setRowStatusMap] = React.useState<{
    [key: string]: string;
  }>({});

  const handleStatusChange = async (newStatus: string, rowId: string) => {
    // const statusMap: { [key: string]: string } = {
    //   "1": "Accepted",
    //   "2": "Pending Review",
    //   "3": "Rejected",
    //   "4": "Shipped",
    //   "5": "Cancelled",
    //   "6": "Delivered",
    // };

    // const newStatus = statusMap[eventKey];

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: rowId,
      status: orderStatus.toUpperCase(),
      rejectionNote: rejectionNote.current,
    };

    console.log("this is status" + orderStatus);

    statusDetailsRef.current = sd;
    if (
      statusDetailsRef.current.status === Constant.OrderStatus.SELLER_REJECTED
    ) {
      handleShow();
    } else {
      changeOfferStatus();
    }
  };

  const handleClose = () => {
    setShow(false);
    setError("");
    setNote("");
  };

  const changeOfferStatus = async () => {
    if (
      rejectionNoteValue.trim().length == 0 &&
      statusDetailsRef.current.status === Constant.OrderStatus.SELLER_REJECTED
    ) {
      console.log("Come inside it");
      setError("Please enter rejection note");
      return;
    }

    try {
      statusDetailsRef.current.rejectionNote = rejectionNote.current;
      console.log(statusDetailsRef.current);
      await OrderApiService.changeOfferStatus(statusDetailsRef.current);
      const rowIndex = tableData.findIndex(
        (row) => row.id === statusDetailsRef.current.id
      );

      if (rowIndex !== -1) {
        // Create a new array with the updated status for the found object
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex] = {
          ...updatedTableData[rowIndex],
          status: statusDetailsRef.current.status,
          rejectionNote: statusDetailsRef.current.rejectionNote,
        };

        // Update the tableData state with the new array
        setTableData(updatedTableData);
      }
      console.log("status updated successfully");
      handleClose();
      CommonService.Toast.fire({
        title: "Offer status updated successfully",
        icon: "success",
      });
    } catch (error) {
      console.log("error occurred which api calling");
    }
  };

  //formate the status
  function formatStatus(status: string): string {
    const formattedStatus = status
      .split(/[_\\]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    console.log(formattedStatus);
    return formattedStatus;
  }

  // const getVariant = (status: any) => {
  //   switch (status) {
  //     case "ACCEPTED":
  //       return "success";
  //     case "PENDING_REVIEW":
  //       return "primary";
  //     case "REJECTED":
  //       return "danger";
  //     case "CANCELLED":
  //       return "dark";
  //     case "SHIPPED":
  //       return "info";
  //     case "DELIVERED":
  //       return "secondary";
  //     default:
  //       return "secondary";
  //   }
  // };

  // const statusOptions = [
  //   "Pending Review",
  //   "Rejected",
  //   "Cancelled",
  //   "Shipped",
  //   "Delivered",
  //   "Accepted",
  //   "Shipped",
  // ];
  //finish drowdown code

  const columns: GridColDef[] = [
    {
      field: "inventoryName",
      headerName: "Inventory",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value}/>
    },
    {
      field: "requestNumber",
      headerName: "Request Number",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value || '-'}/>
    },

    CommonService.isAdmin() && {
      field: "companyName",
      headerName: "Company Name",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    CommonService.isAdmin() && {
      field: "user",
      headerName: "Requested By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "createdDate",
      headerName: "Requested On",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
    },

    {
      field: "quantity",
      headerName: "Quantity",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-IN"
          currency="INR"
        />
      ),
    },
    {
      field: "pricePerBarrel",
      headerName: "Offer Price",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
    },
    {
      field: "totalAmount",
      headerName: "Total Price",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
    },
    {
      field: "status",
      headerName: "Order Status",
      headerClassName: "table-header",
      cellClassName: "status-badge",
      flex: 1,
      renderCell: (params: any) => (
        <div className="cell-with-dropdown mb-2" onClick={(e) => e.stopPropagation()}>
          {/* <DropdownButton
            as={ButtonGroup}
            key={"up"}
            id={`dropdown-button-drop-${"up"}`}
            drop={"start"}
            title={formatStatus(params.row.status)}
            className="dropdown-status"
            //variant={params.row.userStatus === "SHIPPED" ? "danger" : (params.row.userStatus === "PENDING_REVIEW" ? "secondary" : "primary")}
            variant={getVariant(params.row.status)}
            disabled={CommonService.isUser()}
            onSelect={(eventKey) =>
              handleStatusChange(eventKey as string, params.row.id)
            }
          > */}
          {/* Dropdown items */}

          {/* <Dropdown.Item eventKey="1" className="dropdown-item-accepted">
              <HowToRegIcon fontSize="small" sx={{ mr: 1 }} /> Accepted
            </Dropdown.Item> */}
          {/* <Dropdown.Item eventKey="2" className="dropdown-item-pendingReview">
              <PendingActionsIcon fontSize="small" sx={{ mr: 1 }} /> Pending
              Review
            </Dropdown.Item> */}
          {/* <Dropdown.Item eventKey="3" className="dropdown-item-rejected">
              <PersonOffIcon fontSize="small" sx={{ mr: 1 }} /> Rejected
            </Dropdown.Item>
            <Dropdown.Item eventKey="4" className="dropdown-item-shipped">
              <LocalShippingOutlinedIcon fontSize="small" sx={{ mr: 1 }} />{" "}
              Shipped
            </Dropdown.Item>
            <Dropdown.Item eventKey="5" className="dropdown-item-cancelled">
              <HighlightOffIcon fontSize="small" sx={{ mr: 1 }} /> Cancelled
            </Dropdown.Item>
            <Dropdown.Item eventKey="6" className="dropdown-item-delivered">
              <NoCrashIcon fontSize="small" sx={{ mr: 1 }} /> Delivered
            </Dropdown.Item>
          </DropdownButton> */}

          {/* <StatusDropdown
                  currentStatus={formatStatus(params.row.status)}
                  onStatusChange={(newStatus) => {
                      handleStatusChange(newStatus, params.row.id)}
                  } 
                  disableCondition={CommonService.isUser()}
                  allowedLabels={["Delivered","Under Review by Seller","Seller Accepted","Seller Rejected", "Under Review by WDTP",
                    "Contract Sent","Contract Executed", "Invoice Raised", "Payment Received", "Ownership Transferred"]}
                /> */}

          {params.row.status === "PENDING_REVIEW" && currentPath.includes("/user/offer-request") && (
            <StatusDropdown
              currentStatus={formatStatus(params.row.status)}
              onStatusChange={(newStatus) => {
                handleStatusChange(newStatus, params.row.id);
              }}
              disableCondition={false}
              allowedLabels={["Cancelled"]}
            />
          )}

          {params.row.status === "UNDER_REVIEW_BY_SELLER" && currentPath.includes("/user/offer-request") && (
            <StatusDropdown
              currentStatus={formatStatus(params.row.status)}
              onStatusChange={(newStatus) => {
                handleStatusChange(newStatus, params.row.id);
              }}
              disableCondition={true}
              allowedLabels={[]}
            />
          )}

          {((params.row.status === "PENDING_REVIEW" || params.row.status === "UNDER_REVIEW_BY_SELLER") && (currentPath.includes("/user/my-warehouse/offer-request") || CommonService.isAdmin())) && (
            <StatusDropdown
              currentStatus={formatStatus(params.row.status)}
              onStatusChange={(newStatus) => {
                handleStatusChange(newStatus, params.row.id);
              }}
              disableCondition={CommonService.isAdmin()}
              allowedLabels={["Under Review by Seller", "Seller Rejected", "Seller Accepted"]}
            />
          )}

          {params.row.status === "SELLER_ACCEPTED"  && (
            <StatusDropdown
              currentStatus={formatStatus(params.row.status)}
              onStatusChange={(newStatus) => {
                handleStatusChange(newStatus, params.row.id);
              }}
              disableCondition={CommonService.isUser()}
              allowedLabels={["Under Review by WDTP", "Rejected"]}
            />
          )}

          {(params.row.status === "UNDER_REVIEW_BY_WDTP" || params.row.status === "CONTRACT_SENT" || params.row.status === "CONTRACT_EXECUTED" || params.row.status === "INVOICE_RAISED") && (
            <StatusDropdown
              currentStatus={formatStatus(params.row.status)}
              onStatusChange={(newStatus) => {
                handleStatusChange(newStatus, params.row.id);
              }}
              disableCondition={CommonService.isUser()}
              allowedLabels={[ 
                "Contract Sent",
                "Contract Executed",
                "Invoice Raised",
                "Payment Received",
                "Rejected"
              ]}
            />
          )}

{(params.row.status === "PAYMENT_RECEIVED") && (
            <StatusDropdown
              currentStatus={formatStatus(params.row.status)}
              onStatusChange={(newStatus) => {
                handleStatusChange(newStatus, params.row.id);
              }}
              disableCondition={CommonService.isUser()}
              allowedLabels={[ 
                "Ownership Transferred",
                "Rejected"
              ]}
            />
          )}



          {(params.row.status === "SELLER_REJECTED" || params.row.status === "CANCELLED" || params.row.status === "REJECTED" || params.row.status === "OWNERSHIP_TRANSFERRED") && (
            <StatusDropdown
              currentStatus={formatStatus(params.row.status)}
              onStatusChange={(newStatus) => {
                handleStatusChange(newStatus, params.row.id);
              }}
              disableCondition={true}
              allowedLabels={[]}
            />
          )}

          {/* {((params.row.status !== "PENDING_REVIEW" &&
            CommonService.isUser()) ||
            CommonService.isAdmin()) && (
            <StatusDropdown
              currentStatus={formatStatus(params.row.status)}
              onStatusChange={(newStatus) => {
                handleStatusChange(newStatus, params.row.id);
              }}
              disableCondition={
                CommonService.isUser() || params.row.status === "CANCELLED"
              }
              allowedLabels={[
                "Delivered",
                "Under Review by Seller",
                "Seller Accepted",
                "Seller Rejected",
                "Under Review by WDTP",
                "Contract Sent",
                "Contract Executed",
                "Invoice Raised",
                "Payment Received",
                "Ownership Transferred",
              ]}
            />
          )} */}

          {params.row.status === "REJECTED" && (
            <Tooltip
              title={`Rejection Note: ${params.row.rejectionNote}`}
              arrow
            >
              <StickyNote2Icon
                className="note-icon cursor-pointer"
                style={{ color: "gray" }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ].filter(Boolean) as GridColDef[];

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return tableData.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    selectedRows.current = newSelectedRows;
    let flag: any = false;
    flag = selectedRows.current.some((user) => user.status === "INVITED");
    disableAction.current = flag;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log(event.target.value);
    setNote(event.target.value);
    setError("");
    rejectionNote.current = event.target.value;

    console.log(rejectionNoteValue);
  };

  // Filter rows based on search query
  const filteredRows = tableData.filter((row) =>
    CommonService.isAdmin()
      ? row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
        row.inventoryName?.toLowerCase().includes(search?.toLowerCase()) ||
        (row.firstName + " " + row.lastName)
          .toLowerCase()
          .includes(search?.toLowerCase())
      : row.inventoryName?.toLowerCase().includes(search?.toLowerCase())
  );

  // Map filtered rows with unique IDs
  const tableDataWithIds = filteredRows.map((rowData, index) => ({
    ...rowData,
  }));

  const handleBack = () => {
    history.goBack();
  }

  return (
    <>
      <div className="usersList">
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        {CommonService.isAdmin() && (<IconButton
                  onClick={handleBack}
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    mb: 1,
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>)}
          {CommonService.isAdmin() && (<div className="header--title mb-2 me-2">
            <h1 className="title mb-0 ms-2">
              Offer Requests
            </h1>
          </div>)}
          <div className="header--menu ms-auto mb-2">
            <div className="header--menu-items row d-flex justify-content-end">
              <div className="col-auto mb-2">
                <SearchBar search={search} setSearch={setSearch} />
              </div>
            </div>
          </div>
        </div>
        <div className="user-table-container">
          <div className="userTable">
            <DataGrid
              rows={tableDataWithIds}
              columns={columns}
              className="data-grid"
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              localeText={localeText}
              // checkboxSelection
              disableRowSelectionOnClick={true}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={handleSelectionModelChange}
              onRowClick={handleRowClick}
              // getRowClassName={getRowClassName}
            />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Order Status</Modal.Title>
          <p className="modal-text">
            Are you sure, you want to change status to Rejected ?
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="form__inner">
            <textarea
              className={`form-control ${error ? "is_invalid" : ""}`}
              aria-label="With textarea"
              value={rejectionNoteValue}
              onChange={handleInputChange}
              rows={8}
              style={{ resize: "none" }}
              placeholder="Enter rejection note ..."
            />
            {error && <div className="error-message">{error}</div>}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button className="secondary-btn w-100" onClick={handleClose}>
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button className="primary-btn w-100" onClick={changeOfferStatus}>
                Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OfferRequest;
