import React, { useEffect, useRef, useState } from "react";
import { Table, TableContainer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import WhiskyBarrel from "../../assets/images/products/WhiskyBarrel.png";
import { OrderApiService } from "../../Service/OrderApiService";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import Info from "../../assets/images/icons/Info.svg";
import {
  Button as BootstrapButton,
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import Cookies from "universal-cookie";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useParams } from "react-router";
import { CookieService } from "../../Service/CookieService";
import UploadDocs from "../popups/uploadDocs";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ContainerMessageBox from "./ContainerMessageBox";
import { CommonService } from "../../Service/CommonService";
import InventoryFileListTable from "./InventoryFileListTable";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { Stepper, Step, StepLabel, StepContent, Paper } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Constant from "../../Constant";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import { VisibilityOutlined } from "@mui/icons-material";
import StatusDropdown from "../Common/StatusDropdown";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Spinner from "../Common/Spinner";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import EditRequest from "./EditOrderRequest";
import { HistoryStepper } from "../Common/HistoryStepper";
import SearchIcon from "@mui/icons-material/Search";
import { WebSocketProvider } from "../../Websocket/WebSocketProvider";
import NoSupportComment from "../Common/NoSupportComment";
import NoTicketCreated from "../Common/NoTicketCreated";
import FAQList from "../popups/FAQList";
import SupportCommentBox from "./SupportCommentBox";
import DownloadSimpleIcon from "../../assets/images/icons/DownloadSimple.svg";
import TrashIcon from "../../assets/images/icons/Trash.svg";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import AdminMessageBox from "./AdminMessageBox";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// Create a custom theme with adjusted breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768, // Customizing the md breakpoint to start at 992px
      lg: 992,
      xl: 1200,
    },
  },
});

const ProductImage = styled("img")({
  maxWidth: "100%",
  border: "1px solid #D9D9D9", // 1px border for the image
  borderRadius: "10px",
});

const ProductCaption = styled(Typography)({
  textAlign: "center",
  marginTop: "8px",
});

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
  },
  firstColumn: {
    minWidth: 115,
    width: "max-content",
    borderBottom: "none",
    color: "#333333",
    fontWeight: 600,
  },
  secondColumn: {
    width: "100%",
    borderBottom: "none",
    color: "#21252980",
  },
});

const OrderDetail: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const currentPath = location.pathname;
  const classes = useStyles();
  const [checked, setChecked] = React.useState([0]);
  const [addedCartItems, setCartItems] = React.useState<any>([]);
  const [orderSummary, setOrderSummary] = React.useState<any>([]);
  const [orderDetails, setOrderDetails] = React.useState<any>([]);
  const [orderRequestDocs, setOrderRequestDocs] = useState<any[]>([]);
  const [historyData, setHistoryData] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const userId = new Cookies().get("userId");
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const userRole = CookieService.getCookie("userRole");
  const [totalPrice, setTotalPrice] = React.useState<number>(0);
  const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "" });
  const rejectionNote = useRef("");
  const handleShow = () => setShow(true);
  const [rejectionNoteValue, setRejectionNote] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isOpenEditSection, setOpenEditSection] = useState(false);
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [orderItemId, setOrderItemId] = useState("");
  const [isStatusValid, setIsStatusValid] = useState(false);
  const [isOrderItemStatusChange, setIsOrderItemStatusChange] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const handleViewClose = () => setViewDetails(false);
  const handleViewShow = () => setViewDetails(true);
  const handleAdminMessageClick = () => setShowMessageBox(prev => !prev);
  let dashboardPrefrence="";

  const fetchData = async () => {
    try {
      fetchOrderRequestDetail();
      fetchOrderRequestDocs();
      fetchOrderRequestHistory();
      fetchOrderSummaryDetail();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOrderRequestDetail = async () => {

    if (currentPath.startsWith('/user/view-as-buyer/order-detail'))
      dashboardPrefrence = 'Buyer';
    else if (currentPath.startsWith('/user/view-as-owner/order-detail'))
      dashboardPrefrence = 'Owner';
    else
      dashboardPrefrence= CommonService.getDashboardPrefrence();

    const response: any = await OrderApiService.getItemsByOrderId(id, dashboardPrefrence);
    console.log("this is get orderItem by id" + response.data);
    setCartItems(response.data);
    if (response.data) setOrderDetails(response.data[0]);

    const isSeller = response.data.some((item) => {
      return item.typeSenseResponse.company_id === CommonService.getCompanyId() && CommonService.isUser()
    });

    const hasActionRequiredOrPendingReview = response.data.some((item) => {
      return item.status === "ACTION_REQUIRED" || item.status === "PENDING_REVIEW";
    });

    if(isSeller){
      setOrderDetails((prevState) => {
        return { ...prevState, 
          orderStatus: hasActionRequiredOrPendingReview ? 'PENDING_REVIEW' : 'ACCEPTED_REJECTED',
        };
      });
    }

    setIsSeller(isSeller);
    setIsStatusValid(hasActionRequiredOrPendingReview);
  };

  const fetchOrderSummaryDetail = async () => {

    if (currentPath.startsWith('/user/view-as-buyer/order-detail'))
      dashboardPrefrence = 'Buyer';
    else if (currentPath.startsWith('/user/view-as-owner/order-detail'))
      dashboardPrefrence = 'Owner';
    else
      dashboardPrefrence= CommonService.getDashboardPrefrence();

    const response: any = await OrderApiService.getOrderSummaryByOrderId(id, dashboardPrefrence);
    console.log(response);
    if (response.data) setOrderSummary(response.data);
  };

  const fetchOrderRequestDocs = async () => {
    setIsLoading(true);
    const response: any = await OrderApiService.fetchOrderRequestDocs(id);
    setOrderRequestDocs(response.data);
    setIsLoading(false);
  };

  const fetchOrderRequestHistory = async () => {
    const response: any = await OrderApiService.fetchOrderRequestHistory(id);
    setHistoryData(response.data);
  };

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    fetchData();
  }, []);

  // Effect to calculate sum whenever the items list changes
  useEffect(() => {
    const sum = addedCartItems.reduce(
      (acc: any, item: any) => acc + (item["totalPrice"] || 0),
      0
    );
    setTotalPrice(sum);

    let filters =
      addedCartItems.length > 0
        ? [
            `owner_id:!=${userId}`,
            `(inventory_id:!=${addedCartItems
              .map((item: { inventoryId: any }) => item.inventoryId)
              .join(" || inventory_id:=")})`,
          ]
        : [`owner_id:!=${userId}`];

    const filterValue = Array.isArray(filters)
      ? filters?.join(" && ").replace(/\\/g, "")
      : filters;

    setGlobalFilter(filterValue);
  }, [addedCartItems]);

  const routeBack = () => {
    if (userRole == "ADMIN") history.push("/user/active-order");
    else history.push("/master/active-order");
  };

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //formate the status
  function formatStatus(status?: string): string {
    let formattedStatus = "";
    if (status !== undefined) {
      formattedStatus = status
        ?.split(/[_\\]/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }

    return formattedStatus;
  }

 

//handle edit button
const handleEditButtonClick = () => {
    if(isOpenEditSection) {
      setOpenEditSection(false);
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
    }
}

//handle message box click
const handleMessageBoxClick = () => {

    if(isMessageBoxOpen) {
      setMessageBoxOpen(false);
    }
    else {
    setOpenEditSection(false);
    setMessageBoxOpen(true);
    }
}

  const handleStatusChange = async (newStatus: string) => {
    // const statusMap: { [key: string]: string } = {
    //   "1": "Reserved",
    //   "2": "Pending Review",
    //   "3": "Rejected",
    //   "4": "Shipped",
    //   "5": "Cancelled",
    //   "6": "Delivered",
    // };

    // const newStatus = statusMap[eventKey];
    console.log(newStatus);

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: id,
      companyId: CommonService.getCompanyId(),
      status: orderStatus.toUpperCase(),
      rejectionNote: rejectionNote.current,
      quantity: 0
    };

    statusDetailsRef.current = sd;
    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      handleShow();
    } else {
      changeOrderStatus();
    }
  };

  const handleEditClick = (itemId:any) => {

    if(isOpenEditSection) {
      setOpenEditSection(false);
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
      setOrderItemId(itemId);
    }
    
  }

  const handleDeleteClick = async (itemId:any) => {
    await OrderApiService.deleteOrderItem(itemId);
   
    CommonService.Toast.fire({
      title: "Order Item removed successfully",
      icon: "success",
    });

    fetchOrderRequestDetail();
  }

  const changeOrderStatus = async () => {

    if(statusDetailsRef.current.status === Constant.OrderStatus.REJECTED && rejectionNoteValue.trim().length == 0){
      setError("Please enter rejection note");
      return ;
    }

    setIsLoading(true);
    
    try {
      statusDetailsRef.current.rejectionNote = rejectionNote.current;
      await OrderApiService.changeOrderStatus(statusDetailsRef.current);
      setIsLoading(false);
      CommonService.Toast.fire({
        title: "Order Request updated successfully",
        icon: "success",
      });

      handleClose();
      fetchData();
    } catch (error) {
      setIsLoading(false);
      console.log("error occurred which api calling");
    }
  };

  const handleClose = () => {
    setShow(false);
    setRejectionNote("");
    setError("");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRejectionNote(event.target.value);
    setError("");
    rejectionNote.current = event.target.value;
  };

  const downloadOrderRequestDOC = async (item: any) => {
    const requestDto = {
      id: item.id,
      siteId: orderDetails.siteId,
      companyId: orderDetails.companyId,
      fileName: item.fileName,
    };

    try {
      const response: any = await OrderApiService.downloadOrderRequestDOC(
        requestDto
      );
      // Assuming response.data is the blob data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = item.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOrderItemStatusChange = async (newStatus: string, orderItemId: any, inventoryId: any, inventoryName: any) => {

    let updatedStatus;

    if (newStatus === "Reject for Owner")
      updatedStatus = "Admin Rejected";
    else if (newStatus === "Accept for Owner")
      updatedStatus = "Admin Accepted";
    else if (newStatus === "Send to Owner")
      updatedStatus = "Pending Review";
    else
      updatedStatus = newStatus;    

    const status = updatedStatus?.replace(/ /g, "_");

    const details = {
        id: orderItemId,
        orderId: id,
        inventoryId: inventoryId,
        inventoryName: inventoryName,
        status: status.toUpperCase(),
        rejectionNote: rejectionNote.current,
        companyId: CommonService.getCompanyId()
    };

    statusDetailsRef.current = details;
    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED || statusDetailsRef.current.status === Constant.OrderStatus.ADMIN_REJECTED) {
        setIsOrderItemStatusChange(true); // to call method of order item status change
        handleShow();
    } else {
      changeOrderItemStatus();
    }
  };

  const changeOrderItemStatus = async () => {

    if(statusDetailsRef.current.status === Constant.OrderStatus.REJECTED && rejectionNoteValue.trim().length == 0){
      setError("Please enter rejection note");
      return ;
    }

    setIsLoading(true);
    
    try {
      statusDetailsRef.current.rejectionNote = rejectionNote.current;

      await OrderApiService.updateOrderItemStatus(statusDetailsRef.current);
      setIsLoading(false);
      setIsOrderItemStatusChange(false); 

      CommonService.Toast.fire({
          title: "Order Item updated successfully",
          icon: "success",
      });
      
      handleClose();
      fetchOrderRequestDetail();
    } catch (error) {
      setIsLoading(false);
      console.log("error occurred which api calling");
    }
  };

  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.fileName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",

      renderCell: (params: any) => {
        //check file have .pdf extension or not
        const isPdf = params.row.fileName.toLowerCase().endsWith(".pdf");

        return (
          <div>
            {/* if file contains the .pdf extension then show the view button */}
            {isPdf && (
              <Tooltip title="View PDF" arrow>
                <IconButton
                  onClick={() =>
                    CommonService.showPdfForOrder(
                      params.row.id,
                      orderDetails.companyId,
                      orderDetails.siteId,
                      params.row.fileName
                    )
                  }
                >
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Download document" arrow>
              <IconButton
                onClick={(event) => downloadOrderRequestDOC(params.row)}
              >
                <CloudDownloadOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="usersList position-relative">
        <div className="usersList__header--wrapper d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-2">
          <div className="d-flex flex-wrap justify-content-between">
          {CommonService.isAdmin() && (
            <div className="back__button me-3" onClick={routeBack}>
              <IconButton
                aria-label="ArrowBackIcon"
                sx={{ backgroundColor: "#FFF", mb: 1 }}
              >
                <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
              </IconButton>
            </div>
          )}

          {CommonService.isAdmin() && (
            <div className="header--title mb-2 me-2">
              <h1 className="title mb-0">
                Order Request
                <i className="bi bi-heart-fill"></i>
              </h1>
            </div>
          )}

          <div className="header--title d-flex flex-wrap me-auto mb-xxl-0">
            <div className="company__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Request number
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: "#333333",
                  fontSize: { xl: "18px", xs: "16px" },
                  fontWeight: 700,
                }}
              >
                {orderDetails?.requestNumber}
              </Typography>
            </div>
            <div className="company__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Company name
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: "#333333",
                  fontSize: { xl: "18px", xs: "16px" },
                  fontWeight: 700,
                }}
              >
                {orderDetails?.companyName}
              </Typography>
            </div>
            <div className="person__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Submitted by
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {orderDetails?.firstName} {orderDetails?.lastName}
              </Typography>
            </div>
            <div className="time__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Submitted on
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {CommonService.formatDateTime(orderDetails?.createdDate)}
              </Typography>

              
              {/* <img src={Info} alt="Info" /> */}
              
            </div>
          </div>
          </div>
<div className="ms-auto">
          <div className="header--menu ms-auto mb-xxl-0">
            <div className="header--menu-items">
              {/* <div className="col-auto menu-items__btnGroup d-inline-flex mb-3"> */}
              <IconButton aria-label="message" size="small" className="position-relative" onClick={CommonService.isAdmin() ? handleAdminMessageClick : handleMessageBoxClick} sx={{ borderRadius: "50%", backgroundColor: isMessageBoxOpen? "#97AEB8": "#FFF", color: isMessageBoxOpen? "#FFF": "#1C1B1F", marginInline: "8px", padding: "8px" }}>
                <MarkunreadOutlinedIcon />
                {/* <div className="msg-count-wrapper">
                  2
                </div> */}
                {showMessageBox && <ArrowDropUpIcon className="arrow-icon"/>}
              </IconButton>
              {showMessageBox &&
                <AdminMessageBox />
}
              
{/* {CommonService.isAdmin() && (<button onClick={handleEditButtonClick} className="border-0 bg-transparent"> <BorderColorIcon/></button>)} */}

 {/* Pending Review - User */}
      {(orderDetails?.orderStatus === "PENDING_REVIEW" || orderDetails?.orderStatus === "ACCEPTED_REJECTED") && CommonService.isUser() && (
        <StatusDropdown
          currentStatus={formatStatus(orderDetails?.orderStatus)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus)}
          disableCondition={CommonService.isAdmin() || orderDetails?.userId !== CommonService.getUserId()}
          allowedLabels={["Cancelled"]}
        />
      )}

      {/* Pending Review or Under Review - Admin */}
      {((orderDetails?.orderStatus === "PENDING_REVIEW" || orderDetails?.orderStatus === "UNDER_REVIEW") && CommonService.isAdmin()) && (
        <StatusDropdown
          currentStatus={formatStatus(orderDetails?.orderStatus)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus)}
          disableCondition={CommonService.isUser() || orderDetails?.orderStatus === "CANCELLED" || orderDetails?.orderStatus === "REJECTED" || (orderDetails?.orderStatus === "UNDER_REVIEW" && isStatusValid)}
          allowedLabels={isStatusValid ? ["Under Review"] : ["Under Review","Accepted", "Rejected"]}
        />
      )}

      {/* Under Review - User (disabled) */}
      {(orderDetails?.orderStatus === "UNDER_REVIEW" && CommonService.isUser()) && (
        <StatusDropdown
          currentStatus={formatStatus(orderDetails?.orderStatus)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus)}
          disableCondition={true}
          allowedLabels={[]}
        />
      )}

      {/* Accepted to Payment Received - Both User and Admin */}
      {["ACCEPTED", "REJECTED", "CONTRACT_SENT", "INVOICE_SENT", "CONTRACT_EXECUTED"].includes(orderDetails?.orderStatus) && (
        <StatusDropdown
          currentStatus={formatStatus(orderDetails?.orderStatus)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus)}
          disableCondition={CommonService.isUser() || orderDetails?.orderStatus === "CANCELLED" || orderDetails?.orderStatus === "REJECTED"}
          allowedLabels={["Contract Sent", "Contract Executed", "Invoice Sent", "Payment Received", "Rejected"]}
        />
      )}

      {/* Accepted - Admin specific options */}
      {orderDetails?.orderStatus === "PAYMENT_RECEIVED" && (
        <StatusDropdown
          currentStatus={formatStatus(orderDetails?.orderStatus)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus)}
          disableCondition={CommonService.isUser() || orderDetails?.orderStatus === "CANCELLED" || orderDetails?.orderStatus === "REJECTED"}
          allowedLabels={["Ownership Transferred", "Rejected"]}
        />
      )}

      {/* Ownership Transferred or Cancelled - Both User and Admin (disabled) */}
      {(orderDetails?.orderStatus === "OWNERSHIP_TRANSFERRED" || orderDetails?.orderStatus === "CANCELLED") && (
        <StatusDropdown
          currentStatus={formatStatus(orderDetails?.orderStatus)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus)}
          disableCondition={true}
          allowedLabels={[]}
        />
      )}

        {orderDetails?.orderStatus === "REJECTED" && (
            <Tooltip
              title={`Rejection Note: ${orderDetails.orderRejectionNote}`}
              arrow
            >
              <StickyNote2Icon
                className="note-icon cursor-pointer"
                style={{ color: "gray" }}
              />
            </Tooltip>
          )}


              </div>
            </div>
            </div>
        </div>
        <div className="viewSampleRequest--wrapper">
          <div className={`${
    isOpenEditSection
        ? "margin-right-430"  // When only `isOpenEditSection` is true
        : isMessageBoxOpen
        ? "margin-right-400"   // Only `isMessageBoxOpen` is true
        : "no-margin"       // When none of the conditions are met
}`}>
            <div className="row m-0">
              <div className="col-12">
                <div className="view-sample-request mt-4 mb-3">
                  <div>
                    <div className="inventory-file-list-table">
                      <InventoryFileListTable cartItems={addedCartItems} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} onOrderItemStatusChange={handleOrderItemStatusChange} viewDetails={viewDetails} handleViewClose={handleViewClose} handleViewShow={handleViewShow}/>
                      <div className="footer-order-summary d-flex justify-content-end gap-2 flex-wrap">
                        <div>
                          <Typography component="span" sx={{ color: "#333333", fontSize: "13px", fontWeight: "400", lineHeight:"17.29px" }}>
                            The price does not include shipping costs.
                          </Typography>
                        </div>
                        <div className="d-flex gap-2">
                            <Typography
                              component="span"
                              sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight:"23.94px" }}
                            >
                              Subtotal:  
                            </Typography>
                                
                            <Typography
                              component="span"
                              sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight:"21.28px" }}
                            >
                              <CurrencyFormatter
                                amount={!isSeller ? orderSummary.subTotal : orderSummary.orderTotalWithoutPlatformFees}
                                locale="en-US"
                                currency="USD"
                              />  
                            </Typography>

                            {! isSeller && <>
                            <Typography
                              component="span"
                              sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight:"21.28px" }}
                            >
                              <CurrencyFormatter
                                amount={orderSummary.totalHandlingFees}
                                locale="en-US"
                                currency="USD"
                              />
                            </Typography>

                            <Typography
                              component="span"
                              sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight:"21.28px" }}
                            >
                              <CurrencyFormatter
                                amount={orderSummary.totalServiceFees}
                                locale="en-US"
                                currency="USD"
                              />
                            </Typography>
                            </>}

                            <Typography
                              component="span"
                              sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight:"23.94px" }}
                            >
                              Total: 
                            </Typography>
                                
                            <Typography
                              component="span"
                              sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight:"23.94px" }}
                            >
                              <CurrencyFormatter
                                amount={!isSeller ? orderSummary.orderTotal : orderSummary.orderTotalWithoutPlatformFees}
                                locale="en-US"
                                currency="USD"
                              />
                            </Typography>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 py-4">
                <div className="order__summary--card card border-0 mb-xl-0 h-100">
                  <div className="card-header py-3">
                    <Typography
                      variant="h5"
                      sx={{
                        background: "#F0F0F0",
                        mb: 0,
                        fontWeight: 700,
                        fontSize: "20px",
                      }}
                    >
                      Order summary
                    </Typography>
                  </div>
                  <TableContainer
                    component={Paper}
                    style={{ boxShadow: "none" }}
                  >
                    <Table
                      sx={{ minWidth: 400 }}
                      aria-label="items summary table"
                    ></Table>
                  </TableContainer>
                  <div className="card-body">
                    <div className="row d-flex justify-content-between border-bottom">
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Subtotal
                        </Typography>
                      </div>
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          <CurrencyFormatter
                            amount={orderSummary.subTotal}
                            locale="en-US"
                            currency="USD"
                          />
                        </Typography>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Total Handling Charges
                        </Typography>
                      </div>
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          <CurrencyFormatter
                            amount={orderSummary.totalHandlingFees}
                            locale="en-US"
                            currency="USD"
                          />
                        </Typography>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Total Service Charges
                        </Typography>
                      </div>
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          <CurrencyFormatter
                            amount={orderSummary.totalServiceFees}
                            locale="en-US"
                            currency="USD"
                          />
                        </Typography>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between border-top">
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            mt: 2,
                            color: "#333333",
                            fontWeight: 700,
                            fontSize: "20px",
                          }}
                        >
                          Order Total
                        </Typography>
                      </div>
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            mt: 2,
                            color: "#333333",
                            fontWeight: 700,
                            fontSize: "20px",
                          }}
                        >
                          <CurrencyFormatter
                            amount={orderSummary.orderTotal}
                            locale="en-US"
                            currency="USD"
                          />
                        </Typography>
                      </div>
                      <div className="col-12">
                        <Typography
                          variant="caption"
                          display="block"
                          sx={{
                            my: 0,
                            color: "#333333",
                            fontWeight: 400,
                            fontSize: "12px",
                          }}
                        >
                          The price does not include shipping costs.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row mt-3 mt-lg-2 mb-3 mx-0">
              <div className="col-xl-6 mb-3 mb-xl-0">
                <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">List of Documents</h3>
                  <UploadDocs
                    fetchData={fetchData}
                    csvId={id}
                    uploadCSV={false}
                    requestType={"ORDER_REQUEST"}
                  />
                </div>
                <div
                  className="inventory-doc-table"
                  style={{ height: 350, width: "100%" }}
                >
                  <DataGrid
                    localeText={localeText}
                    rows={orderRequestDocs}
                    columns={columnsForDoc}
                    className="data-grid"
                    hideFooterPagination
                  />
                </div>
              </div>
              <div className="col-xl-6 mb-3 mb-xl-0">
                <div className="recentActivity">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-2">Recent Activity</h3>
                  </div>
                  <div className="history-stepper-wrapper">
                    <HistoryStepper historyData={historyData}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(isOpenEditSection || isMessageBoxOpen) &&
          <div className="msgContainerBox">
            {isOpenEditSection && (<EditRequest requestType="ORDER_REQUEST" id={orderItemId} refreshData={fetchData} onEditClick={handleEditClick}/>)}
           {isMessageBoxOpen &&  (<ContainerMessageBox requestType={"ORDER_REQUEST"} sellerCompanyId={isSeller ? CommonService.getCompanyId() : null}/>)}
          </div>
         }
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          {isOrderItemStatusChange ? <Modal.Title>Update Owner Status</Modal.Title>
                                  : <Modal.Title>Update Order Status</Modal.Title>}
          <p className="modal-text">
            Are you sure, you want to change status to Rejected ?
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="form__inner">
          <textarea
            className={`form-control ${error ? "is_invalid" : ""}`}
            aria-label="With textarea"
            value={rejectionNoteValue}
            onChange={handleInputChange}
            rows={8}
            style={{resize:"none"}}
            placeholder="Enter text here..."
          />
          {error && <div className="error-message">{error}</div>}

          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button className="primary-btn w-100" onClick={isOrderItemStatusChange ? changeOrderItemStatus : changeOrderStatus} disabled={isLoading}>
               {isLoading && <Spinner/>} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderDetail;
